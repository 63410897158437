import React, { useEffect, useState } from "react";
import { getCountries, getCountry, getStates } from "country-state-picker";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app, db } from "../Config/Config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import LoadingButton from "@mui/lab/LoadingButton";


export default function Register() {
  //==================================================== Variable Declaration =========================================================//
  const [name, setname] = useState("");
  const [state, setstate] = useState("");
  const [stateList, setstateList] = useState([]);
  const [district, setdistrict] = useState("");
  const [dob, setdob] = useState(new Date());
  const [edustatus, setedustatus] = useState();
  const [userId, setuserId] = useState(null);
  const [mobile, setmobile] = useState();
  const [gender, setgender] = useState("male");
  const [loading, setloading] = useState(false);
  const [disabilityFlag, setdisabilityFlag] = useState(false);
  const [lastName, setLastName] = useState("");
  const [privilegeType, setprivilegeType] = useState();
  const [IssuingAuthority, setissuingAuthority] = useState();
  const [certificateUrl, setcertificateUrl] = useState();
  const today = new Date();

  //==================================================== Fetch Countries  =========================================================//
  const [countryCodeList, setcountryCodeList] = useState([]);
  const [countryCode, setcountryCode] = useState("India");

  useState(() => {
    const countries = getCountries();
    setcountryCodeList(countries);
  });

  //==================================================== Fetch States  =========================================================//
  useEffect(() => {
    if (countryCode) {
      let country = getCountry(countryCode);
      let states = getStates(country.code);
      setstateList(states);
    }
  }, [countryCode]);

  //==================================================== Current User =========================================================//
  const [email, setemail] = useState(null);
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setuserId(uid);
        if (user.email) {
          setemail(user.email);
        } else {
          setemail("");
        }
      } else {
      }
    });
  }, []);

  //==================================================== User Registration  =========================================================//

  const functions = getFunctions(app, "asia-south1");

  const handleRegister = (url) => {
    const dateob = new Date(dob);
    setloading(true);
    const userRegistratioin = httpsCallable(functions, "userRegistratioin");
    const userData = {
      userData: {
        name: name,
        lastname: lastName,
        state: state,
        district: district,
        qualification: edustatus,
        uid: userId,
        gender: gender,
        mobile: mobile,
        country: countryCode,
        email: email,
        my_books: [],
        privilege: disabilityFlag,
        privilege_type: disabilityFlag ? privilegeType : "",
        privilege_issuing_authority: disabilityFlag ? IssuingAuthority : "",
        privilege_certificate: disabilityFlag ? url : "",
        avatar: gender === "male" ? "m1avatar.png" : "f1avatar.png",
        platform: "web",
      },
      dateofb: {
        month: dateob.getMonth(),
        date: dateob.getDate(),
        year: dateob.getFullYear(),
      },
    };
    userRegistratioin({
      data: userData,
    }).then((result) => {
      const data = result.data;
      localStorage.setItem("register_popup", false);
      if (data) {
        setloading(false);
        localStorage.setItem("register_popup", false);
        window.location.reload();
      } else {
        setloading(false);
        alert("Something went wrong...please try again");
      }
    });
  };

  //==================================================== Fetch Qualifications  =========================================================//
  const [qualificationList, setqualificationList] = useState([]);
  const fetchPackage = async () => {
    setqualificationList([]);
    const q = query(
      collection(db, "registration_qualification_dropbox"),
      orderBy("order", "asc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setqualificationList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
          },
        ];
      });
    });
    // setloading(false);
  };
  useState(() => {
    fetchPackage();
  });

  //==================================================== Fetch Privileges  =========================================================//
  const [privilegeList, setprivilegeList] = useState([]);
  const fetchPrivilege = async () => {
    setprivilegeList([]);
    const q = query(
      collection(db, "registration_privilege_dropbox"),
      orderBy("order", "asc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setprivilegeList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
          },
        ];
      });
    });
    // setloading(false);
  };
  useState(() => {
    fetchPrivilege();
  });
  //==================================================== Dob Validation  =========================================================//
  const [dobFlag, setdobFlag] = useState(false);
  const calculateDaysDifference = (timestamp1, timestamp2) => {
    // Convert timestamps to Date objects
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    // Calculate the difference in milliseconds
    const timeDifference = date1 - date2;

    // Convert milliseconds to days
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    if (Math.floor(daysDifference) >= 5475) {
      setdob(timestamp2);
      setdobFlag(false);
    } else {
      setdobFlag(true);
    }
  };
  //==================================================== Upload Certificate  =========================================================//
  const uploadCertificate = async (e) => {
    e.preventDefault();
    if (disabilityFlag) {
      const date = new Date();
      const storage = getStorage();
      const storageRef = ref(
        storage,
        "privilegeCertificate/" + name + "/" + mobile + "/" + date
      );

      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, certificateUrl).then((snapshot) => {
        getDownloadURL(ref(storageRef)).then((url) => {
          handleRegister(url);
        });
      });
    } else {
      handleRegister();
    }
  };
  //==================================================== Popup Management  =========================================================//

  const [isModalOpen, setIsModalOpen] = useState(false);
  window.addEventListener("register", (event) => {
    if (event.type === "register") {
      setIsModalOpen(localStorage.getItem("register_popup"));
    }
  });
  window.addEventListener("loginReg", async(event) => {
    if (event.type === "loginReg") {
      const docRef = doc(db, "cities", "SF");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsModalOpen(false);
      } else {
        setIsModalOpen(true);
      }
    }
  });

  const closeModal1 = () => {
       localStorage.setItem("register_popup", false);
       setIsModalOpen(false);
  };
     
  return (
    <div>
    {/* {loading?(
      <Spinner/>
    ):( */}
      <div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-filter backdrop-blur-lg ">
            <div className="bg-white p-2 rounded-lg max-w-4xl">
              <div className="flex justify-end">
                <button
                  onClick={closeModal1}
                  className="cursor-pointer text-gray-600 hover:text-gray-800 flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 inline-block mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <form
                onSubmit={uploadCertificate}
                class="rounded px-8 pt-6 pb-8 mb-4"
              >
                <div class="grid gap-3 md:grid-cols-3">
                  <div>
                    <label class="">First Name</label>
                    <input
                      onChange={(e) => setname(e.target.value)}
                      value={name}
                      placeholder="Enter First your name"
                      required
                      type="text"
                      class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                    />
                  </div>
                  <div>
                    <label class="">Last Name</label>
                    <input
                      type="text"
                      value={lastName}
                      placeholder="Enter Last your name"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      required
                      class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                    />
                  </div>
                  <div>
                    <label class="">Date of Birth</label>
                    <input
                      type="date"
                      value={dob}
                      onChange={(e) => {
                        calculateDaysDifference(today, e.target.value);
                      }}
                      required
                      class="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                    />
                    {dobFlag ? (
                      <label class="text-xs text-red-500">
                        Registration for ages 15 and above only
                      </label>
                    ) : null}
                  </div>

                  <div>
                    <label class="">Gender</label>
                    <select
                      className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                      data-te-select-init
                      required
                      onChange={(e) => {
                        setgender(e.target.value);
                      }}
                    >
                      <option
                        value={"male"}
                        data-te-select-secondary-text="Secondary text"
                      >
                        Male
                      </option>
                      <option
                        value={"female"}
                        data-te-select-secondary-text="Secondary text"
                      >
                        Female
                      </option>
                      <option
                        value={"other"}
                        data-te-select-secondary-text="Secondary text"
                      >
                        Other
                      </option>
                    </select>
                  </div>

                  <div>
                    <label class=""> Current Educational Status</label>
                    <select
                      required
                      className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                      data-te-select-init
                      onChange={(e) => {
                        setedustatus(e.target.value);
                      }}
                    >
                      <option
                        value={""}
                        data-te-select-secondary-text="Secondary text"
                      >
                        Select
                      </option>
                      {qualificationList.map((doc, index) => {
                        return (
                          <option
                            value={doc.data.content}
                            data-te-select-secondary-text="Secondary text"
                          >
                            {doc.data.content}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <label class=""> Country</label>
                    <select
                      required
                      onChange={(e) => {
                        setcountryCode(e.target.value);
                      }}
                      value={countryCode}
                      data-te-select-filter="true"
                      className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                    >
                      {countryCodeList.map((doc, index) => {
                        return (
                          <option
                            value={doc.name}
                            data-te-select-secondary-text="Secondary text"
                          >
                            {doc.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div>
                    <label class=""> State</label>
                    <select
                      required
                      className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                      data-te-select-init
                      onChange={(e) => {
                        setstate(e.target.value);
                      }}
                    >
                      {stateList.map((item) => (
                        <option
                          value={item}
                          data-te-select-secondary-text="Secondary text"
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label class="">District</label>
                    <input
                      required
                      className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                      type="text"
                      value={district}
                      onChange={(e) => setdistrict(e.target.value)}
                      placeholder="Enter District"
                    />
                  </div>
                  <div>
                    <label class="">Contact Number</label>
                    <input
                      required
                      className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                      type="tel"
                      value={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                      placeholder="Enter Contact Number"
                    />
                  </div>
                </div>

                <div class="col-span-6 sm:flex justify-center sm:items-center sm:gap-4 py-4">
                  <input
                    className="flex-shrink-0 w-4 h-4 mr-2 border focus:border-blue-500 focus:outline-none"
                    type="checkbox"
                    value={disabilityFlag}
                    onChange={(e) => setdisabilityFlag(e.target.checked)}
                  />
                  <label className="flex-grow">
                    Do you have any special privileges
                  </label>
                </div>
                {disabilityFlag ? (
                  <>
                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <label
                          htmlFor="privilegeType"
                          className="block text-sm font-medium"
                        >
                          Privilege type
                        </label>
                        <select
                          id="privilegeType"
                          className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                          data-te-select-init
                          required
                          onChange={(e) => {
                            setprivilegeType(e.target.value);
                          }}
                        >
                          {privilegeList.map((doc, index) => (
                            <option
                              value={doc.data.content}
                              data-te-select-secondary-text="Secondary text"
                              key={index}
                            >
                              {doc.data.content}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex-1">
                        <label
                          htmlFor="issuingAuthority"
                          className="block text-sm font-medium"
                        >
                          Issuing authority
                        </label>
                        <input
                          id="issuingAuthority"
                          required
                          className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                          type="tel"
                          value={IssuingAuthority}
                          onChange={(e) => setissuingAuthority(e.target.value)}
                          placeholder="Issuing authority"
                        />
                      </div>

                      <div className="flex-1">
                        <label
                          htmlFor="disabilityCertificate"
                          className="block text-sm font-medium"
                        >
                          Privilege Certificate
                        </label>
                        <input
                          id="disabilityCertificate"
                          required
                          className="mt-2 h-12 w-full rounded-md bg-gray-100 px-3"
                          type="file"
                          onChange={(e) => {
                            setcertificateUrl(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                <div>
                  <LoadingButton
                    size="small"
                    type="submit"
                    style={{ height: "35px" }}
                    className="mt-5 w-full rounded-md bg-blue-500  text-center font-semibold text-white"
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                  >
                    <span>Register Now</span>
                  </LoadingButton>
                  {console.log(loading)}
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      {/* )} */}
    </div>
  );
}
