import React from 'react';

export default function Extgoals() {
  return (
    <div className="bg-white py-6">

      <div className="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
      <h1 className="lg:text-7xl text-4xl md:text-5xl font-bold tracking-tight text-gray-900 text-center py-4">
        The goals of
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900">
          Goodwill Academy
        </span>
      </h1>
      {/* <p className="mt-2 text-xl text-gray-800 max-w-4xl text-center">
        The significance of ACCA in TGA shall be delivered later today.
      </p> */}
    </div>

  
      <section className="mx-auto bg-white max-w-7xl sm:px-6 lg:px-8 2xl:rounded-xl ">
        <div className="flex flex-col sm:flex-row items-center max-w-screen-xl">
          <div className="sm:w-1/2 p-5 md:p-10 flex justify-center" style={{ minHeight: '400px' }}>
            <img
              alt="Img"
              className="rounded-2xl h-full w-full object-cover"
              src="https://img.freepik.com/free-photo/woman-with-graduation-diploma_23-2148928762.jpg?w=740&t=st=1700807512~exp=1700808112~hmac=6d8967c181f72e2bbd390da17ecbf3821a46847e6b3d065db27658a4a813cc8b"
            />
          </div>
          <div className="sm:w-1/2 p-5 md:p-10">
            <h2 className="my-4 font-normal text-3xl sm:text-4xl">
              <span className="text-blue-600">Professional Coaching Online</span>
            </h2>
            <p className="mt-2">
              Online coaching classes have become increasingly popular among ACCA students, particularly through platforms like The Goodwill Academy, due to their numerous features and benefits.
            </p>
            <p className="mt-2">
              Here’s an overview tailored for students interested in securing ACCA membership.
            </p>
          </div>
        </div>
      </section>


      <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12">
        <h3 className="text-3xl font-bold text-gray-900 mb-12">
          Features of Online Coaching Classes with The Goodwill Academy
        </h3>
        <div className="space-y-6">
          <p><strong>1. Flexibility:</strong> Online classes offer a high degree of flexibility, allowing students to access materials and lectures at their convenience. This is particularly beneficial for working professionals or those with busy schedules, as they can study at their own pace and choose times that suit them best.</p>
          <p><strong>2. Global Accessibility:</strong> Students can connect with study resources of The Goodwill Academy from around the world, breaking geographical barriers. This accessibility allows for a diverse learning experience and the opportunity to learn from experts in the field.</p>
          <p><strong>3. Cost-Effectiveness:</strong> ACCA courses offered by The Goodwill Academy come at a lower cost compared to traditional classroom settings. Students save on commuting and accommodation expenses, and the ACCA course from TGA offers competitive pricing for high-quality education.</p>
          <p><strong>4. Diverse Learning Resources:</strong> Platforms like The Goodwill Academy provide a variety of multimedia resources, including video lectures, interactive quizzes, and discussion forums, Exams practices, Time management techniques etc. This range of tools enhances the learning experience and prepares the candidate for better exam results.</p>
          <p><strong>5. Personalized Learning:</strong> The online ACCA course offered by TGA allows students to create personalized study plans and utilize adaptive learning tools. This customization helps students focus on their individual needs and progress at their own pace.</p>
          <p><strong>6. Structured Learning:</strong> Despite the flexibility, the ACCA online course maintains a structured curriculum that ensures comprehensive coverage of the ACCA syllabus. This structure helps students stay organized and on track with their studies.</p>
        </div>
      </section>


      <section className="bg-gray-100 rounded mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12">
        <h3 className="text-3xl font-bold text-gray-900  mb-12">
          Benefits of Online Coaching Classes with The Goodwill Academy
        </h3>
        <div className="space-y-6">
          <p><strong>1. Enhanced Understanding:</strong> Virtual coaching with TGA offers students the chance to connect theoretical ideas with practical implementations in authentic exam settings. This link strengthens comprehension and deepens insight into exam viewpoints.</p>
          <p><strong>2. Support and Guidance:</strong> Students have access to course materials around the clock and can have questions answered beyond what the system offers. This arrangement is very supportive for students to clarify their doubts at any point in time.</p>
          <p><strong>3. Self-Paced Learning:</strong> The ability to revisit lectures and materials multiple times with no restriction allows students to master subjects without the pressure of keeping up with a classroom schedule. This can lead to better retention of information and improved exam performance.</p>
          <p><strong>4. Comfort and Convenience:</strong> The Goodwill Academy offers a seamless ACCA study experience from the comfort of home or any chosen location, ensuring a stress-free environment for learners of all ages. This flexibility allows students to concentrate better and boosts their overall productivity.</p>
          <p><strong>5. Networking Opportunities:</strong> Although online education might miss certain in-person interactions, TGA's educational platform promotes networking through virtual events, forums, and collaborative projects. These opportunities enable students to cultivate professional relationships effectively.</p>
          <p><strong>6. Preparation for Digital Literacy:</strong> Participating in TGA's online classes and utilizing its resources equips students for the digital demands of contemporary workplaces. This expertise not only contributes to their academic achievements but also provides them with vital skills that are highly sought after in professional settings.</p>
          <p><strong>7. Career Development Resources:</strong> The Goodwill Academy's online programs frequently offer supplementary resources aimed at career development, such as assistance with CV writing and access to virtual job fairs both internal and International. These tools are designed to boost students' employability as they approach graduation.</p>
        </div>
      </section>

      {/* Summary Section */}
      {/* <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12">
        <h3 className="text-3xl font-bold text-gray-900 text-center mb-8">Summary</h3>
        <p>
          In summary, online coaching classes through The Goodwill Academy offer ACCA students a flexible, accessible, and cost-effective way to prepare for their qualifications. With a wealth of resources and support, students can enhance their learning experience and increase their chances of achieving ACCA membership.
        </p>
      </section> */}
    </div>
  );
}
