import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../assets/Loading";
import { Navigate, Route, Routes } from "react-router-dom";
import Register from "../Auth/Register";
import Login from "../Auth/Login";
import Home from "../Home/Home";
import Bookdetails from "../Book/Bookdetails";
import Exams from "../Exams/Exams";
import Chapter from "../Book/Chapter";
import PdfViewer from "../Book/Pdf";
import ExamResult from "../Exams/ExamResult";
import Package from "../Package/Package";
import MyShelf from "../MyShelf/MyShelf";
import Profile from "../Profile/Profile";
import Contact from "../Contact/Contact";
import Exampage from "../Exam/Exampage";
import Aboutus from "../footercontent/Aboutus";
import Privacy from "../footercontent/Privacy";
import Refund from "../footercontent/Refund";
import Terms from "../Home/Termsofservice";
import Stripe from "../Payment/Stripe";
import Success from "../PaymentResult/Success";
import Cancel from "../PaymentResult/Cancel";
import About from "../About/About";
import LockedBooks from "../Book/LockedBooks";
import Viewboks from "../Book/ViewBooks";
import Editprofile from "../Profile/EditProfile";
import Vac from "../Vaccencies/Vac";
import Studeies from "../Home/Studeies";
import LevelBooks from "../Book/LevelBooks";
import SearchBook from "../Book/SearchBook";
import Results from "../Exam/Results";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../Config/Config";
import Navbar from "../NavBar/Navbar";
import Noresult from "../Exam/Noresult";
import Previous from "../Exams/Previous";
import PreviousAnswers from "../Exams/PreviousAnswers";
import Cart from "../Cart/Cart";
import Invoicw from "../Invoice/Invoicw";
import PurchaseList from "../Invoice/PurchaseList";
import Passed from "../Home/Passed";
import FAQ from "../Home/FAQ";
import Extgoals from "../Home/Extgoals";
import Studyplan from "../Studyplan/Studyplan";
import Studyplans from "../Studyplan/Studyplans";
import Studyplancotent from "../Studyplan/Studyplancotent";
import Career from "../Vaccencies/Career";
import MockTest from "../Studyplan/MockTest/Mocktest";
export default function AppRouter() {
  //==================================================== Variable Declaration and initialization =========================================================//

  const auth = getAuth();
  const [loading, setloading] = useState(true);
  const [isRegistered, setisRegistered] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [isloggedin, setisloggedin] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        
        const uid = user.uid;
        setisloggedin(true);
        const docRef = doc(db, "users", uid);
         onSnapshot(
           docRef,
           (snapshot) => {
             if (snapshot.exists()) {
               setuserDetails(snapshot.data());
               setisRegistered(true);
               setloading(false);
             } else {
               setisRegistered(false);
               setloading(false);
               setuserDetails(null);
             }
             setloading(false);
           },
           (error) => {
             console.error("Error fetching document:", error);
           }
         );
      } else {
        setisloggedin(false);
        setisRegistered(false);
        setuserDetails(null);
        setloading(false);
      }
    });
    

    return () => {
      unsubscribe();
    };
  }, [auth, isloggedin]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Navbar {...userDetails} />
          <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route index element={<Home {...userDetails} />} />
            <Route
              path="/bookdetails"
              element={<Bookdetails {...userDetails} />}
            />
            {!isloggedin ? <Route path="/login" element={<Login />} /> : null}
            {isloggedin && !isRegistered ? (
              <Route path="/register" element={<Register />} />
            ) : null}
            <Route path="/purchase-list" element={<PurchaseList />} />
            <Route path="/exam" element={<Exams />} />
            <Route path="/chapter" element={<Chapter />} />
            <Route path="/pdfviewer" element={<PdfViewer />} />
            <Route path="/examresult" element={<ExamResult />} />
            <Route path="/package" element={<Package {...userDetails} />} />
            <Route path="/myshelf" element={<MyShelf />} />
            <Route path="/profile" element={<Profile {...userDetails} />} />
            <Route path="/exams" element={<Exampage />} />
            <Route path="/payment" element={<Stripe />} />
            <Route path="/about-info" element={<About />} />
            <Route path="/purchasebook" element={<LockedBooks />} />
            <Route path="/Noresult" element={<Noresult />} />
            <Route path="/Previous" element={<Previous />} />
            <Route path="/answerKey" element={<PreviousAnswers />} />
            <Route path="/invoice" element={<Invoicw />} />
            <Route path="/ext" element={<Extgoals />} />
            <Route
              path="/contact-info"
              element={<Contact {...userDetails} />}
            />
            <Route path="/aboutus-info" element={<Aboutus />} />{" "}
            <Route
              path="/acca-levels/levelbooks"
              element={<LevelBooks {...userDetails} />}
            />
            <Route path="/privacypolicy-info" element={<Privacy />} />
            <Route path="/pass" element={<Passed />} />
            <Route path="/refundpolicy-info" element={<Refund />} />
            <Route path="/termsofservice-info" element={<Terms />} />
            <Route path="/payment-success" element={<Success />} />
            <Route path="/payment-cancel" element={<Cancel />} />
            <Route path="/viewbooks" element={<Viewboks {...userDetails} />} />
            <Route path="/results" element={<Results />} />
            <Route
              path="/books-search"
              element={<SearchBook {...userDetails} />}
            />
            <Route path="/vacancies" element={<Vac {...userDetails} />} />
            <Route path="/acca-levels" element={<Studeies />} />
            <Route
              path="/editprofile"
              element={<Editprofile {...userDetails} />}
            />
            <Route path="/faq" element={<FAQ />} />
             <Route path="/studyplan" element={<Studyplan />} />
             <Route path="/studyplans" element={<Studyplans />} />
             <Route path="/studyplancotent" element={<Studyplancotent />} />
             <Route path="/career" element={<Career/>} />
             <Route path="/mocktest" element={<MockTest />} />

          </Routes>
          <Register />
          {isloggedin && isRegistered ? <Cart /> : <Login />}
        </>
      )}
      {userDetails ? <Passed /> : null}
    </div>
  );
}
