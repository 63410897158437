import { useEffect, useState } from "react";
import { getCountries, getCountry, getStates } from "country-state-picker";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../Config/Config";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';

export default function Profile(user) {
  //========================================================= Const Declearation ==============================================================//
  const [gender, setgender] = useState();
  const [name, setname] = useState();
  const [countryCodeList, setcountryCodeList] = useState([]);
  const [countryCode, setcountryCode] = useState("India");
  const [state, setstate] = useState();
  const [stateList, setstateList] = useState([]);
  const [district, setdistrict] = useState();
  const [dob, setdob] = useState(new Date());

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    setcountryCode(user.country);
    const countries = getCountries();
    setcountryCodeList(countries);
    setname(user.name);
    setgender(user.gender);
    setstate(user.state);
    setdistrict(user.district);
    setdob(formatDate(new Date(user.dob.seconds * 1000)));
    if (countryCode) {
      let country = getCountry(countryCode);
      let states = getStates(country.code);
      setstateList(states);
    }
  }, [user.country, user.name, user.gender, user.state, user.district, user.dob, countryCode]);

  //========================================================= Uploading ==============================================================//

  const handleSubmit = async () => {
    await updateDoc(doc(db, "users", user.uid), {
      name: name,
      avatar: "m1avatar.png",
      country: countryCode,
      state: state,
      district: district,
      dob: new Date(dob),
      gender: gender,
    }).then(() => {
      handleClick();
      window.location.href="/profile"
    });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className=" bg-gray-100 py-6">
      <div className="mx-auto max-w-4xl  bg-white shadow-md rounded-lg overflow-hidden">
        <div className="border-b border-gray-200 p-6">
          <h1 className="text-2xl font-semibold text-gray-800">Edit Profile Details</h1>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-1 gap-y-6">
            <div className="flex items-center">
              <label htmlFor="name" className="w-1/3 text-sm font-medium text-gray-500">Full Name</label>
              <input
                id="name"
                className="w-2/3 py-2 pl-4 border border-gray-300 focus:outline-none focus:border-black rounded-md"
                type="text"
                placeholder="Enter your Name"
                required
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="gender" className="w-1/3 text-sm font-medium text-gray-500">Gender</label>
              <select
                id="gender"
                className="w-2/3 py-2 pl-4 border border-gray-300 focus:outline-none focus:border-black rounded-md"
                value={gender}
                onChange={(e) => setgender(e.target.value)}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="flex items-center">
              <label htmlFor="dob" className="w-1/3 text-sm font-medium text-gray-500">DOB</label>
              <input
                id="dob"
                className="w-2/3 py-2 pl-4 border border-gray-300 focus:outline-none focus:border-black rounded-md"
                type="date"
                value={dob}
                onChange={(e) => setdob(e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="country" className="w-1/3 text-sm font-medium text-gray-500">Country</label>
              <select
                id="country"
                className="w-2/3 py-2 pl-4 border border-gray-300 focus:outline-none focus:border-black rounded-md"
                value={countryCode}
                onChange={(e) => setcountryCode(e.target.value)}
                required
              >
                {countryCodeList.map((doc, index) => (
                  <option key={index} value={doc.name}>{doc.name}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <label htmlFor="state" className="w-1/3 text-sm font-medium text-gray-500">State</label>
              <select
                id="state"
                className="w-2/3 py-2 pl-4 border border-gray-300 focus:outline-none focus:border-black rounded-md"
                value={state}
                onChange={(e) => setstate(e.target.value)}
                required
              >
                {stateList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <label htmlFor="district" className="w-1/3 text-sm font-medium text-gray-500">District</label>
              <input
                id="district"
                className="w-2/3 py-2 pl-4 border border-gray-300 focus:outline-none focus:border-black rounded-md"
                type="text"
                placeholder="Enter your District"
                required
                value={district}
                onChange={(e) => setdistrict(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end p-6">
          <button
            onClick={handleSubmit}
            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            This is a success message!
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}
