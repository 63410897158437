import { useEffect, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { app, db } from "../Config/Config";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./banner.css";
import loadImage from "../Book/Images/web banner placeholder.png"
import User from "../Auth/User";
import UserData from "../Config/UserData";
export default function Banner() {
     const uid = User();
     const data = UserData(uid);
     const [userData,setuserData] = useState();
    useEffect(()=>{
      setuserData(data)
    },[data])


  AOS.init();

  const [bannerList, setBannerList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const fetchBanners = async () => {
    setBannerList([]);
    const q = query(
      collection(db, "banners"),
      orderBy("order", "asc"),
      where("status", "==", true)
    );

    const querySnapshot = await getDocs(q);
    const bannersData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      url: doc.data().img,
      data: doc.data(),
    }));
    setBannerList(bannersData);
  };

  useEffect(() => {
    fetchBanners();
  }, []);
const [loadFlag,setloadFlag] = useState(false)
  useEffect(() => {
    
    const intervalId = setInterval(() => {
      setloadFlag(true);
      const isLastSlide = currentIndex === bannerList.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      const img = new Image();
      img.onload = setloadFlag(false);
    }, 5000); // Change the interval (in milliseconds) to control the auto slide change speed

    return () => clearInterval(intervalId);
  }, [currentIndex, bannerList]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  // click function
  const functions = getFunctions(app, "asia-south1");
  const paymentFunction = () => {
    const bannerClick = httpsCallable(functions, "bannerClick");
    bannerClick({
      uid: uid?uid:"not-signedin",
      id: bannerList[currentIndex].id,
    });
  };
  const onBannerClick = () => {
    if (bannerList[currentIndex].data.type === "volume_offer") {
      navigate("/package");
    } else if (bannerList[currentIndex].data.type === "book_offer") {
      navigate("/purchasebook");
    } else if (bannerList[currentIndex].data.type === "whatsapp") {
      const whatsappLink = `https://wa.me/${
        bannerList[currentIndex].data.whatsapp
      }?text=${encodeURIComponent(bannerList[currentIndex].data.w_message)}`;
      window.open(whatsappLink, "_blank");
    } else if (bannerList[currentIndex].data.type === "webpage") {
      window.open(bannerList[currentIndex].data.link, "_blank");
    }
    paymentFunction();
  };
  
  return (
    <section
     
      className=" px-4 mx-auto max-w-8xl sm:px-6 lg:px-8 bg-white ark:text-gray-00 py-6"
    >
      {bannerList.length ? (
        <div className="max-w-8xl mx-auto py-12 px-4 sm:px-6 md:px-8 relative group">
          <div
            onClick={onBannerClick}
            style={{
              backgroundImage: loadFlag
                ? `url("${loadImage}")`
                : `url("${bannerList[currentIndex].url}")`,
              transition: "background-image 0.3s ease-linear", // Add transition to background-image
            }}
            className="banner"
          />

          <div className="flex top-4 justify-center py-2">
            {bannerList.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
                className={`text-2xl cursor-pointer ${
                  slideIndex === currentIndex ? "text-black" : "text-gray-400"
                }`}
              >
                <RxDotFilled />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </section>
  );
}
