import React, { useState } from 'react';

export default function Level() {
 
     
  return (
   

    <div className="bg-white py-6">
    <div className="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
      <h1 className="lg:text-7xl text-4xl md:text-5xl font-bold tracking-tight text-gray-900 text-center">
        The goals of
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900">
          Goodwill Academy
        </span>
      </h1>
      {/* <p className="mt-8 text-xl text-gray-800 max-w-4xl text-center">
        The significance of ACCA in TGA shall be delivered later today.
      </p> */}
    </div>

    <div className="mx-auto bg-white max-w-7xl sm:px-6 lg:px-8 2xl:rounded-xl">
      <div className="sm:flex items-center max-w-screen-xl">
        <div className="sm:w-1/2 p-10">
          <div className="image object-center text-center rounded-xl">
            <img
              alt="Img"
              className="rounded-2xl"
              src="https://img.freepik.com/free-photo/woman-with-graduation-diploma_23-2148928762.jpg?w=740&t=st=1700807512~exp=1700808112~hmac=6d8967c181f72e2bbd390da17ecbf3821a46847e6b3d065db27658a4a813cc8b"
            />
          </div>
        </div>

        <div className="sm:w-1/2 p-5">
          <div className="text">
            <h2 className="my-4 font-normal text-3xl sm:text-4xl">
              <span className="text-blue-600">Professional Coaching Online</span>
            </h2>
            
            Online coaching classes have become increasingly popular among ACCA students, particularly through platforms like The Goodwill Academy, due to their numerous features and benefits. 
              
                  <p className='mt-2'>Here’s an overview tailored for students interested in securing ACCA membership.</p>
                  <h3 className="font-bold py-2">Features of Online Coaching Classes with The Goodwill Academy</h3>
                  <p>
                  1. Flexibility: Online classes offer a high degree of flexibility, allowing students to access materials and lectures at their convenience. This is particularly beneficial for working professionals or those with busy schedules, as they can study at their own pace and choose times that suit them best.
                  </p>
                 
                  <p>
                  2. Global Accessibility: Students can connect with study resources of The Goodwill Academy from around the world, breaking geographical barriers. This accessibility allows for a diverse learning experience and the opportunity to learn from experts in the field
                  <a
            href='/ext'
            
              className="ml-3 rounded text-blue-600  py-4  "
            >
             Read More
            </a>
                  </p>
                  {/* <h5 className="font-bold py-2">Experienced Instructors</h5>
                  <p>
                    The online classes are assisted and guided by experienced ACCA instructors who prepared the course materials to help students grasp the concepts and develop the necessary skills to excel in the exams and earn higher credits in each exam.
                  </p>
                  <h5 className="font-bold py-2">Diverse Learning Resources</h5>
                  <p>
                    Students have access to a wide range of learning resources such as video lectures, detailed notes, and multiple exam sessions to practice timing of exams and memorize the importance in quick answering techniques, and many more. This variety caters to different learning styles and enhances the overall learning experience.
                  </p>
                  <h5 className="font-bold py-2">Personalized Support</h5>
                  <p>
                    The academy provides personalized support to each student, helping them create a customized study plan and progress at their own pace. This ensures that students get the support they need to succeed.
                  </p>
                  <h5 className="font-bold py-2">Placement Assistance</h5>
                  <p>
                    The academy also offers placement assistance, including mock interviews, industry masterclasses, resume drafting, and job application support. This helps students transition smoothly into their desired careers after becoming ACCA qualified.
                  </p>
                  <p className="py-2">
                    By providing a flexible, comprehensive, and supportive learning environment, The Goodwill Academy's online ACCA coaching aims to help students achieve their goal of becoming ACCA members and advancing their careers in accounting and finance.
                  </p> */}
                  
                </div>
              
            
            
           
          </div>
          
        </div>
      </div>
    </div>
  
 
 

  )
}
