import { doc, getDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db } from "../../Config/Config";

const PurchasePopup = ({ onClose, onSubmit }) => {

    const [accept, setAccept] = useState(false);




    useEffect(() => {
      fetch_paymentPolicy();
    }, []);
    const [policyContent, setpolicyContent] = useState();
    const fetch_paymentPolicy = async () => {
      const docRef = doc(db, "basic_info", "payment_policy");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setpolicyContent(docSnap.data().content);
      }
    };


  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(); // Pass the input value to the callback
    //onClose(); // Close the popup
  };

  return (
    <div className="popup">
      <div className="fixed top-0 left-0 w-full h-full flex justify-center inset-0 z-50 items-center bg-gray-500 bg-opacity-50">
        <div
          className="relative bg-white p-6 rounded-md"
          style={{ width: "50%" }}
        >
          <button
            className="absolute top-0 right-0 mt-4 mr-2"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h2 className="text-lg font-semibold mb-4">Terms and Conditions</h2>
      

          <div
            className="content"
            style={{ maxHeight: "300px", overflowY: "auto" }}
          >
            <pre className="content">{policyContent}</pre>
          </div>

          <div className="mt-4 flex justify-between items-center">
            <label htmlFor="termsCheckbox" className="flex items-center">
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={accept}
                onChange={(e) => setAccept(e.target.checked)}
                className="mr-2"
              />
              <span className="text-gray-800">
                I accept the terms and conditions
              </span>
            </label>
            <button
              disabled={!accept}
              onClick={handleSubmit}
              className={`px-4 py-2 rounded-md ${
                !accept
                  ? "bg-gray-300 text-black cursor-not-allowed"
                  : "bg-black text-white"
              }`}
            >
              Proceed
            </button>
          </div>
          <div className="mt-4 flex justify-end"></div>
        </div>
      </div>
    </div>
  );
};
export default PurchasePopup;
