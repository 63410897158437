import React from "react";

export default function Refund() {
  return (
    <div>
      <div class="max-w-6xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
        <div class="max-w-6xl">
          <div class="space-y-8 md:space-y-12">
            <div class="space-y-3">
              <h2 class="text-2xl text-center py-4 font-bold md:text-3xl dark:text-black">
                Cancellation & Refund Policy URL
              </h2>

              <p class="text-lg text-black dark:text-black">
                At The Goodwill Academy, we value customer satisfaction, which
                is why we offer a Payment Refund Policy to assist you with
                refund requests. If you've enrolled in a course or purchased a
                product/service directly through our website, you can request a
                refund within 10 days of your purchase. Please note that refunds
                are not available if you've completed over 50% of the course or
                violated our terms and conditions. To request a refund, simply
                contact our customer support team with your order details and
                reason for the request. Once approved, refunds will be processed
                within a specified number of business days. Keep in mind that
                our Payment Refund Policy may be subject to occasional updates,
                so please check our website for the latest information. Your
                satisfaction is our priority, and we are here to assist you
                throughout this process. Updated: This Privacy Policy was last
                updated on 18/08/2023.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
