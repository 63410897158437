import React from "react";
import gif from "./s.gif";

export default function Cancel() {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="relative">
        <img src={gif} alt="GIF" className="h-full w-full" />
      </div>
      <h1 className="text-3xl font-bold animate-bounce mt-4">Payment Cancelled</h1>
    </div>
  );
}
