import { doc, getDoc } from 'firebase/firestore';
import  { useEffect, useState } from 'react'
import { db } from './Config';

export default  function UserData(uid) {
      const [userDetails, setuserDetails] = useState(null);
      const [isRegistered,setisRegistered] = useState(false);
      const checkRegistrationStatus = async (id) => {
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setuserDetails(docSnap.data());
          setisRegistered(true);
        } else {
            setisRegistered(false);
        }
      };
      useEffect(()=>{
          if(uid&&!isRegistered){
              if(uid!==false){
                checkRegistrationStatus(uid);
              }
          }
      },[uid,isRegistered])

  return userDetails?userDetails:false;
}
