import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../Config/Config';
import Spinner from '../assets/Loading';
import { getAuth } from 'firebase/auth';

const Studyplancotent = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
// =================================== fetch Topics ===================================//
const [topicList, settopicList] = useState([]);
const [dayDetails, setdayDetails] = useState([]);
const fetchTopics = async (planId, dayId) => {
  const ref = collection(db, "study_plan", planId,"day", dayId, "topics");
  const querySnapshot = await getDocs(ref);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push({...doc.data(),id:doc.id});
  });
  const dayRef = doc(db, "study_plan", planId,"day", dayId);
  const dayDoc = await getDoc(dayRef);
  if (dayDoc.exists()) {
    setdayDetails(dayDoc.data());
  }
  settopicList(data);
  setLoading(false);
}
useEffect(() => {
  setLoading(true);
  const params = new URLSearchParams(location.search);
  const  dayId= params.get('d').trim();
  const planId = params.get('p').trim();
  if(dayId && planId){
      fetchTopics(planId, dayId);
  }
}, [location]);

// =================================== Check User ===================================//

const navigate = useNavigate();
const fetchUser = async (id,chapter_id, path) => {
  setLoading(true);
  const auth = getAuth();
  const user = auth.currentUser;
  if(user){
    const docSnap = await getDoc(doc(db, "users", user.uid));
    if (docSnap.exists()) {
      if(path ==="/chapter"){
      const details = docSnap.data();
      if ( details.my_books.includes(id)){
        const bookRef = doc(db, "book_pricing", id);
      const bookDoc = await getDoc(bookRef);
      if (bookDoc.exists()) {
          const chapterRef = doc(db, "chapters", chapter_id);
          const chapterDoc = await getDoc(chapterRef);
          if (chapterDoc.exists()) {
              navigate("/chapter", {
                state: {
                  data: { id: id, data: chapterDoc.data() },
                  id: id,
                  book: bookDoc.data(),
                  page: "nil",
                },
              });
          } else {
              alert("Chapter is not updated yet");
          }
      }
      } else {
        navigate("/purchasebook");
      }
    } else {
      navigate(path, {
        state: {
          ...dayDetails
        }
      })
    }
    } else {
        localStorage.setItem("register_popup", true);
         const storageEvent = new Event("register");
         window.dispatchEvent(storageEvent);
         setLoading(false);
    }
  } else {
     localStorage.setItem("login_popup", true);
     const storageEvent = new Event("storage");
     window.dispatchEvent(storageEvent);
     setLoading(false);
  }
}


  return (
    <div className="bg-gradient-to-br from-gray-50 via-white to-gray-100 ">
      {loading ? (
        <Spinner />
      ) : (
        <div className=" mx-auto max-w-7xl min-h-screen px-4 items-center py-12">
          <div className="flex justify-between px-2 items-center mb-8">
            <h2 className="text-2xl font-medium text-gray-700">
              Study Plan Topics
            </h2>
            <button onClick={() => 
            {
              fetchUser("", "", "/mocktest");
            }} className="text-blue-900 border border-blue-900 px-6 py-2 rounded-xl font-semibold text-sm hover:bg-blue-50 hover:text-blue-700 transition duration-300">
              Take Mock Test
            </button>
          </div>
          <div className="space-y-4">
            {topicList &&
              topicList.map((topic, index) => (
                <div
                  key={index}
                  className="w-full flex items-center justify-between p-6 rounded-lg  border border-double transition duration-300"
                >
                  <div className="flex items-center py-3 ">
                    <h3 className="text-xl font-semibold text-gray-700">
                      {topic.title}
                    </h3>
                  </div>
                  <button
                    onClick={() =>
                      fetchUser(topic.book_id, topic.chapter_doc_id, "/chapter")
                    }
                    className="text-blue-900 bg-white border border-blue-900 px-4 py-2 rounded-xl text-sm font-medium hover:bg-blue-50 hover:text-blue-900 transition duration-300"
                  >
                    View
                  </button>
                </div>
              ))}
          </div>
          {console.log()}
        </div>
      )}
    </div>
  );
};

export default Studyplancotent;
