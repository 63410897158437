import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useState } from 'react';
import { db } from '../Config/Config';

const FAQ = () => {


  const [faqList,setfaqList] = useState([]);
  const fetchFaq =async() =>{
      setfaqList([]);
      const q = query(
        collection(db, "faq_query"),
        orderBy("order", "asc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setfaqList((prev) => {
          return [
            ...prev,
            {
              id: doc.id,
              data: doc.data(),
            },
          ];
        });
      });
  }

  useState(()=>{
    fetchFaq();
  })
  

  const FaqItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="mb-4">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-between w-full px-4 py-2 text-left bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100"
        >
          <span className="text-lg font-normal ">{question}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-6 w-6 ${isOpen ? 'transform rotate-180' : ''}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? 'M19 9l-7 7-7-7' : 'M5 15l7-7 7 7'}
            />
          </svg>
        </button>
        {isOpen && <p className="mt-2 ml-4">{answer}</p>}
      </div>
    );
  };

  return (
    <div className="bg-white p-8 py-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold mb-4 py-6">Frequently Asked Questions</h2>
        {faqList.map((faq, index) => (
          <FaqItem key={index} question={faq.data.question+"?"} answer={faq.data.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
