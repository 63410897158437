import React from "react";
import ExamBooks from "../Book/ExamBooks";

export default function Exampage() {
  //======================================== Variable Declerations =========================================//


  return (
    <div>
      <br />

      <div className="bg-white p-4 rounded-lg ">
      
        <div className="mt-4">
          <ExamBooks />
        </div>
      </div>
    </div>
  );
}
