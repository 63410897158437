import React from 'react'
import Message from './components/Auth/Message';
import AppRouter from './components/Root/AppRouter';

export default function App() {
Message();

  return (
    <div>
      <AppRouter />
    </div>
  );
};


