import React, { useEffect, useState } from 'react'
import Banner from '../Banner/Banner'
import Footer from './Footer';
import Main from './Main'
import Contact from '../Contact/Contact';
import Organization from './Organization';
import Level from './Level';
import Studeies from './Studeies';
import Testimonial from './Testimonial';
import Mobile from './Mobile';
import Section from './Section';
import PageClick from '../Config/PageClick';
import UserData from '../Config/UserData';
import User from '../Auth/User';
import FAQ from './FAQ';
export default function Home (data) {
  // ====================================================== Click Report ==============================================//
    const clickReport= PageClick("home_page");
    const uid = User();
    const userData = UserData(uid)
    const [user,setuser] = useState();
    useEffect(()=>{
      setuser(userData);
      data?setuser(data):setuser(null);
      if(clickReport){
        // console.log(clickReport)
      }
    },[clickReport,user,userData,data])

  return (
    <div>
      <Main />
      <Organization />
      <Studeies />
      <Level />
      <Section />
      <Banner user={user} />
      {/* <ChapterList user={data} /> */}2
      <Testimonial />
      {/* <Video /> */}
      <Mobile />
      <FAQ/>
      <Contact user={user} />
      <Footer />
    </div>
  );
}
