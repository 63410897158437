import {  doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../Config/Config";
import ReactApexChart from "react-apexcharts";
import User from "../Auth/User";
import BookResults from "./BookResults";
import Spinner from "../assets/Loading";
import Noresult from "./Noresult";
export default function Results() {
  const uid = User();
  const BookResult = BookResults();
  const [bookIndex,setbookIndex] = useState(-1);
  const [loading,setloading] = useState(true);
    const [dataFlag, setdataFlag] = useState(false);


  //======================================== Total result fetching =========================================//
  const [totalData, settotalData] = useState(null);
  const [totalChart, settotalChart] = useState([]);

  const fetchTotal = async (Id) => {
    if (Id) {
      const docRef = doc(db, "users", Id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
       
        settotalChart({
          data: [
            parseInt(docSnap.data().total_right_answers) > 0
              ? (
                  (parseInt(docSnap.data().total_right_answers) /
                    parseInt(docSnap.data().total_questions)) *
                  100
                ).toFixed(0)
              : 0,
            parseInt(docSnap.data().total_wrong_answers) > 0
              ? (
                  (parseInt(docSnap.data().total_wrong_answers) /
                    parseInt(docSnap.data().total_questions)) *
                  100
                ).toFixed(0)
              : 0,
            parseInt(docSnap.data().total_unattended_questions) > 0
              ? (
                  (parseInt(docSnap.data().total_unattended_questions) /
                    parseInt(docSnap.data().total_questions)) *
                  100
                ).toFixed(0)
              : 0,
          ],
          name: "Series A",
        });

        settotalData(docSnap.data());
      }
    }
  };
  //======================================== Pie diagram =========================================//

  useEffect(() => {
    if (uid !== false) {
      fetchTotal(uid);
    }
  }, [uid]);


  useEffect(()=>{
    if(totalData&&totalData.exam_attempt===0){
      setdataFlag(true);
      setloading(false);
    } else if(totalData&&totalData.exam_attempt>0){
      setloading(false);
    }
  },[totalData,dataFlag])
  const chartData = [
    {
      data: [44, 55, 41],
      name: "Series A",
    },
  ];

  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: function () {
              return totalData ? `${parseInt(totalChart.data[0])} %` : 0;
            },
          },
        },
      },
    },
    labels: ["Correct", "Wrong", "Skip"],
    colors: ["#47ff6c", "#ff0026", "#1975ff"],
  };
  //======================================== Bar diagram =========================================//
  const [chartData2, setChartData2] = useState();

  useEffect(() => {
    setChartData2({
      series: [
        {
          name: "Right Answers",
          data: BookResult.map((subject) => subject.data.right),
        },
        {
          name: "Wrong Answers",
          data: BookResult.map((subject) => subject.data.wrong),
        },
        {
          name: "Skipped Questions",
          data: BookResult.map((subject) => subject.data.skipped),
        },
      ],
      options: {
        chart: {
          type: "bar",
          stacked: false,
          height: 350,
        },
        xaxis: {
          categories: BookResult.map((subject) => subject.name),
          labels: {
            formatter: function (value) {
              return value.length > 10 ? value.substring(0, 10) : value;
            },
          },
        },
        yaxis: {
          title: {
            text: "Answers",
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: true, 
        },
        tooltip: {
          y: {
            formatter: (val) => `${val} %`,
          },
        },
      },
    });
  }, [BookResult]); 



  return (
    <body class="bg-[#F8F9FE]">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {dataFlag ? (
            <Noresult />
          ) : (
            <>
              <div className=" p-4 grid grid-cols-1 md:col-span-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
                {totalData ? (
                  <div class="bg-white p-4 rounded-xl md:col-auto shadow-sm">
                    {chartData.map((chart, index) => (
                      <div key={index} className="radial-bar-chart">
                        <h2 class="text-lg font-bold">Overall Performance</h2>
                        <ReactApexChart
                          options={options}
                          series={totalChart.data}
                          type="radialBar"
                          height={300}
                        />
                      </div>
                    ))}
                    <div class="flex items-center justify-center space-x-2">
                      <div class="w-4 h-4 rounded-full bg-green-400"></div>
                      <span>Correct</span>

                      <div class="w-4 h-4 rounded-full bg-red-500"></div>
                      <span>Wrong</span>

                      <div class="w-4 h-4 rounded-full bg-blue-500"></div>
                      <span>Skip</span>
                    </div>
                  </div>
                ) : null}
                <div class="bg-white p-4   rounded-xl md:col-auto  shadow-sm">
                  <div class="flex justify-between mb-4">
                    <h2 class="text-lg font-bold">Overall Performance</h2>
                    <h2 class="text-lg font-bold">Score</h2>
                  </div>

                  <ul class="space-y-4">
                    <li class="flex justify-between items-center">
                      <span class=" text-sm">Total exam attempts</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold text=">
                          {totalData ? totalData.exam_attempt : 0}
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Correct Answers</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold">
                          {totalChart.data ? totalChart.data[0] : 0}%
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Wrong Answer</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold">
                          {totalChart.data ? totalChart.data[1] : 0}%
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Skipped Questions</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold">
                          {totalChart.data ? totalChart.data[2] : 0}%
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="bg-white p-4  rounded-xl   md:col-auto  shadow-sm">
                  <div class="flex justify-between mb-4">
                    <h2 class="text-lg font-bold">Overall Scoresheet</h2>
                    <h2 class="text-lg font-bold">Score</h2>
                  </div>

                  <ul class="space-y-4">
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Total question attended</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold">
                          {totalData ? totalData.total_questions : 0}
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Correct answers</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold">
                          {totalData ? totalData.total_right_answers : 0}
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Wrong answers</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold">
                          {totalData ? totalData.total_wrong_answers : 0}
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Skipped questions</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold">
                          {totalData ? totalData.total_unattended_questions : 0}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <body class="grid grid-cols-1 md:grid-cols-3 gap-8 p-4">
                <div className="col-span-2 bg-white rounded-xl">
                  {chartData2 && BookResult ? (
                    <div id="chart">
                      <h2 class="text-lg font-bold ml-3 mt-2"> Performance (Books)</h2>
                      <ReactApexChart
                        options={chartData2.options}
                        series={chartData2.series}
                        type="bar"
                        height={350}
                      />
                    </div>
                  ) : null}
                </div>
                <div class="col-span-1 md:col-span-1 bg-white p-4 rounded-xl shadow-sm">
                  <div class="flex justify-between mb-4">
                    <h2 class="text-lg font-bold">Results (Books)</h2>
                    <h2 class="text-lg font-bold">Score</h2>
                  </div>
                  <select
                    onChange={(e) => {
                       setbookIndex(e.target.value);
                    }}
                    class="text-sm w-full  appearance-none border border-gray-300 bg-white py-2 px-4 rounded-md leading-tight focus:outline-none focus:border-blue-500"
                  >
                    <option value={-1} disabled selected hidden>
                      Select Your Book
                    </option>
                    {BookResult ? (
                      <>
                        {BookResult.map((doc, index) => {
                          return (
                            <option value={index}>{doc.datas.book_name}</option>
                          );
                        })}
                      </>
                    ) : null}
                  </select>
                  <ul class="space-y-4 mt-5">
                    <li class="flex justify-between items-center">
                      <span class=" text-sm">No of attempts</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-lg text-blue-500 font-bold text=">
                          {bookIndex > -1
                            ? BookResult[bookIndex].datas.exam_attempt
                            : 0}
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Total question attended</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-sm text-blue-500 font-bold">
                          {bookIndex > -1
                            ? BookResult[bookIndex].datas.total_questions
                            : 0}
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Correct answers</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-lg text-blue-500 font-bold">
                          {bookIndex > -1
                            ? BookResult[bookIndex].datas.total_right_answers
                            : 0}
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Wrong answer</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-lg text-blue-500 font-bold">
                          {bookIndex > -1
                            ? BookResult[bookIndex].datas.total_wrong_answers
                            : 0}
                        </span>
                      </div>
                    </li>
                    <li class="flex justify-between items-center">
                      <span class="text-sm">Skipped questions</span>
                      <div class="w-10 h-10 flex items-center justify-center bg-blue-100 rounded-full">
                        <span class="text-lg text-blue-500 font-bold">
                          {bookIndex > -1
                            ? BookResult[bookIndex].datas
                                .total_unattended_questions
                            : 0}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </body>
            </>
          )}
        </>
      )}
    </body>
  );
}
