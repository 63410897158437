import React from "react";
import "aos/dist/aos.css";
import PageClick from "../Config/PageClick";

import Search from "./Search";

export default function SearchBook() {
    PageClick("search_book");
 
 
  return (
    <>
      <div>
      <Search/>
      </div>
    </>
  );
}
