import React from 'react'

export default function Aboutus() {
  return (
<div class=""> 

  <div>
    <div class="max-w-6xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
      <div class="space-y-8 md:space-y-12">
        <div class="space-y-3">
          <h2 class="text-2xl text-center py-4 font-bold md:text-3xl dark:text-black">About Us</h2>
          <p class="text-lg text-black dark:text-black font-bold text-justify">About the Goodwill Academy</p>
          <p>
The Goodwill Academy, founded by experienced professionals in commerce and finance, is dedicated to helping students excel in their ACCA exam pursuits through high-quality support and resources. This digital learning hub leverages the expertise of seasoned educators to assist students in mastering their ACCA exams. 
</p>
<p>
The academy provides accessible, flexible, and high-quality education worldwide, utilizing advanced technology and innovative teaching methods to empower students for personal and professional success. By focusing on improvement and global reach, The Goodwill Academy excels in online education and aims to deliver systematic and rigorous professional learning opportunities.
</p>
<p>
The Goodwill Academy is dedicated to providing high-quality education through its online platform, catering to a diverse range of professional students. Our faculty consists of qualified experts who are committed to delivering exceptional instruction and guidance.
</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Curriculum and Learning Environment</p>

<p>Our curriculum is meticulously designed to meet rigorous academic standards, ensuring that students are well-prepared for future success. The faculty employs a variety of innovative teaching methodologies tailored to accommodate different learning styles, fostering an inclusive and engaging learning atmosphere.</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Commitment to Student Success</p>
<p>
At The Goodwill Academy, we prioritize creating a supportive environment where every student feels valued. Open communication between study resources and students is encouraged around the clock, and we offer additional resources such as mentorship programs and academic support to help students overcome challenges and achieve their full potential.
</p>
<p>
By integrating advanced technology and ergonomic learning spaces, The Goodwill Academy aims to enhance the educational experience, enabling students to engage actively in their studies and develop critical thinking skills. Our commitment to excellence empowers students to become lifelong learners and successful professionals in their respective fields.
</p>
<p>
Our objective is to deliver top-notch, all-encompassing study resources and guidance to guarantee our students' achievements. Created by a team of knowledgeable commerce and financial specialists, The Goodwill Academy aims to help students succeed in their ACCA exam endeavors. We are dedicated to providing superior, thorough educational materials and support to ensure our students' success.
</p>
<p>
Our team consists of ACCA tutors and learning partners who have a proven track record of helping students achieve their goals. We are committed to staying up-to-date with the latest ACCA syllabus changes and exam formats to ensure our materials are always relevant and effective.
</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Our platform offers a wide range of resources, including:</p>

<p>
Detailed study notes covering the full ACCA syllabus
Exam-standard practice questions and mock exams
Personalized feedback and support from our expert tutors
Interactive quizzes and flashcards to reinforce learning
Access to the ACCA mock exams Practice Platform to familiarize students with the exam environment.

At The Goodwill Academy, we believe in the power of continuous learning and development. We regularly update our materials and resources to ensure our students are always learning the most relevant and valuable skills for their future careers.

</p>
<p>
Join us on our mission to help ACCA students achieve their full potential. Sign up today and take the first step towards a successful career in accounting and finance.
</p>


<p class="text-lg text-black dark:text-black font-bold text-justify">Management Responsibilities</p>
<p>
The management of the online ACCA coaching program is entrusted with several critical responsibilities:
</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Curriculum Development</p>
<p>
We are committed to ensuring that our curriculum remains in strict alignment with the most current ACCA syllabus and industry benchmarks. This involves ongoing collaboration with industry experts to deliver content that is both relevant and practical, fully preparing students for their exams and their future careers.
</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Technology Integration</p>
<p>
Our focus is on implementing cutting-edge online platforms that facilitate a rich, interactive learning experience. This includes offering recorded sessions and comprehensive study materials. The technology we deploy is designed to support diverse learning styles, ensuring tools are in place for both engagement and assessment.
</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Student Support Services</p>
<p>
We offer robust personalized assistance through mentorship, career counselling, and placement support. This includes services like mock interviews and resume drafting, aimed at enhancing students' employability as they transition from education to the professional world.
</p>
<p class="text-lg text-black dark:text-black font-bold text-justify">Quality Assurance</p>
<p>
A commitment to excellence drives us to regularly evaluate the effectiveness of our coaching programs. Through student feedback and performance metrics, we ensure continuous improvement and the maintenance of high educational standards
</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Ideas and Rules</p>
<p>
To create an optimal learning environment, we have established the following principles:
</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Flexible Scheduling</p>
<p>
Our platform allows students to access classes and materials at their convenience, accommodating various time zones and personal commitments.
</p>
<p class="text-lg text-black dark:text-black font-bold text-justify">Regular Assessments</p>
<p>
Periodic quizzes and mock exams after each session of classes are conducted to monitor progress and identify areas for improvement.
</p>

<p class="text-lg text-black dark:text-black font-bold text-justify">Community Building</p>
<p>
We encourage the formation of forums and groups where students can interact, share resources, and support each other throughout their ACCA journey.
</p>
<p class="text-lg text-black dark:text-black font-bold text-justify">Current Vision and Future Plans</p>
<p>
Our current vision is to establish our online ACCA coaching as a leading platform, known for delivering top-tier education that meets the evolving demands of the finance and accounting sectors. Looking forward, our future plans include:
</p>
<p class="text-lg text-black dark:text-black font-bold text-justify">Expanding Course Offerings</p>
<p>
We aim to introduce specialized courses focusing on niche areas within finance and accounting, such as forensic accounting or sustainability reporting.
</p>
<p class="text-lg text-black dark:text-black font-bold text-justify">Global Partnerships</p>
<p>
By collaborating with international firms and educational institutions, we seek to enhance our curriculum and provide students with global perspectives and opportunities.
</p>
<p class="text-lg text-black dark:text-black font-bold text-justify">Enhanced Technology Use</p>
<p>
We plan to leverage artificial intelligence and data analytics to further personalize the learning experience and improve student outcomes.
</p>
<p class="text-lg text-black dark:text-black font-bold text-justify">Best Wishes to ACCA Aspirants</p>

<p>
To all ACCA aspirants, the management extends our heartfelt wishes for your success and resilience as you embark on this journey. Embrace the challenges and opportunities that come with pursuing this prestigious qualification. The skills and knowledge you acquire here will not only elevate your career prospects but also empower you to make meaningful contributions to the finance and accounting sectors. Stay dedicated, seek support when needed, and always believe in your potential to achieve your professional goals.
</p>
<p>
This undertaking reflects our unwavering commitment to supporting ACCA aspirants in their quest for excellence. We look forward to guiding you on this transformative educational journey.
</p>
        </div>
      </div>
    </div>
  </div>
</div>





    
  )
}
