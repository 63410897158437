import React, { useRef, useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  Pagination,
  connectSearchBox,
} from "react-instantsearch-dom";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { app, db } from "../Config/Config";
import { getFunctions, httpsCallable } from "firebase/functions";
import PurchasePopup from "./PreBuy/PurchasePopup";
import Spinner from "../assets/Loading";
import placeHolder from "./Images/web book tumb.png";
import "./book.css";
import "instantsearch.css/themes/satellite.css";

const searchClient = algoliasearch(
  "Y34JVGBY03",
  "c1616534611bb96408f3d0885a989c64",
);
const Hit = ({ hit }) => {
  const [loading, setloading] = useState(false);
  const [imageFlag, setimageFlag] = useState(true);

  const navigate = useNavigate();
  const [pBooks, setpBooks] = useState();
  const [dataC, setData] = useState();
  const [throughPageC, setthroughPage] = useState();
  const [statusC, setstatus] = useState();
  const [prePurchasePopup, setprePurchasePopup] = useState(false);
  // const [openPopup, setOpenPopup] = useState(true);

  const auth = getAuth(app);
  const purchaseCheck = async () => {
    if (auth.currentUser) {
      const userRef = doc(db, "users", auth.currentUser.uid);
      await getDoc(userRef).then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setpBooks(data);
        } else {
          console.log("Document not found");
        }
      });
    }
  };
  useEffect(() => {
    purchaseCheck();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //=========================================== Payment function ===========================================/
  const functions = getFunctions(app, "asia-south1");
  const paymentFunction = (data, throughPage, status) => {
    // through page check
    setloading(true);
    if (auth.currentUser && pBooks ) {
      if (throughPage) {
          navigate(`/bookdetails?b=${data.book_id} &p=book`);
      } else {
        const paymentRequest = httpsCallable(functions, "paymentRequest");
        paymentRequest({
          img: data.book_img,
          package: data.book_id,
          name: data.book_name,
          uid: auth.currentUser.uid,
          platform: "web",
          books: [data.book_id],
          collection: "book_pricing",
          // currency: userData.country === "India" ? "inr" : "usd",
          currency: "inr",
        }).then((result) => {
          const data = result.data;
          if (data) {
            window.location.href = data.url;
          } else {
            alert("Something went wrong!!");
            setloading(false);
          }
        });
      }
    } else {
      if (!auth.currentUser) {
        localStorage.setItem("login_popup", true);
        const storageEvent = new Event("storage");
        window.dispatchEvent(storageEvent);
        setloading(false);
      } else {
        localStorage.setItem("register_popup", true);
        const storageEvent = new Event("register");
        window.dispatchEvent(storageEvent);
        setloading(false);
      }
    }
  };

  const handleprePurchase = (data, throughPage, status) => {
    if (throughPage) {
      paymentFunction(data, throughPage, status);
    } else {
      setData(data);
      setthroughPage(throughPage);
      setstatus(status);
      setprePurchasePopup(true);
    }
  };
  const handlePopupSubmit = () => {
    paymentFunction(dataC, throughPageC, statusC);
  };
  const handleClose = () => {
    window.location.reload();
  };

  const handleCart = async (product) => {
    console.log(product);
    if (auth.currentUser && pBooks !== false) {
      localStorage.setItem("cart_flag", true);
      const cartEvent = new Event("cartEvent");
      window.dispatchEvent(cartEvent);
      await setDoc(
        doc(db, "users", auth.currentUser.uid, "cart", product.book_id),
        product,
        {
          merge: true,
        },
      );
      
    } else {
      if (auth.currentUser === false) {
        localStorage.setItem("login_popup", true);
        const storageEvent = new Event("storage");
        window.dispatchEvent(storageEvent);
        setloading(false);
      } else if (!auth.currentUser) {
        localStorage.setItem("register_popup", true);
        const storageEvent = new Event("register");
        window.dispatchEvent(storageEvent);
        setloading(false);
      }
    }
  };
  return (
    <>
      {" "}
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {prePurchasePopup ? (
            <PurchasePopup onClose={handleClose} onSubmit={handlePopupSubmit} />
          ) : null}

          <div className="  hit   w-full flex justify-center gap-2 px-6 ">
            <>
              <div style={{ cursor: "pointer" }} className="flex   ">
                <div
                  onClick={() => {
                    paymentFunction(
                      hit,
                      true,
                      pBooks && pBooks.my_books.includes(hit.book_id)
                    );
                  }}
                  className="w-24 h-32 mr-6 relative overflow-hidden rounded-lg flex-shrink-0"
                >
                  <img
                    onLoad={() => setimageFlag(false)}
                    src={imageFlag ? placeHolder : hit.book_img}
                    alt="Book Cover"
                    className="w-full h-full rounded-lg"
                  />
                  {!pBooks || !pBooks.my_books.includes(hit.book_id) ? (
                    <div className="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-rounded/24/lock--v1.png"
                        alt="Lock Icon"
                      />
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col  mt-8">
                  <h2 className="text-slate-700 text-sm mb-2">
                    {hit.book_name.toUpperCase()}
                  </h2>
                  <p className="text-sm text-slate-900 mb-0">{hit.sub_title}</p>
                  <div className="py-2">
                    {pBooks && pBooks.my_books.includes(hit.book_id) ? (
                      <div>
                        {/* <button
                    className="flex rounded-lg bg-black px-4 py-1.5 text-white duration-100 hover:bg-black"
                    onClick={() => {
                      paymentFunction(hit, true, true);
                    }}
                  >
                    Read Now
                  </button> */}
                      </div>
                    ) : (
                      <>
                        {pBooks ? (
                          <p className="text-lg text-black mb-0">
                            {pBooks.country === "India"
                              ? "₹" + hit.offer_price
                              : "$" + hit.dollar_offer_price}
                            &nbsp;&nbsp;
                            <span className="line-through text-red-600">
                              {pBooks.country === "India"
                                ? "₹" + hit.price
                                : "$" + hit.dollar_offer_price}
                            </span>
                            &nbsp;
                            {(
                              100 -
                              (parseInt(hit.offer_price) /
                                parseInt(hit.price)) *
                                100
                            ).toFixed(0) > 0 ? (
                              <span className="text-green-600 font-sm">
                                (
                                {(
                                  100 -
                                  (parseInt(hit.offer_price) /
                                    parseInt(hit.price)) *
                                    100
                                ).toFixed(0)}
                                % OFF)
                              </span>
                            ) : null}
                          </p>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
};

const Autocomplete = ({ searchClient, refine }) => {
  const containerRef = useRef(null);
  const [selectedTerm, setSelectedTerm] = useState("");

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      placeholder: "Search for Books",
      color: "blue",
      insights: true,
      getSources({ query }) {
        return [
          {
            sourceId: "products",
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "book_pricing",
                    query,
                    params: {
                      hitsPerPage: 5,
                    },
                  },
                ],
              });
            },
            templates: {
              item({ item, html }) {
                return html`
                  <div
                    class="py-2 px-2 flex items-center custom-autocomplete-item"
                  >
                    <img
                      class="w-14 h-14 rounded mr-4"
                      src="${item.book_img}"
                      alt="${item.book_name}"
                    />
                    <div class="gap-4">
                      <span class="font-medium">${item.book_name}</span>
                      <span>${item.level_name}</span>
                    </div>
                  </div>
                `;
              },
            },
            onSelect({ item }) {
              setSelectedTerm(item.book_name);
              refine(item.book_name);
            },
          },
        ];
      },
      initialState: {
        query: selectedTerm,
      },
    });

    return () => {
      search.destroy();
    };
  }, [searchClient, refine, selectedTerm]);

  useEffect(() => {
    if (containerRef.current && selectedTerm) {
      const input = containerRef.current.querySelector("input");
      if (input) {
        input.value = selectedTerm;
      }
    }
  }, [selectedTerm]);

  return <div ref={containerRef} />;
};

function Search() {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="book_pricing">
        <AutocompleteWithRefine />
        <Hits className='px-4'  hitComponent={Hit} />
        <Pagination className="justify-center flex py-6" />
      </InstantSearch>
    </div>
  );
}

const AutocompleteWithRefine = connectSearchBox(({ refine }) => (
  <div className="autocomplete-container">
    <Autocomplete
      placeHolder="Search here"
      className="autocomplete-input"
      searchClient={searchClient}
      refine={refine}
    />
  </div>
));

export default Search;
