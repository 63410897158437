import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { useEffect } from "react";
import { FiBook, FiCalendar, FiArrowRight } from "react-icons/fi";
import { db } from "../Config/Config";
import Spinner from "../assets/Loading";
import { useNavigate } from "react-router-dom";

export default function Studyplans() {
  // ============================== Fetching Study Plans ==============================//
  const [plansList, setPlansList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const fetchPlans = async () => {
    setLoading(true);
    const ref = collection(db, "study_plan");
    const q = query(ref, orderBy("order", "asc"),where("status", "==", true));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({...doc.data(),id:doc.id});
    });
    setPlansList(data);
    setLoading(false);
  }
  useEffect(() => {
    fetchPlans();
  }, []);

  

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="bg-gradient-to-br from-gray-50 via-white to-gray-100 min-h-screen px-6 py-12">
          <div className="text-center mb-12">
            <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#281388] to-blue-600 mb-4">
              Explore Our Study Plans
            </h1>
            <p className="text-gray-600 text-lg">
              Choose a plan that suits your learning journey and goals.
            </p>
          </div>
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
            {plansList.map((plan, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl shadow-md hover:shadow-xl transition-shadow duration-300 overflow-hidden border border-gray-200"
              >
                <div className="relative bg-gradient-to-r from-[#281388] to-blue-600 h-1"></div>
                <div className="p-8">
                  <div className="flex items-center mb-4">
                    <FiBook className="text-blue-900 h-6 w-6 mr-3" />
                    <h2 className="text-base font-semibold text-gray-800">
                      {plan.plan_name}
                    </h2>
                  </div>
                  <p className="flex items-center text-sm text-gray-500 mb-4">
                    <FiCalendar className="text-gray-500 h-5 w-5 mr-4" />
                    <span className="font-medium text-gray-700">
                      Duration:
                    </span>{" "}
                    &nbsp;{plan.day} &nbsp;Days
                  </p>
                  <p style={{ height: "80px", overflow: "hidden"}} className="text-gray-600 mb-6">{plan.description}</p>
                  <div
                    onClick={() => {
                      navigate(`/studyplan?p=${plan.id}`);
                    }}
                    className="flex items-center justify-center w-full text-center px-4 py-2 text-sm font-medium text-blue-800 border border-blue-600 rounded-full hover:bg-blue-50 hover:text-blue-600 transition-all duration-300"
                  >
                    View Study Plan
                    <FiArrowRight className="h-5 w-5 ml-2" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
