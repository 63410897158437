import React from 'react'
import img from "./assets/portrait-young-teen-student-attending-school (1).jpg";
export default function Section() {
  return (
      <section class="relative py-10 overflow-hidden bg-black sm:py-16 lg:py-16 xl:py-16">
        <div class="absolute inset-0">
          <img
            class="object-cover w-full h-full md:object-left md:scale-150 md:origin-top-left"
            src={img}
            alt=""
          />
        </div>

        <div class="absolute inset-0 hidden bg-gradient-to-r md:block from-black to-transparent"></div>

        <div class="absolute inset-0 block bg-black/60 md:hidden"></div>

        <div class="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-11xl">
          <div class="text-center md:w-2/3 lg:w-1/2 xl:w-1/3 md:text-left">
            <h2 class="text-3xl font-bold leading-tight text-white sm:text-4xl max-w-6xl lg:text-5xl">
              {" "}
              Empowering Futures
            </h2>
            <p class="mt-4 text-base text-gray-200">
              From Anywhere to ACCA: Seamless Education, Limitless
              Opportunities.
            </p>
          </div>
        </div>
      </section>
  )
}
