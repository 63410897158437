import React from 'react';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoImage from './acca.png'; // Import the image

const Invoice = () => {
  const location = useLocation();

const downloadPDF = () => {
  const pdf = new jsPDF();

  // Define page size and margins
  const pageWidth = pdf.internal.pageSize.getWidth();
  const marginLeft = 15;

  // Academy information and invoice details
  const logoWidth = 30;
  const logoHeight = 30;
  const logoX = pageWidth - 40; // Position logo to the right
  const logoY = 10;

  const imgData = logoImage;
  pdf.addImage(imgData, 'JPEG', logoX, logoY, logoWidth, logoHeight);

  pdf.setFontSize(16);
  pdf.text('The Goodwill Academy', marginLeft, 20);

  pdf.setFontSize(12);
  pdf.text('Professional Coaching Online', marginLeft, 30);
  pdf.text(`Invoice No: ${location.state.data.invoice}`, marginLeft, 40);

  // Customer details
  pdf.text(`Customer Name: ${location.state.data.name}`, marginLeft, 60);
  pdf.text(`Invoice Date: ${new Date(location.state.data.subscribed_date.seconds * 1000).toDateString()}`, marginLeft, 70);

  // Table of items (Books or Package)
  const itemRows = location.state.data.book_details
    ? location.state.data.book_details.map(item => [
        item.book_name,
        '1', // Quantity
        item.offer_price,
        item.offer_price // Total
      ])
    : [[
        location.state.data.package.toUpperCase(),
        '1', // Quantity
        location.state.data.purchased_price,
        location.state.data.purchased_price // Total
      ]];

  // Calculate Subtotal
  const currencySymbol = location.state.data.currency === "INR" ? "₹" : "$";
  const purchasedPrice = location.state.data.purchased_price;

  // Adding subtotal row
  itemRows.push([
    '', // Empty for Book Name
    '', // Empty for Quantity
    'Subtotal', 
    `${purchasedPrice}` // Total price for subtotal
  ]);

  // Adding total row
  itemRows.push([
    '', // Empty for Book Name
    '', // Empty for Quantity
    'Total', 
    `${purchasedPrice}` // Total price for total
  ]);

  // Table columns
  const columns = [
    { header: 'Book Name', dataKey: 'bookName' },
    { header: 'Quantity', dataKey: 'quantity' },
    { header: 'Price', dataKey: 'price' },
    { header: 'Total', dataKey: 'total' }
  ];

  // Add table to PDF
  pdf.autoTable({
    head: [columns.map(col => col.header)],
    body: itemRows,
    startY: 80, // Start table after customer details
    theme: 'striped',
    headStyles: { fillColor: [3, 169, 244] }, // Blue color header
    margin: { left: marginLeft },
    styles: {
      fontSize: 10, // Small font size for table
      cellPadding: 3,
      overflow: 'linebreak',
      valign: 'middle',
    },
    columnStyles: {
      2: { halign: 'right' }, // Align the "Subtotal" and "Total" text to the right
      3: { halign: 'right' }, // Align the prices to the right
    },
  });

  // Footer disclaimer
  pdf.setFontSize(8);
  pdf.setFont('helvetica', 'normal');
  pdf.text(
    'This invoice is system-generated and does not require a signature or stamp.',
    marginLeft,
    pdf.internal.pageSize.getHeight() - 60
  );

  // Save PDF
  const date = new Date();
  pdf.save(`${date.toLocaleDateString()}-GWA-Invoice.pdf`);
};






  return (
    <div className="flex justify-center items-center mt-12">
      <div className="bg-blue-200 font-sans leading-normal w-1/2 tracking-normal">
        <div className="bg-blue-300">
          <div className="container mx-auto px-4">
            <div className="flex justify-between items-center py-4">
              <div>
                <h1 className="text-xl font-bold">The Goodwill Academy</h1>
                <p className="text-sm text-gray-600">Professional Coaching Online</p>
              </div>
              <div>
                <h1 className="text-xl font-bold">Invoice No</h1>
                <p className="text-gray-600">{location.state.data.invoice}</p>
              </div>
            </div>
          </div>
        </div>

        <InvoiceSection location={location} />

        <div className="p-4">
          <div className="flex justify-end">
            <button className=" text-black font-bold py-2 px-4 rounded" onClick={downloadPDF}>
              Download PDF
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const InvoiceSection = ({ location }) => (
  <div className="container mx-auto mt-8">
    <div className="bg-white shadow-md rounded-lg">
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold">Invoice Details</h2>
        <div className="flex justify-between mt-2">
          <div>
            <p className="text-sm text-gray-600">Invoice Date</p>
            <p className="font-semibold">
              {new Date(
                location.state.data.subscribed_date.seconds * 1000
              ).toDateString()}
            </p>
          </div>

          <div>
            <p className="text-sm text-gray-600">Total Amount</p>
            <p className="font-semibold text-green-600">
              {location.state.data.currency === "INR" ? "₹" : "$"}
              {location.state.data.purchased_price}
            </p>
          </div>
        </div>
      </div>
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold">Customer Details</h2>
        <div className="mt-2">
          <p className="text-sm text-gray-600">Customer Name</p>
          <p className="font-semibold">{location.state.data.name}</p>
        </div>
      </div>
      <div className="p-4">
        <h2 className="text-lg font-semibold">Items</h2>
        <div className="mt-2">
          <table id="invoice-table" className="w-full">
            <thead>
              <tr className="border-b">
                <th className="pb-2 text-left text-sm font-semibold text-gray-600">
                  Book Name
                </th>
                <th className="pb-2 text-right text-sm font-semibold text-gray-600">
                  Quantity
                </th>
                <th className="pb-2 text-right text-sm font-semibold text-gray-600">
                  Price
                </th>
                <th className="pb-2 text-right text-sm font-semibold text-gray-600">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {location.state.data.book_details &&
                location.state.data.book_details.map((item, index) => (
                  <tr className="border-b" key={index}>
                    <td className="py-2 text-right">{item.book_name}</td>
                    <td className="py-2 text-right">1</td>
                    <td className="py-2 text-right">{item.offer_price}</td>
                    <td className="py-2 text-right">{item.offer_price}</td>
                  </tr>
                ))}
              {!location.state.data.book_details && (
                <tr className="border-b" key={1}>
                  <td className="py-2 text-right">
                    {location.state.data.package.toUpperCase()}
                  </td>
                  <td className="py-2 text-right">1</td>
                  <td className="py-2 text-right">
                    {location.state.data.purchased_price}
                  </td>
                  <td className="py-2 text-right">
                    {location.state.data.purchased_price}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-end">
          <div className="text-right">
            <p className="text-sm text-gray-600">Subtotal</p>
            <p className="font-semibold">
              {location.state.data.currency === "INR" ? "₹" : "$"}
              {location.state.data.purchased_price}
            </p>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <div className="text-right">
            <p className="text-sm text-gray-600">Total</p>
            <p className="font-semibold">
              {location.state.data.currency === "INR" ? "₹" : "$"}
              {location.state.data.purchased_price}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Invoice;
