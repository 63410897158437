import  { useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function User() {
  const auth = getAuth();
  const [user,setUser] = useState();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const uid = user.uid;
        setUser(uid);
      } else {
        // User is signed out
        
      }
    });

    // Cleanup the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [auth]);
  return user?user:false;
}
