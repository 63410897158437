import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutPage from './CheckoutPage';


const stripePromise = loadStripe(
  "pk_test_51NYwSUSIGy7YvKwb3iGyTouCsJP9Ynh7F7zUlV1O7SdwBy5juTbfil4daNCoR9uQExBdHT067bXHClzDdHkjQwSD004JTWljgN"
); // Replace with your publishable key
function Payment() {
  return (
    <div className="App">
    
      <h1>Stripe Payment Demo</h1>
      <Elements stripe={stripePromise}>
        <CheckoutPage />
      </Elements>
    </div>
  );
}

export default Payment;
