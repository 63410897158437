import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../Config/Config";
import hamburgerMenu from "../assets/hum.png";
import close from "../assets/close.png";
import Drawer from "@mui/material/Drawer";
import './Nav.css'
import logo1 from './logo192.png'
import male from "../Profile/assets/male.png";
import female from "../Profile/assets/Female.png";


const Navbar = () => {
  const [uid, setuid] = useState(null);
  const [loading, setloading] = useState(true);
  const [email, setemail] = useState(null);



  const auth = getAuth();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setuid(uid);
        setemail(user.email ? user.email : null);
        fetchUser(user.uid);
      } else {
        setuid(null);
        setloading(false);
      }
    });
  }, [auth]);

  //=========================== Variable Decleration ===============================//
  const [cartBagde, setcartBadge] = useState(0);
  useEffect(() => {
    if (auth.currentUser) {
      const user = auth.currentUser.uid;
      const cartRef = collection(db, "users", user, "cart");
      const unsubscribe = onSnapshot(cartRef, (snapshot) => {
        setcartBadge(snapshot.docs.length);
      });

      return () => unsubscribe();
    }
  }, [auth.currentUser]);

  //=========================== Variable Decleration ===============================//
  const [toggle, setToggle] = useState(false);
  const [userData, setuserData] = useState("");
  const [avatar, setAvatar] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  //=========================== Pointer style ===============================//
  const divStyle = {
    cursor: "pointer",
  };

  const handleClick = () => {
    setToggle(!toggle);
  };

  //=========================== Fetch User ===============================//
  const [user, setuser] = useState(false);
  const fetchUser = async (uid) => {
    const docRef = doc(db, "users", uid);
         onSnapshot(
           docRef,
           (docSnap) => {
             if (docSnap.exists()) {
             setuserData(docSnap.data());
             setuser(true);
             setloading(false);
             if (docSnap.data().profileImg !== undefined) {
               setAvatar(docSnap.data().profileImg);
             } else {
               if (docSnap.data().gender === "female") {
                 setAvatar(female);
               } else if (docSnap.data().gender === "male") {
                 setAvatar(male);
               }
             }
             } else {
            setuser(false);
            setloading(false);
             }
             setloading(false);
           },
           (error) => {
             console.error("Error fetching document:", error);
           }
         );
  };

  //const [imgFlag, setImgFlag] = useState(true);
  const imageFetch = () => {
    if (avatar) {
      //setImgFlag(false);
    }
  };
  const navbarLinks = [
    { label: "Home", link: "/", selected: true },
    { label: "ACCA ", dropdown: true},
    { label: "Exams", link: "/exams" },
    { label: "Exam Results", link: uid ? "/Results" : "/login" },
    // { label: "Help Desk", link: "/Contact-info" },
    { label: "Vacancies", link: "/vacancies" },
     { label: "Career", link: "/career" },
    { label: "Scholarship", link: "/pass" },
    { label: "Study Plan", link: "/studyplans" },
    
  ];

  const navbarLinks2 = [
    { label: "Home", link: "/", selected: true },
    { label: "ACCA Levels", link: "/acca-levels" },
    { label: "Books", link: "/myshelf" },

    { label: "Exams", link: "/exams" },
    { label: "Help Desk", link: "/Contact-info" },
    { label: "Vacancies", link: "/vacancies" },
    // { label: "Scholarship", link: "/pass" },
     { label: "Study Plan", link: "/studyplans" },
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  function handleProfileClick(path) {
    if (user) {
      navigate(path);
      setIsDrawerOpen(false);
    } else {
      setIsDrawerOpen(false);
    }
  }

  const [showDropdown, setShowDropdown] = useState(null);

  // ============================================================ Handle SignIn Button ============================================================//
  const handleSigin = () => {
    localStorage.setItem("login_popup", true);
    const storageEvent = new Event("storage");
    window.dispatchEvent(storageEvent);
  };
  // ============================================================ Handle Register Button ============================================================//

  const handleResigister = () => {
    console.log("yes");
    localStorage.setItem("register_popup", true);
    const storageEvent = new Event("register");
    window.dispatchEvent(storageEvent);
  };
  // ============================================================ Search data ============================================================//


  const handleInputClick = () => {
    // Show suggestions when input box is clicked
    // setShowSuggestions(true);
    navigate("/books-search")
  };

  const handleCart = () => {
    localStorage.setItem("cart_flag", true);
    const cartEvent = new Event("cartEvent");
    window.dispatchEvent(cartEvent);
  };

  const handleScollership = () => {
    localStorage.setItem("scolr_flag", true);
    const cartEvent = new Event("scollerEvent");
    window.dispatchEvent(cartEvent);
  };
 
  return (
    <div className="w-full h-[80px] sticky top-0 z-30 bg-cover bg-white border-b">
      <div className="md:max-w-[1480px] max-w-[600px]sticky top-0 z-30 m-auto w-full h-full flex justify-between items-center md:px-0 px-4">
         <div className="flex items-center space-x-4">
  <img alt="The Goodwill Academy logo" src={logo1} className="w-14 h-14 lg:ml-2 " />
  <div className="leading-tight">
    <h2 className="text-md font-bold text-gray-900">The Goodwill Academy</h2>
    <p className="text-sm font-medium text-gray-700">Professional Coaching Online</p>
  </div>
</div>


        {uid && userData ? (
          <div className="hidden gap-y-1 md:flex items-center">
            <ul className="flex cursor-auto font-semibold gap-4">
              {navbarLinks.map((link, index) => (
                <>
                  <li
                  style={{cursor:"pointer"}}
                    key={index}
                    className={`relative group ${
                      location.pathname === link.link ? "text-blue-500" : ""
                    } hover:text-blue-500`}
                    onMouseEnter={() => setShowDropdown(index)}
                    onMouseLeave={() => setShowDropdown(null)}
                  >
                    {link.link === "/pass" ? (
                      <>
                        {userData ? (
                          <button
                            onClick={handleScollership}
                            className={`transition duration-300 ease-in-out ${
                              location.pathname === link.link
                                ? "text-blue-500"
                                : ""
                            }`}
                          >
                            {link.label}
                          </button>
                        ) : null}
                      </>
                    ) : (
                      <a
                        href={link.link}
                        className={`transition duration-300 ease-in-out ${
                          location.pathname === link.link ? "text-blue-500" : ""
                        }`}
                      >
                        {link.label}
                      </a>
                    )}

                    {/* Mega Menu Box */}
                    {link.dropdown && showDropdown === index && (
                      <div className="absolute top-full left-0 bg-white border shadow-lg rounded-lg p-4 ">
                        <div className="grid grid-cols-4 gap-12">
                          <div className="col-span-4">
                            <ul className="">
                              <a
                                href="/acca-levels"
                                className="text-gray-800 hover:text-blue-600 transition duration-300 ease-in-out inline-block mr-8"
                              >
                                ACCA LEVELS
                              </a>

                              <a
                                href="/myshelf"
                                className="text-gray-800 hover:text-blue-500 transition duration-300 ease-in-out inline-block mt-4"
                              >
                                ACCA BOOKS
                              </a>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </>
              ))}
            </ul>
          </div>
        ) : (
          <div className="hidden gap-y-1 md:flex items-center">
            <ul className="flex cursor-auto font-semibold gap-8">
              {navbarLinks2.map((link, index) => (
                <>
                  {link.link === "/pass" ? (
                    <li
                      key={index}
                      className={`relative group ${
                        location.pathname === link.link ? "text-blue-500" : ""
                      }`}
                    >
                      <>
                        {userData ? (
                          <button
                            onClick={handleScollership}
                            className={`transition duration-300 ease-in-out hover:text-blue-500 group-hover:after:absolute group-hover:after:content-before group-hover:after:h-0.5 group-hover:after:w-full group-hover:after:bg-blue-500 group-hover:after:bottom-0 group-hover:after:left-0 group-hover:after:invisible ${
                              location.pathname === link.link
                                ? "text-blue-500"
                                : ""
                            }`}
                          >
                            {link.label}
                          </button>
                        ) : null}
                      </>
                    </li>
                  ) : (
                    <li
                      key={index}
                      className={`relative group ${
                        location.pathname === link.link ? "text-blue-500" : ""
                      }`}
                    >
                      <a
                        href={link.link}
                        className={`transition duration-300 ease-in-out hover:text-blue-500 group-hover:after:absolute group-hover:after:content-before group-hover:after:h-0.5 group-hover:after:w-full group-hover:after:bg-blue-500 group-hover:after:bottom-0 group-hover:after:left-0 group-hover:after:invisible ${
                          location.pathname === link.link ? "text-blue-500" : ""
                        }`}
                      >
                        {link.label}
                      </a>
                    </li>
                  )}
                </>
              ))}
            </ul>
          </div>
        )}

        <div className="hidden md:flex items-center">
          <>
            {location.pathname !== "/books-search" ? (
              <div className="flex flex-col items-center">
                <div className="relative w-54">
                  <input
                    type="text"
                    onClick={handleInputClick}
                    placeholder="Search..."
                    readOnly
                    className="rounded-3xl cursor-pointer border border-gray-400 text-black py-1 px-3 pr-6 w-full focus:outline-none" // Adjusted padding for icon space
                  />
                  <span className="absolute cursor-pointer right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 cursor-pointer"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.9 14.32a8 8 0 111.414-1.414l4.386 4.387a1 1 0 01-1.414 1.414l-4.386-4.387zM8 14A6 6 0 108 2a6 6 0 000 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            ) : null}
            &nbsp;&nbsp;&nbsp;
            {uid && user && cartBagde > 0 ? (
              <div onClick={handleCart} class="relative">
                <img
                  src="https://img.icons8.com/ios-glyphs/60/shopping-cart--v1.png"
                  alt="Cart Icon"
                  class="h-8 w-8 cursor-pointer text-gray-600"
                />

                <div class="absolute cursor-pointer top-0 right-0 bg-blue-500 rounded-full h-4 w-4 flex justify-center items-center text-white text-xs">
                  <span className="cursor-pointer">{cartBagde}</span>
                </div>
              </div>
            ) : null}
          </>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {uid && userData ? (
            <div
              style={divStyle}
              className="flex items-center space-x-4 mr-6 "
              onClick={() => {
                handleDrawerOpen();
              }}
            >
              {userData.gender === "male" ? (
                <img
                  onLoad={() => imageFetch()}
                  className="w-12 h-12 rounded-full"
                  width="48"
                  height="48"
                  src={avatar}
                  alt="circled-user-male-skin-type-3--v1"
                />
              ) : (
                <>
                  {userData && userData.gender === "female" ? (
                    <img
                      onLoad={() => imageFetch()}
                      className="w-10 h-10 rounded-full"
                      width="48"
                      height="48"
                      src={avatar}
                      alt="circled-user-male-skin-type-3--v1"
                    />
                  ) : null}
                </>
              )}
              <h2>
                {userData.name} <br />
                <h7 className="text-blue-600 text-sm"> Records</h7>
              </h2>
            </div>
          ) : (
            <>
              <div className="font-medium dark:text-white">
                <div className="text-black">
                  {!loading ? (
                    <button
                      className="px-4 py-2 text-white rounded-full bg-blue-500 hover:bg-blue-800 focus:outline-none focus:ring focus:border-blue-300 transition-all duration-300"
                      onClick={() => {
                        if (uid) {
                          // setregisterFlag(true);
                          handleResigister();
                        } else {
                          // setLoginFlag(true);
                          handleSigin();
                        }
                      }}
                    >
                      {!uid ? <>Sign In</> : <>Register Now</>}
                    </button>
                  ) : null}
                </div>
              </div>
              &nbsp;
              {uid ? (
                <div
                  style={divStyle}
                  className="flex items-center space-x-4 mr-6 "
                  onClick={() => {
                    handleDrawerOpen();
                  }}
                >
                  <img
                    onLoad={() => imageFetch()}
                    className="w-10 h-10 rounded-full"
                    width="48"
                    height="48"
                    src="https://img.icons8.com/color/48/circled-user-male-skin-type-3--v1.png"
                    alt="circled-user-male-skin-type-3--v1"
                    //alt="circled"
                  />
                </div>
              ) : null}
            </>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;
        </div>

        <div className="md:hidden" onClick={handleClick}>
          <img alt="a" src={toggle ? close : hamburgerMenu} />
        </div>
      </div>

      <div
        className={
          toggle
            ? "absolute z-10 p-4 bg-white w-full px-8 md:hidden border-b"
            : "hidden"
        }
      >
        <ul>
          {navbarLinks.map((link, index) => (
            <>
              {link.link === "/pass" ? (
                <button onClick={handleScollership} key={index}>
                  <li className="p-4 hover:bg-gray-100">{link.label}</li>
                </button>
              ) : (
                <a href={link.link} key={index}>
                  <li className="p-4 hover:bg-gray-100">{link.label}</li>
                </a>
              )}
            </>
          ))}

          <br />

          {uid && userData ? (
            <div
              style={divStyle}
              className="flex items-center space-x-4 mr-6"
              onClick={() => {
                handleDrawerOpen();
              }}
            >
              {userData.gender === "male" ? (
                <img
                  onLoad={() => imageFetch()}
                  className="w-10 h-10 rounded-full"
                  width="48"
                  height="48"
                  src={avatar}
                  alt="circled-user-male-skin-type-3--v1"
                />
              ) : (
                <>
                  {userData && userData.gender === "female" ? (
                    <img
                      onLoad={() => imageFetch()}
                      className="w-10 h-10 rounded-full"
                      width="48"
                      height="48"
                      src={avatar}
                      alt="circled-user-male-skin-type-3--v1"
                    />
                  ) : null}
                </>
              )}
            </div>
          ) : (
            <div className="font-medium dark:text-white">
              <div className="text-black">
                <button
                  className="px-4 py-2 text-white rounded-full bg-blue-500 hover:bg-blue-800 focus:outline-none focus:ring focus:border-blue-300 transition-all duration-300"
                  onClick={() => {
                    if (uid) {
                      // setregisterFlag(true);
                      handleResigister();
                    } else {
                      // setLoginFlag(true);
                      handleSigin();
                    }
                  }}
                >
                  {!uid ? <>Sign In</> : <>Register Now</>}
                </button>
              </div>
            </div>
          )}
        </ul>
      </div>

      <Drawer
        className=""
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      >
        <div className="p-4 text-black py-2">
          <div class="flex justify-between">
            <h2 class=" text-black mt-3 font-bold rounded-lg">Options</h2>

            <h2
              style={divStyle}
              class=" text-blue-500 p-4 rounded-lg flex items-center"
              onClick={() => {
                user ? handleProfileClick("editprofile") : handleResigister();
                setIsDrawerOpen(false);
              }}
            >
              {user ? "Edit profile" : "Register Now"}&nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                />
              </svg>
            </h2>
          </div>

          <div
            style={divStyle}
            onClick={() => handleProfileClick("/profile")}
            className="w-84 mx-auto rounded-lg bg-gradient-to-r from-blue-500 to-indigo-700 p-3 shadow-lg mt-3"
          >
            <div className="flex items-center mb-6">
              <img
                className="w-18 h-18 rounded-full mr-4 mt-6"
                src="https://img.icons8.com/fluency/48/user-female-circle.png"
                alt="Profile"
              />
              <div>
                <h3 className="text-lg text-sm mt-6 text-white">
                  {userData ? userData.name : "Anonymous"}
                </h3>

                <p className="text-gray-100 text-sm mt-1">
                  {email ? email : ""}
                </p>
              </div>
            </div>
          </div>

          {user ? (
            <>
              <hr className="my-4 border-t border-gray-300 w-full" />
              <ul
                onClick={() => handleProfileClick("/Results")}
                className="list-none"
              >
                <li className="mb-2 text-black cursor-pointer">Exam Results</li>
              </ul>
              <hr className="my-4 border-t border-gray-300 w-full" />
            </>
          ) : null}

          {user ? (
            <>
              <ul
                onClick={() => handleProfileClick("/Previous")}
                className="list-none"
              >
                <li className="mb-2 text-black cursor-pointer">Exam History</li>
              </ul>
            </>
          ) : null}
          {user ? (
            <ul
              onClick={() => handleProfileClick("/myshelf")}
              className="list-none"
            >
              <hr className="my-4 border-t border-gray-300 w-full" />

              <li className="mb-2 text-black cursor-pointer">
                Subscribed Books
              </li>
            </ul>
          ) : null}

          <hr className="my-4 border-t border-gray-300 w-full" />
          <ul
            onClick={() => {
              setIsDrawerOpen(false);
              navigate("/aboutus-info")}}
            className="list-none"
          >
            <li className="mb-2 text-black cursor-pointer">About Us</li>
          </ul>
          <hr className="my-4 border-t border-gray-300 w-full" />
          <ul
            onClick={() => {
              setIsDrawerOpen(false);
              navigate("/Contact-info")}}
            className="list-none"
          >
            <li className="mb-2 text-black cursor-pointer">Contact Us</li>
          </ul>

          <hr className="my-4 border-t border-gray-300 w-full" />
          <ul onClick={() => {
                          setIsDrawerOpen(false);

            navigate("/faq")}} className="list-none">
            <li className="mb-2 text-black cursor-pointer">FAQ</li>
          </ul>

          {/* <ChapterList/> */}
          <div className="mt-8">
            <button
              onClick={() => {
                const auth = getAuth();
                signOut(auth)
                  .then(() => {
                    handleDrawerClose();
                    navigate("/");
                    setuid(null);
                  })
                  .catch((error) => {
                    // An error happened.
                    alert("Currently unable to process try again...");
                  });
              }}
              className="bg-blue-500 py-3 px-6 text-white rounded-lg transition-all duration-300 hover:bg-blue-500 focus:outline-none focus:ring focus:border-blue-300"
            >
              Log Out
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Navbar;
