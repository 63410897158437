import React from "react";

export default function Terms() {
  return (
    <div>
      <div class="max-w-6xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
        <div class="max-w-6xl">
          <div class="space-y-8 md:space-y-12">
            <div class="space-y-3">
              <h2 class="text-2xl text-center py-4 font-bold md:text-3xl dark:text-black">
                Cancellation & Refund Policy URL
              </h2>

              <p class="text-lg text-black dark:text-black">
                Welcome to The Goodwill Accademy for ACCA courses. By
                accessing and using our services, you agree to our Terms of
                Service. These terms cover user registration, course content
                usage, payment, and user conduct. We provide ACCA courses and
                materials for educational purposes, and while we strive for
                accuracy, we can't guarantee it. Please adhere to ACCA's terms
                when using their content. If you need a refund, our Payment
                Refund Policy explains the process. Misuse of our platform or
                violation of our terms may result in termination of your access.
                Your privacy is important, and our Privacy Policy outlines how
                we handle your data. We may update these terms, so please review
                them periodically. If you have questions, contact us at infothegoodwillaccademy@gmail.com.
                 Thank you for using our platform, and we
                hope you find our ACCA courses valuable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
