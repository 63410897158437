
import {  addDoc, collection,getDocs, orderBy, query, where } from "firebase/firestore";
import { getStorage, uploadBytes,ref, getDownloadURL } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { db } from "../Config/Config";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
export default function Vac(user) {
  // ========================================= Variable Decleration =========================================//

  const [vacancyList, setvacancyList] = useState([]);
  const [resume, setresume] = useState();
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [mobile, setmobile] = useState();
  const [vacancy, setVacancy] = useState('');
  const [open, setOpen] = React.useState(false);
  const [toastType, settoastType] = useState("success");
  const [toastContent, settoastContent] = useState("Logged In");
  // =========================================Fetching vacancy data=========================================//
  const fetchLevel = async () => {
    const q = query(
      collection(db, "vacancy"),
      orderBy("order", "asc"),
      where("status", "==", true)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setvacancyList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
          },
        ];
      });
    });
  };
  useState(() => {
    fetchLevel();
  });
  // ============================================== Popup Open ==============================================//

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setname("");
    setemail("");
    setmobile("");
    setresume();
    setIsModalOpen(false);
  };
  // ============================================ Uploading Resume ============================================//
  const uploadResume = async (e) => {
    e.preventDefault();
    const date = new Date();
    const storage = getStorage();
    if(resume){
    const storageRef = ref(storage, "resume/" + date);
    uploadBytes(storageRef, resume).then((snapshot) => {
      getDownloadURL(ref(storageRef)).then((url) => {
        uploadData(url);
      });
    });
  }else{
        handleClick(
          "error",
          "Please upload your resume"
        );

  }
  };
  // ============================================= Uploading Resume =============================================//
  const uploadData = async (url) => {
    const date = new Date();
    await addDoc(collection(db, "vacancy",vacancy.id,"vacancy_request"), {
      date: date,
      email: email,
      name: name,
      mobile: mobile,
      url: url,
      status: "pending",
    },{merge:true}).then(()=>{
    handleClick("success", "Your application has been submitted successfully.");
    //userRequestValidation();
    closeModal();
    });
  };
  // =============================================  Toast =============================================//


  const handleClick = (type, content) => {
    setOpen(true);
    settoastType(type);
    settoastContent(content);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

useEffect(() => {
 setVacancy(vacancyList[0])
}, [vacancyList])


  return (
    <section>
      <Stack spacing={2} sx={{ width: "80%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={toastType}
            sx={{ width: "100%" }}
          >
            {toastContent}
          </Alert>
        </Snackbar>
      </Stack>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-30 flex items-center justify-center overflow-auto ">
          <div className="bg-white p-8 rounded-lg w-96">
            <form onSubmit={uploadResume}>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-600"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  name="name"
                  className="mt-1 p-2 w-full border rounded-md"
                  placeholder="Enter your name"
                  onChange={(e) => setname(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-600"
                >
                  Your contact number
                </label>
                <input
                  type="number"
                  id="name"
                  value={mobile}
                  name="name"
                  className="mt-1 p-2 w-full border rounded-md"
                  placeholder="Enter contact number"
                  onChange={(e) => setmobile(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-600"
                >
                  Your Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  name="email"
                  className="mt-1 p-2 w-full border rounded-md"
                  placeholder="Enter your email"
                  onChange={(e) => setemail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-600"
                  for="selectOption"
                >
                  Select Your Job
                </label>
                <select
                  className="mt-1 p-2 w-full border rounded-md"
                  id="options"
                  required
                  name="selectOption"
                  value={vacancy}
                  onChange={(e) => {
                    setVacancy(e.target.value);
                  }}
                  data-te-select-init
                >
                  {vacancyList.map((doc, index) => {
                    return <option value={doc}>{doc.data.designation}</option>;
                  })}
                </select>
              </div>
              <>
                <div className="mb-4">
                  <label
                    htmlFor="resume"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Your Resume
                  </label>
                  <input
                    type="file"
                    id="resume"
                    name="resume"
                    accept=".pdf, .doc, .docx"
                    className="mt-1 p-2 w-full border rounded-md"
                    onChange={(e) => {
                      setresume(e.target.files[0]);
                    }}
                  />
                </div>
              </>

              <div className="flex justify-end">
                <button
                  onClick={closeModal}
                  className="ml-2 bg-gray-300 text-black px-4 py-2 rounded-md"
                >
                  Close
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  type="submit"
                  className="bg-black text-white px-4 py-2 rounded-md"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <section class="mx-auto my-10 flex max-w-xl flex-col   px-4 py-10   sm:px-10 lg:max-w-screen-lg lg:flex-row">
        <div class="mr-2">
          <h2 class="mb-4 text-3xl font-medium">
            <span class="text-blue-600">You are welcome
to join in the team of The Goodwill
Academy</span>
          </h2>
          <p class="mb-6">
            {" "}
            Together, let's grow in this stimulating professional coaching
            online environment.
          </p>
          <div class="mb-4 space-y-4">
            <div class="flex space-x-2">
              <span class="text-blue-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 7h4v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2h4"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 9v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8 7V3m4 4V3"
                  ></path>
                </svg>
              </span>
              <span class="font-medium">Inclusive and Diverse Workplace</span>
            </div>
            <div class="flex space-x-2">
              <span class="text-blue-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 7h4v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2h4"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 9v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8 7V3m4 4V3"
                  ></path>
                </svg>
              </span>
              <span class="font-medium">Work From Home</span>
            </div>
            <div class="flex space-x-2">
              <span class="text-blue-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 7h4v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2h4"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 9v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8 7V3m4 4V3"
                  ></path>
                </svg>
              </span>
              <span class="font-medium">
                Competitive Compensation and Benefits
              </span>
            </div>
            <div class="flex space-x-2">
              <span class="text-blue-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 7h4v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2h4"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 9v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8 7V3m4 4V3"
                  ></path>
                </svg>
              </span>
              <span class="font-medium">Performance-Based Incentives</span>
            </div>
          </div>
          <div class="flex justify-between items-end mt-8">
            <a
              onClick={() => {
                openModal();
              }}
              href="#_"
              class="relative rounded px-5 py-2.5 overflow-hidden group bg-blue-400 hover:bg-gradient-to-r hover:from-gray-500 hover:to-gray-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-black "
            >
              <span class="relative">Apply Now</span>
              <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
            </a>
          </div>
        </div>
        <div class="h-96">
          <img
            class="h-full w-full object-contain"
            src="https://img.freepik.com/free-vector/tiny-people-searching-business-opportunities_74855-19928.jpg?t=st=1709460120~exp=1709463720~hmac=11d681e3cf5c894d1dbb4e3c0b9b81e22c89ac54631d21f9cfb76152dbc1cec9&w=826"
            alt=""
          />
        </div>
      </section>
    </section>
  );
}
