import React, { useEffect, useState } from 'react'
import LockedBooks from '../Book/LockedBooks'
import Purchased from '../Book/Purchased'
import AOS from "aos";
import "aos/dist/aos.css";
import User from '../Auth/User';
import UserData from '../Config/UserData';
import Spinner from '../assets/Loading';
export default function MyShelf() {
    AOS.init();
      const uid = User();
      const userData = UserData(uid);
      const [users, setusers] = useState();
      const [loading,setloading] = useState(false);
      useEffect(() => {
        if (userData && userData !== false) {
          setusers(userData);
        }
      }, [userData]);

             window.addEventListener("myshelfloading", (event) => {
               if (event.type === "myshelfloading") {
                 setloading(localStorage.getItem("myshelfloading"));
               }
             });
  return (
    <div>
    {loading?(
      <Spinner/>
    ):(
      <>
        <br />
        {users && users.my_books.length ? (
          <div className="max-w-2xl relative">
            <h2 className="text-xl ml-8 py-2 font-bold md:text-3xl md:leading-tight dark:text-black">
              Your Library
            </h2>
          </div>
        ) : null}
        <Purchased />
        {users && users.my_books.length >= 16 ? null : (
          <div class="max-w-2xl ">
            <h2 class="text-xl ml-8 py-2 font-bold md:text-3xl md:leading-tight dark:text-black">
              Explore Our Premium Book Collection
            </h2>
          </div>
        )}
        <LockedBooks />
      </>
      )}
    </div>
  );
}
