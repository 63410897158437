import { getAuth } from "firebase/auth";
import {  doc, getDoc} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Spinner from "../assets/Loading";
import { app, db } from "../Config/Config";
import placeHolder from "./Images/web book tumb.png";
import PurchasePopup from "./PreBuy/PurchasePopup";

export default function Viewboks(user) {
  const location = useLocation();
  // fetch books
    const [bookList, setbookList] = useState([]);
    const [imageFlag, setimageFlag] = useState(true);
    const [loading, setloading] = useState(false);
    const [dataC, setData] = useState();
    const [prePurchasePopup, setprePurchasePopup] = useState(false);



  const fetchBooks = async () => {
 
    for(var i =0;i<location.state.book_id.length;i++){
        const userRef = doc(db, "book_pricing",location.state.book_id[i]);
        await getDoc(userRef).then((doc) => {
          if (doc.exists) {
              setbookList((prev) => {
                return [
                  ...prev,
                  {
                    id: doc.id,
                    data: doc.data(),
                  },
                ];
              });
          } else {
            console.log("Document not found");
          }
        });
    }
  };

  useState(()=>{
      fetchBooks()
  });


    const functions = getFunctions(app, "asia-south1");
    const [userId, setuserId] = useState(null);
    const paymentFunction = (data) => {
      setloading(true);
      const paymentRequest = httpsCallable(functions, "createOrder");
      paymentRequest({
        // amount: 300,
        img: data.book_img_1,
        name: data.package_name,
        package: data.package,
        uid: userId,
        platform: "web",
        books: data.book_id,
        collection: "packages",
        currency: user.country === "India" ? "INR" : "USD",
        amount:
          user.country === "India"
            ? data.offer_price
            : data.dollar_offer_price,
      }).then((result) => {
        const data = result.data;
        if (data) {
            const data = result.data;
            if (data !== 500) {
              const order = result.data;

              const options = {
                key: process.env.REACT_APP_RAZOR_KEY,
                amount: order.amount,
                currency: order.currency,
                name: "The Goodwill Academy",
                description: "Payment for e-book",
                order_id: order.id,
                handler: function (response) {
                  setloading(false);
                },
                prefill: {
                  name: order.notes.user_name,
                  email: getAuth().currentUser.email,
                  contact: order.notes.mobile,
                },
                notes: {
                  address: "Razorpay Corporate Office",
                },
                theme: {
                  color: "#3399cc",
                },
                modal: {
                  ondismiss: function () {
                    setloading(false);
                  },
                },
              };

              const rzp1 = new window.Razorpay(options);
              rzp1.open();
              rzp1.on("payment.failed", function (response) {
                setloading(false);
                alert("Something went wrong! Try again..");
              });
            } else {
              alert("Something went wrong!!");
              setloading(false);
            }
        } else {
          alert("Something went wrong!!");
          setloading(false);
        }
      });
    };
     useEffect(() => {
       const auth = getAuth();
       const user = auth.currentUser;
       setuserId(user.uid);
     }, []);



  const handleClose = () => {
    // window.location.reload();
    setprePurchasePopup(false);
  };

  const handlePopupSubmit = () => {
    paymentFunction(dataC);
  };
  const handleprePurchase = (data) => {
    setData(data);
    setprePurchasePopup(true);
  };
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {prePurchasePopup ? (
            <PurchasePopup
              onClose={handleClose}
              onSubmit={handlePopupSubmit} // Pass the callback to handle return value
            />
          ) : null}
          <section className="col-span-1 lg:col-span-1 bg-gray-50 min-h-screen p-6 border-r">
            <h3 className="text-xl font-medium text-center mb-4 underline">
              Package Details
            </h3>
            <div className="flex flex-col">
              <img
                src={location.state.data.book_img_1}
                alt="PackageImage"
                style={{
                  width: "100%",
                  height: "auto",

                  objectFit: "contain",
                  borderRadius: "5px",
                }}
                className="mb-4"
              />
              <div className="flex flex-col">
                <h2 className="text-xl text-center  font-medium mt-2">
                  {location.state.data.package_name}
                </h2>
                <h6 className="text-xl text-center font-thin mt-2">
                  {location.state.data.package_subtitle}
                </h6>
                <span className="text-green-500 text-center text-lg">
                  {user.country === "India"
                    ? "₹" + location.state.data.offer_price
                    : "$" + location.state.data.dollar_offer_price}
                  &ensp;
                  <span className="text-red-600 text-center text-lg mt-2 line-through">
                    {user.country === "India"
                      ? "₹" + location.state.data.price
                      : "$" + location.state.data.dollar_price}
                  </span>
                </span>
                <br />
                <button
                  onClick={() => handleprePurchase(location.state.data)}
                  className="bg-black hover:bg-gray-700 rounded-xl text-white font-bold py-3 px-4"
                >
                  Buy Package Now
                </button>
              </div>
            </div>
          </section>

          <section className="col-span-1 lg:col-span-3 bg-white">
            <h3 className="text-xl font-medium mt-4 ml-6 underline">
              Books available in a set
            </h3>
            <div className="mx-auto py-6 grid max-w-8xl grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {bookList.length ? (
                <>
                  {bookList.map((doc, index) => (
                    <article
                      key={index}
                      className="rounded-xl bg-white p-3 shadow-lg"
                    >
                      <div className="relative flex items-end overflow-hidden rounded-xl">
                        <img
                          onLoad={() => setimageFlag(false)}
                          src={imageFlag ? placeHolder : doc.data.book_img}
                          alt="BookImage"
                        />
                      </div>
                      <div className="mt-2 p-2">
                        <h2 className="text-black font-thin">
                          {doc.data.book_name}
                        </h2>
                      </div>
                    </article>
                  ))}
                </>
              ) : null}
            </div>
          </section>
        </>
      )}
    </div>
  );
}
