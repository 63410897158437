// PaymentForm.js
import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../Config/Config";
import { getAuth } from "firebase/auth";

const CheckoutPage = () => {
  const stripe = useStripe();

  const handleSubmit = async (event) => {
    event.preventDefault();
    paymentFunction();
  };
  const [userId,setuserId]=useState(null);
useEffect(()=>{
  const auth = getAuth();
  const user = auth.currentUser;
  setuserId(user.uid)
},[])



  const functions = getFunctions(app, "asia-south1");

  const paymentFunction=()=>{
const paymentRequest = httpsCallable(functions, "paymentRequest");
paymentRequest({
  amount: 300,
  img: "https://firebasestorage.googleapis.com/v0/b/myprofessional-coaching.appspot.com/o/acca.png?alt=media&token=f45ea44a-7b5c-4e95-9711-c3ceb0128e2a",
  package:"gold",
  uid:userId,
  platform: "web"
}).then((result) => {
  const data = result.data;
  if (data) {
    window.location.href=data.url
  } else {
    alert("Registration failed");
  }
});
  }

  

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Card Details</label>
        {/* <CardElement /> */}
      </div>
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

export default CheckoutPage;
