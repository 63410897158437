import { addDoc, collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import contact from "./contact.jpg";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { db } from "../Config/Config";
import User from "../Auth/User";
import UserData from "../Config/UserData";
export default function Contact() {
  const uid = User();
  const userData = UserData(uid);

  const [message, setmessage] = useState("");
  const [user, setuser] = useState();
  const [subList,setsubList] = useState([]);
  const [subject,setsubject] = useState();

  useEffect(() => {
    setuser(userData);
  }, [userData]);

  //======================================================== Upload Message ========================================================//

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uid !== false && user !== false) {
      var date = new Date();
      const ref = collection(db, "user_message");
      const data = {
        date: date,
        uid: user.uid,
        name: user.name,
        mobile: user.mobile,
        topic: subject,
        platform: "web",
        message: message,
      };
      await addDoc(ref, data);
      setmessage("");
      handleClick("success", "Message send");
    } else {
      if (uid === false) {
        localStorage.setItem("login_popup", true);
        const storageEvent = new Event("storage");
        window.dispatchEvent(storageEvent);
      } else {
         localStorage.setItem("register_popup", true);
         const storageEvent = new Event("register");
         window.dispatchEvent(storageEvent);
      }
    }
  };
  const [open, setOpen] = React.useState(false);
  const [toastType, settoastType] = useState("success");
  const [toastContent, settoastContent] = useState("Logged In");
  const handleClick = (type, content) => {
    setOpen(true);
    settoastType(type);
    settoastContent(content);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  //======================================================== Query fetching  ========================================================//


  const fetchBooks = async (level) => {
    setsubList([]);
    const q = query(
      collection(db, "contact_us_query"),
      orderBy("order", "asc"),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setsubList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
          },
        ];
      });
    });
  };
  useState(()=>{
    fetchBooks();
  })

  return (
    <div data-aos-delay="400">
      <Stack spacing={2} sx={{ width: "50%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={toastType}
            sx={{ width: "100%" }}
          >
            {toastContent}
          </Alert>
        </Snackbar>
      </Stack>
      <div className="space-y-2 text-center py-4">
        <h2 className="text-3xl py-4 font-bold">Contact Us</h2>
        <p className="font-serif text-lg dark:text-gray-400">
          “Being a student is easy. Learning requires actual work.”—William
          Crawford
        </p>
      </div>
      <div className="grid max-w-screen-xl grid-cols-1 gap-8 px-8 py-16 mx-auto rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 dark:bg-white dark:text-gray-100">
        <div className="flex flex-col justify-between">
          <div className="space-y-2">
            <h2 className="text-4xl font-bold text-black lg:text-3xl">
              Let's Hear From You{" "}
            </h2>
            <div className="dark:text-black"></div>
          </div>

          <img src={contact} alt="" className="object-contain rounded-xl" />
        </div>
        <form onSubmit={handleSubmit} className="space-y-1">
          <div>
            <label htmlFor="message" className="text-lg text-black">
              Could you kindly specify the nature or topic of your query?
            </label>
            <select
              required
              value={subject}
              onChange={(e) => setsubject(e.target.value)}
              id="message"
              className="w-full mt-4 p-3 rounded-lg bg-gray-100 text-black"
            >
              <option value="" >
                Select a topic
              </option>
              {subList.map((doc, index) => {
                return (
                  <option value={doc.data.content}>{doc.data.content}</option>
                );
              })}
            </select>

            {/* <label for="message" className="text-lg text-black mt-4   ">
              Enter Your Message
            </label> */}
            <p className="mt-6 text-lg text-black">
            Enter Your Message
            </p>
            <textarea
              required
              value={message}
              onChange={(e) => setmessage(e.target.value)}
              id="message"
              rows="6"
              className="w-full mt-4 p-3 rounded-lg bg-gray-100 text-black"
            ></textarea>
          </div>
          <button
            type="submit"
            class="inline-flex items-center justify-center px-6 py-4 mt-3 w-full font-semibold text-white transition-all duration-200 bg-black rounded-md hover:bg-black  focus:bg-black "
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
}
