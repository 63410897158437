import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, setDoc, increment, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { db } from "../Config/Config";

import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";
import { FaPlay, FaPause } from 'react-icons/fa';
export default function Chapter() {
  const [open, setOpen] = React.useState(false);

  // user id
  const [userId, setuserId] = useState(null);
  const [pdf_progress, setpdf_progress] = useState(0);
  const [docExisit,setdocExsist] = useState(false);
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    setuserId(user.uid);
  
    if (!location.state){
      navigate("/myshelf");
    } else {
      if (location.state.page !== "exam") {
        startTimeFetching(user.uid);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // pdf check

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if(location.state&&location.state.page==="exam"){
      setOpen(false);
      navigate("/exams");
    } else {
      setOpen(false);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu
  };

  const divStyle = {
    cursor: "pointer",
    // Add any other styles you want for the div
  };

  // video settings
  const videoRef = useRef(null);
  const [startTime, setStartTime] = useState(0);
  // upload video details
  var currentValue = 0;
  const uploadVideoDetails = async (currentTime, progress, video) => {
    if (currentTime !== currentValue) {
      await setDoc(
        doc(db, "play_position", userId, "positions", location.state.id),
        {
          video_position: currentTime,
          total_video_time_spend: increment(5),
          total_time_spend: increment(5),
          progress: progress,
          pdf_progress: pdf_progress,
          video_progress: video > 95 ? 100 : video,
        },
        { merge: true }
      );
      currentValue = currentTime;
    }
  };


///audio player //////


  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1); // Speed control
  const [progress, setProgress] = useState(0); // Seek progress
  const [duration, setDuration] = useState(0); // Audio duration

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSpeedChange = (e) => {
    const newSpeed = parseFloat(e.target.value);
    setSpeed(newSpeed);
    if (audioRef.current) {
      audioRef.current.playbackRate = newSpeed;
    }
  };

  const handleSeek = (e) => {
    const newTime = parseFloat(e.target.value);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
    setProgress(newTime);
  };

  const updateProgress = () => {
    if (audioRef.current) {
      setProgress(audioRef.current.currentTime);
    }
  };


  
 
  // determining the video postion
  const [videoFlag, setvideoFlag] = useState(false);
  useEffect(() => {
    // Function to update the current time
    if (videoFlag) {
      const updateCurrentTime = () => {
        if (videoRef.current) {
          var per = 0;
          per =
            (Math.floor(videoRef.current.currentTime) / totalDuration) * 100;
          var u_per = 0;
          u_per = ((parseInt(pdf_progress) + Math.floor(per)) / 200) * 100;
          uploadVideoDetails(
            Math.floor(videoRef.current.currentTime),
            Math.floor(u_per),
            Math.floor(per)
          );
        }
      };

      // Set up an interval to call the updateCurrentTime function every 5 seconds
      const intervalId = setInterval(updateCurrentTime, 5000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoFlag]);
  // total duration
  const [totalDuration, settotalDuration] = useState(0);
  useEffect(() => {
    if (location.state && location.state.page !== "exam") {
      const videoElement = videoRef.current;

      const handleLoadedMetadata = () => {
        // Check if the video element has loaded metadata
        if (videoElement && videoElement.readyState >= 1) {
          // Retrieve the total duration of the video in seconds
          const totalDurationInSeconds = videoElement.duration;
          settotalDuration(totalDurationInSeconds);
        }
      };

      // Attach the event listener for when video metadata is loaded
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);

      // Clean up the event listener when the component unmounts
      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // user id

  // video play
  const handlePlay = async () => {
    var date = new Date();
    setvideoFlag(true);
    await setDoc(
      doc(db, "play_position", userId),
      {
        uid: userId,
      },
      { merge: true }
    );
    if(!docExisit){
    await setDoc(
      doc(db, "play_position", userId, "positions", location.state.id),
      {
        video_last_opened_time: date,
        video_total_length: Math.floor(totalDuration),
        chapter_last_opened_time: date,
        thumbnail: location.state.data.data.thumbnail,
        chapter_name: location.state.data.data.chapter_name,
        video_position: 0,
        total_video_time_spend: 0,
        total_time_spend: 0,
        progress: 0,
        pdf_progress: 0,
        video_progress: 0,
        pdf_index: 1,
        book_id: location.state.data.data.book_id,
      },
      { merge: true }
    );
    }
  };
  // fetching start time
  const startTimeFetching = async (id) => {
    const docRef = doc(db, "play_position", id, "positions", location.state.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setStartTime(parseInt(docSnap.data().video_position));
      setpdf_progress(parseInt(docSnap.data().pdf_progress));
      setdocExsist(true);
      // setvideo_progress(parseInt(docSnap.data().video_progress));
    } else {
      // docSnap.data() will be undefined in this case
      setStartTime(0);
      initialUpdate(id);
      setdocExsist(false);
    }
  };
   useEffect(() => {
     if (videoRef.current) {
       videoRef.current.currentTime = startTime;
     }
   }, [startTime]);

  useState(() => {
    if (location.state && location.state.page === "exam") {
      setOpen(true);
    }
  });

  const handlePause = () => {
    setvideoFlag(false);
  };

  const initialUpdate=async(id)=>{
    const date = new Date();
if (!docExisit) {
  await setDoc(
    doc(db, "play_position", id, "positions", location.state.id),
    {
      video_last_opened_time: date,
      video_total_length: totalDuration,
      chapter_last_opened_time: date,
      thumbnail: location.state.data.data.thumbnail,
      chapter_name: location.state.data.data.chapter_name,
      video_position: 0,
      total_video_time_spend: 0,
      total_time_spend: 0,
      progress: 0,
      pdf_progress: 0,
      video_progress: 0,
      pdf_index: 1,
      book_id: location.state.data.data.book_id,
    },
    { merge: true }
  );
}
  }
  return (
    <>
      {location.state && location.state.page === "exam" ? (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            scroll="body"
          >
            <div className="bg-white shadow-lg ">
              <div className="border-t-8 border-blue-400  flex">
                <div className="pt-2 flex justify-center"></div>
                <div className="w-full pt-4 pr-4">
                  <DialogTitle className="text-center">
                    Exam Instructions
                  </DialogTitle>

                  <ul className="lg:p-4 dark:bg-white dark:text-gray-500">
                    <p className="py-4 ml-4 text-sm text-gray-700">
                      {location.state.data.data.exam_intro}
                    </p>
                    <li>
                      <p className="py-4 ml-4 text-sm text-gray-700">
                        1. {location.state.data.data.exam_intro}
                      </p>
                    </li>
                    <li>
                      <p className="py-4 ml-4 text-sm text-gray-700">
                        2. Exam Duration:{" "}
                        {location.state.data.data.exam_duration}
                      </p>
                    </li>
                    <li>
                      <p className="py-4 ml-4 text-sm text-gray-700">
                        3. Total Questions:{" "}
                        {location.state.data.data.question_limit}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="p-4 flex space-x-4">
                <div
                  style={divStyle}
                  onClick={handleClose}
                  className="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm"
                >
                  Cancel
                </div>
                <div
                  onClick={() => {
                    navigate("/exam", {
                      state: {
                        data: location.state.data.data,
                        book_name: location.state.book.book_name,
                      },
                    });
                  }}
                  style={divStyle}
                  className="w-1/2 px-4 py-3 text-center text-white bg-blue-500 rounded-lg hover:bg-blue-400 font-bold text-sm"
                >
                  Start Now
                </div>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <section className="bg-white dark:text-gray-00">
          <div className="space-y-2 text-center py-12">
            <h2 className="text-4xl font-bold ">
              {location.state ? location.state.data.data.chapter_name : null}
            </h2>
            <p className="font-serif text-xl dark:text-gray-400">
              {location.state && location.state.data.data.desc}
            </p>
          </div>
          <div className="container mx-auto py-9 md:py-12 px-4 md:px-20">
            <video
              ref={videoRef}
              onPlay={handlePlay}
              onPause={handlePause}
              controlsList="nodownload"
              onContextMenu={handleContextMenu}
              className="bg-no-repeat bg-center  mx-auto bg-cover rounded-xl h-90 sm:h-396  w-screen object-cover"
              controls
              poster={location.state ? location.state.data.data.thumbnail : ""}
            >
              <source
                src={location.state ? location.state.data.data.video : ""}
                type="video/mp4"
              />
            </video>
          </div>

          <div class="max-w-[85rem]  px-4 py-4 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div class="grid lg:grid-cols-2  lg:gap-y-16 gap-10">
              <a
                href
                class="group rounded-xl bg-white overflow-hidden shadow-xs border   p-6"
                onClick={() => {
                  localStorage.setItem("pdfFlag", true);
                  navigate("/pdfviewer", {
                    state: {
                      url: location.state.data.data.note,
                      data: location.state.data.data,
                      id: location.state.id,
                    },
                  });
                }}
              >
                <div class="sm:flex">
                  <div class="flex-shrink-0 relative object-contain rounded-xl overflow-hidden  w-full sm:w-56 h-44">
                    <img
                      class="group-hover:scale-105  transition-transform duration-500 ease-in-out  absolute top-0 left-0 object-cover rounded-xl"
                      src="https://t3.ftcdn.net/jpg/04/26/62/74/360_F_426627402_MKwE8SgQawtwtq9CAYVsKm4HglcQSagf.jpg"
                      alt="Images"
                    />
                  </div>

                  <div class="grow mt-4 sm:mt-0 sm:ml-6 px-4 sm:px-0">
                    <h3 class="text-2xl font-bold text-black group-hover:text-black dark:ttext-black dark:group-hover:text-black">
                      Go through the note’s
                    </h3>
                    <p class="mt-3 text-gray-900 dark:text-gray-400">
                      The earlier you start working on something, the earlier
                      you will see results.
                    </p>
                    <p class="mt-5 cursor-pointer inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium">
                      Read Notes
                      <svg
                        class="w-2.5 h-2.5"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </p>
                  </div>
                </div>
              </a>

              <a
                href
                onClick={handleClickOpen}
                class="group rounded-xl bg-white overflow-hidden shadow-xs border  p-6"
              >
                <div class="sm:flex ">
                  <div class="flex-shrink-0 relative rounded-xl object-contain overflow-hidden w-full sm:w-56 h-44">
                    <img
                      class="group-hover:scale-105 object-contain transition-transform duration-500 ease-in-out  absolute top-0 left-0 object-cover rounded-xl"
                      src="https://t3.ftcdn.net/jpg/02/18/23/64/360_F_218236421_mAe1LAtZV9QH1KHWbO061j6c1sqY86nk.jpg"
                      alt="Images"
                    />
                  </div>

                  <div class="grow mt-4 sm:mt-0 sm:ml-6 px-4 sm:px-0">
                    <h3 class="text-2xl font-bold text-black group-hover:text-black dark:ttext-black dark:group-hover:text-black">
                      Let’s take a test
                    </h3>
                    <p class="mt-3 text-gray-900 dark:text-gray-400">
                      A successful tomorrow belongs to those who prepare today
                    </p>
                    <p class="mt-5 cursor-pointer inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium">
                      Start Now
                      <svg
                        class="w-2.5 h-2.5"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </p>
                  </div>
                </div>
              </a>
              {location.state.data.data.audio ? (
                <div className="group rounded-xl bg-white shadow-xs border overflow-hidden p-6 max-w-3xl mx-auto">
                  <div className="sm:flex items-center">
               <div className="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44 mb-4 sm:mb-0 flex items-center justify-center">
  <img
    className="rounded-xl h-full w-full object-fill"
    src={location.state.data.data.audio_thumbnail}
    alt="Audio Class"
  />
</div>


                    <div className="flex-grow sm:ml-6 px-4 sm:px-0">
                      <h3 className="text-xl font-bold text-gray-800 group-hover:text-gray-900">
                        Audio Class: Mastering the Basics
                      </h3>
                      <p className="mt-2 text-sm text-gray-600">
                        Explore the fundamentals with our easy-to-follow audio
                        lessons.
                      </p>

                      <div className="mt-4">
                        <audio
                          ref={audioRef}
                          src={location.state.data.data.audio}
                          preload="auto"
                          onTimeUpdate={updateProgress}
                          onLoadedMetadata={() =>
                            setDuration(audioRef.current?.duration || 0)
                          }
                        />

                        <div className="flex items-center space-x-4">
                          <button
                            onClick={handlePlayPause}
                            className="w-12 h-12 bg-blue-500 text-white rounded-full shadow-lg flex items-center justify-center transition-transform duration-300 ease-in-out hover:bg-blue-600 hover:scale-105"
                          >
                            {isPlaying ? (
                              <FaPause className="h-4 w-4" />
                            ) : (
                              <FaPlay className="h-4 w-4" />
                            )}
                          </button>

                          <div className="flex-grow mt-4">
                            <input
                              type="range"
                              min="0"
                              max={duration}
                              step="0.1"
                              value={progress}
                              onChange={handleSeek}
                              className="w-full h-2 bg-gray-200 rounded-full appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <div className="flex justify-between text-sm text-gray-500 mt-1">
                              <span>{Math.floor(progress)}s</span>
                              <span>{Math.floor(duration)}s</span>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4 flex items-center space-x-4">
                          <label className="text-xl font-medium text-gray-700">
                            Speed:
                          </label>
                          <select
                            value={speed}
                            onChange={handleSpeedChange}
                            className="p-1 border border-gray-300 rounded-lg bg-white text-gray-700   focus:outline-none transition duration-200 ease-in-out hover:bg-gray-50"
                          >
                            <option value="0.5">0.5x</option>
                            <option value="1">1x (Normal)</option>
                            <option value="1.5">1.5x</option>
                            <option value="2">2x</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            scroll="body"
          >
            <div className="bg-white shadow-lg ">
              <div className="border-t-8 border-blue-400  flex">
                <div className="pt-6 flex justify-center"></div>
                <div className="w-full pt-9 pr-4">
                  <DialogTitle className="text-center">
                    Exam Instructions
                  </DialogTitle>

                  <pre
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                    className="  py-4 ml-4 text-sm text-gray-700"
                  >
                    {location.state && location.state.data.data.exam_intro}
                  </pre>

                  {/* <li>
                      <p className="py-4 ml-4 text-sm text-gray-700">
                        2. Exam Duration:{" "}
                        {location.state.data.data.exam_duration}
                      </p>
                    </li>
                    <li>
                      <p className="py-4 ml-4 text-sm text-gray-700">
                        3. Total Questions:{" "}
                        {location.state.data.data.question_limit}
                      </p>
                    </li> */}
                </div>
              </div>

              <div className="p-4 flex space-x-4">
                <div
                  style={divStyle}
                  onClick={handleClose}
                  className="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm"
                >
                  Cancel
                </div>
                <div
                  onClick={() => {
                    navigate("/exam", {
                      state: {
                        data: location.state.data.data,
                        book_name: location.state.book.book_name,
                      },
                    });
                  }}
                  style={divStyle}
                  className="w-1/2 px-4 py-3 text-center text-black bg-blue-400 rounded-lg hover:bg-blue-500 font-bold text-sm"
                >
                  Start Now
                </div>
              </div>
            </div>
          </Dialog>
        </section>
      )}
    </>
  );
}
