import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../assets/Loading";
import { app, db } from "../Config/Config";
import Popup from "reactjs-popup";
import placeHolder from "./Images/web book tumb.png";
import AOS from "aos";
import "aos/dist/aos.css";
import PageClick from "../Config/PageClick";
import User from "../Auth/User";
import UserData from "../Config/UserData";
import PurchasePopup from "./PreBuy/PurchasePopup";
import { getAuth } from "firebase/auth";


export default function LevelBooks() {
  const clickReport = PageClick("book_list");
  const uid = User();
  const userData = UserData(uid);
  //=========================================== Variable decleration ===========================================/
  AOS.init();
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const [bookList, setbookList] = useState([]);
  const [imageFlag, setimageFlag] = useState(true);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(true);
  const [user, setuser] = useState();
  const [userId, setuserId] = useState(null);
  const [pBooks, setpBooks] = useState();
  const [dataC,setData] = useState();
  const [throughPageC,setthroughPage]= useState();
  const [statusC,setstatus] = useState();
  const [prePurchasePopup,setprePurchasePopup] = useState(false);


  useEffect(() => {
    setuser(userData);
    if(uid!==false){
      setuserId(uid);
    }
    if (userData !== false) {
      purchaseCheck(userData.uid);
      
    } else {
      setpBooks({my_books:[]})
    }
    if (clickReport) {
      // console.log(clickReport)
    }
  }, [clickReport, userData, user,uid]);

  //=========================================== Fetch books ===========================================/
  const fetchBooks = async (level) => {
    setbookList([]);
    const q = query(
      collection(db, "book_pricing"),
      orderBy("order", "asc"),
      where("status","==",true),
      where("level", "==", level),
      limit(16)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setbookList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
            percentage: (
              100 -
              (parseInt(doc.data().offer_price) / parseInt(doc.data().price)) *
                100
            ).toFixed(0),
          },
        ];
      });
    });
  };

const [levelName, setlevelName] = useState("");
   useEffect(() => {
       const params = new URLSearchParams(location.search);
      const level = params.get("level").trim();
      const Name = params.get("name").trim();
      if (level) {
          setlevelName(Name);
          fetchBooks(level);
      }
               
   }, [location]);

 
 const divStyle = {
    cursor: "pointer",
    // Add any other styles you want for the div
  };

  //=========================================== User check ===========================================/

  const purchaseCheck = async (uid) => {
    const userRef = doc(db, "users", uid);
    await getDoc(userRef).then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setpBooks(data);
      } else {
        console.log("Document not found");
      }
    });
  };


  //=========================================== Payment function ===========================================/
  const functions = getFunctions(app, "asia-south1");
  const paymentFunction = (data,throughPage,status) => {
   // through page check
  
       setloading(true);
    if(uid!==false&&user!==false){
        if(throughPage){
                navigate(`/bookdetails?b=${data.book_id} &p=book`);

    } else{
      const orderdetails = {
        img: data.book_img,
        package: data.book_id,
        name: data.book_name,
        uid: userId,
        platform: "web",
        books: [data.book_id],
        collection: "book_pricing",
        currency: userData.country === "India" ? "INR" : "USD",
        amount:
          userData.country === "India"
            ? data.offer_price
            : data.dollar_offer_price,
      };
      const paymentRequest = httpsCallable(functions, "createOrder");
      paymentRequest({
        img: data.book_img,
        package: data.book_id,
        name: data.book_name,
        uid: userId,
        platform: "web",
        books: [data.book_id],
        collection: "book_pricing",
        currency: userData.country === "India" ? "INR" : "USD",
        amount:
          userData.country === "India"
            ? data.offer_price
            : data.dollar_offer_price,
        orderdetails,
      }).then((result) => {
        const data = result.data;
        if (data !== 500) {
          const order = result.data;
          // console.log("Order Data:", order);

          const options = {
            key: process.env.REACT_APP_RAZOR_KEY,
            amount: order.amount,
            currency: order.currency,
            name: "The Goodwill Academy",
            description: "Payment for e-book",
            order_id: order.id,
            handler: function (response) {
             window.location.reload();
            },
            prefill: {
              name: order.notes.user_name,
              email: getAuth().currentUser.email,
              contact: order.notes.mobile,
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
            modal: {
              ondismiss: function () {
                setloading(false);
              },
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.open();
          rzp1.on("payment.failed", function (response) {
            setloading(false);
            alert("Something went wrong! Try again..");
          });
        } else {
          alert("Something went wrong!!");
          setloading(false);
        }
      });
      //========================================= Stripe payment function =========================================//

      // const paymentRequest = httpsCallable(functions, "paymentRequest");
      // paymentRequest({
      //   img: data.book_img,
      //   package: data.book_id,
      //   name: data.book_name,
      //   uid: userId,
      //   platform: "web",
      //   books: [data.book_id],
      //   collection: "book_pricing",
      //   // currency: userData.country === "India" ? "inr" : "usd",
      //   currency: "inr",
      // }).then((result) => {
      //   const data = result.data;
      //   if (data) {
      //     window.location.href = data.url;
      //   } else {
      //     alert("Something went wrong!!");
      //     setloading(false);
      //   }
      // });

      //========================================= Stripe payment function =========================================//
    }
  } else {
      if (uid === false) {
        localStorage.setItem("login_popup", true);
        const storageEvent = new Event("storage");
        window.dispatchEvent(storageEvent);
        setloading(false);
      } else if (!user) {
        localStorage.setItem("register_popup", true);
        const storageEvent = new Event("register");
        window.dispatchEvent(storageEvent);
        setloading(false);
      }
}
  };

  const handleCart = async (product) => {
    if (uid !== false && user !== false) {
      localStorage.setItem("cart_flag", true);
      const cartEvent = new Event("cartEvent");
      window.dispatchEvent(cartEvent);
      await setDoc(doc(db, "users", uid, "cart", product.book_id), product, {
        merge: true,
      });
    } else {
      if (uid === false) {
        localStorage.setItem("login_popup", true);
        const storageEvent = new Event("storage");
        window.dispatchEvent(storageEvent);
        setloading(false);
      } else if (!user) {
        localStorage.setItem("register_popup", true);
        const storageEvent = new Event("register");
        window.dispatchEvent(storageEvent);
        setloading(false);
      }
    }
  };

  const handleClose=()=>{
    setprePurchasePopup(false);
  }

    const handlePopupSubmit = () => {
      paymentFunction(dataC,throughPageC,statusC)
    };

 const handleprePurchase = (data, throughPage, status) => {
   if (!getAuth().currentUser) {
     localStorage.setItem("login_popup", true);
     const storageEvent = new Event("storage");
     window.dispatchEvent(storageEvent);
     setloading(false);
   } else if (!user) {
     setprePurchasePopup(false);
     localStorage.setItem("register_popup", true);
     const storageEvent = new Event("register");
     window.dispatchEvent(storageEvent);
     setloading(false);
   } else {
     if (throughPage) {
       paymentFunction(data, throughPage, status);
     } else {
       setData(data);
       setthroughPage(throughPage);
       setstatus(status);
       setprePurchasePopup(true);
     }
   }
 };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {prePurchasePopup ? (
            <PurchasePopup
              onClose={handleClose}
              onSubmit={handlePopupSubmit} // Pass the callback to handle return value
            />
          ) : null}
          <h1 className="text-xl px-10  mt-6 py-4  text-center  font-bold text-black dark:text-black md:text-3xl">
            {levelName}
          </h1>
          {pBooks &&
            bookList.some(
              (doc) => pBooks.my_books.includes(doc.data.book_id)
            ) && (
              <section>
                <div class="mx-auto grid max-w-8xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                  {pBooks ? (
                    <>
                      {bookList.map((doc, index) => {
                        return (
                          <>
                            {pBooks &&
                            pBooks.my_books.includes(doc.data.book_id) ? (
                              <div>
                                <article
                                  style={divStyle}
                                  class="rounded-2xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300  "
                                >
                                  <div
                                    onClick={() => {
                                      paymentFunction(
                                        doc.data,
                                        true,
                                        pBooks &&
                                          pBooks.my_books.includes(
                                            doc.data.book_id
                                          )
                                      );
                                    }}
                                  >
                                    <div class="relative flex items-end overflow-hidden rounded-xl">
                                      <img
                                        onLoad={() => setimageFlag(false)}
                                        src={
                                          imageFlag
                                            ? placeHolder
                                            : doc.data.book_img
                                        }
                                        alt="aa"
                                      />
                                    </div>
                                    <h2 class="text-slate-700 mt-2 text-xs">
                                      {doc.data.book_name.toUpperCase()}
                                    </h2>
                                    <p class="mt-1 text-xs text-slate-400">
                                      {doc.data.sub_title}
                                    </p>
                                  </div>
                                  <div class="">
                                    <div class="mt-3 flex items-end justify-between">
                                      <button
                                        class="flex  float-left items-center  rounded-lg bg-black px-4 py-1.5 text-white duration-100 hover:bg-black ml-0 "
                                        onClick={() => {
                                          paymentFunction(doc.data, true, true);
                                        }}
                                      >
                                        Read Now
                                      </button>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            ) : null}
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </section>
            )}
          {/* Not purchased */}
          <section>
            {pBooks &&
              bookList.some(
                (doc) => !pBooks.my_books.includes(doc.data.book_id)
              ) && (
                <h1 className="text-2xl px-10 mt-6 py-4 font-medium text-black dark:text-black md:text-2xl">
                  Explore Our Premium Book Collection
                </h1>
              )}

            <div class="mx-auto grid max-w-8xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {pBooks ? (
                <>
                  {bookList.map((doc, index) => {
                    return (
                      <>
                        {pBooks &&
                        !pBooks.my_books.includes(doc.data.book_id) ? (
                          <div>
                            <article
                              style={divStyle}
                              class="rounded-2xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300  "
                            >
                              <div
                                onClick={() => {
                                  paymentFunction(
                                    doc.data,
                                    true,
                                    pBooks &&
                                      pBooks.my_books.includes(doc.data.book_id)
                                  );
                                }}
                              >
                                <div class="relative flex items-end overflow-hidden rounded-xl">
                                  <img
                                    onLoad={() => setimageFlag(false)}
                                    src={
                                      imageFlag
                                        ? placeHolder
                                        : doc.data.book_img
                                    }
                                    alt="aa"
                                  />

                                  <div class="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
                                    <img
                                      width="24"
                                      height="24"
                                      src="https://img.icons8.com/material-rounded/24/lock--v1.png"
                                      alt="lock--v1"
                                    />
                                  </div>
                                </div>
                                <h2 class="text-slate-700 mt-2 text-xs">
                                  {doc.data.book_name.toUpperCase()}
                                </h2>
                                <p class="mt-1 text-xs text-slate-400">
                                  {doc.data.sub_title}
                                </p>
                              </div>
                              <div class="">
                                <div class="mt-3 flex items-end justify-between">
                                  <p
                                    class="text-lg  text-black"
                                    onClick={() => {
                                      handleprePurchase(doc.data, true, false);
                                    }}
                                  >
                                    {userData.country === "India"
                                      ? "₹" + doc.data.offer_price
                                      : "$" + doc.data.dollar_offer_price}
                                    &nbsp;&nbsp;
                                    <span className="line-through text-red-600">
                                      {userData.country === "India"
                                        ? "₹" + doc.data.price
                                        : "$" + doc.data.dollar_price}
                                    </span>
                                    &nbsp;
                                    {doc.percentage > 0 ? (
                                      <span className="text-green-600 font-sm">
                                        ({doc.percentage}%)
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                                {loading ? (
                                  <Popup
                                    open={openPopup}
                                    onClose={() => setOpenPopup(false)}
                                    contentStyle={{
                                      background: "whites", // Semi-transparent black background
                                      border: "none",
                                      width: "100%", // Full width
                                      padding: 0,
                                    }}
                                  >
                                    <div className="p">
                                      <Spinner />
                                    </div>
                                  </Popup>
                                ) : (
                                  <div class="flex items-center justify-between mt-4  ">
                                    <button
                                      class="space-x-1.5 rounded-lg bg-black px-4 py-1.5 text-white duration-100 hover:bg-black"
                                      onClick={() => {
                                        handleprePurchase(doc.data, false);
                                      }}
                                    >
                                      Buy Now
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      onClick={() => handleCart(doc.data)}
                                      class="space-x-1.5 rounded-lg bg-gray-200 px-3 py-2  text-black"
                                    >
                                      Add to Cart
                                    </button>
                                  </div>
                                )}
                              </div>
                            </article>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </section>
        </div>
      )}
    </>
  );
}
