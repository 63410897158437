import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../assets/Loading";
import PurchasePopup from "../Book/PreBuy/PurchasePopup";
import { app, db } from "../Config/Config";

export default function Package(user) {
  // fetch packages

  const [packageList, setpackageList] = useState([]);
  const [loading, setloading] = useState(true);
    const [dataC, setData] = useState();
    const [prePurchasePopup, setprePurchasePopup] = useState(false);
  const navigate = useNavigate();
  const fetchPackage = async () => {
    setpackageList([]);
    const q = query(
      collection(db, "packages"),
      orderBy("order", "asc"),
      limit(7)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setpackageList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
            percentage: (
              100 -
              (parseInt(doc.data().offer_price) / parseInt(doc.data().price)) *
                100
            ).toFixed(0),
          },
        ];
      });
    });
    setloading(false);
  };
  useState(() => {
    fetchPackage();
  });

  // calling stripe
  const functions = getFunctions(app, "asia-south1");
  const [userId, setuserId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const loggedInUser = auth.currentUser;
    if(loggedInUser){
       setuserId(loggedInUser.uid);
       pageClick(loggedInUser.uid);
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paymentFunction = (data) => {
    setloading(true);
    localStorage.setItem("bookdetailsloading", true);
    const loadingEvent = new Event("bookdetailsloading");
    window.dispatchEvent(loadingEvent);
    const paymentRequest = httpsCallable(functions, "createOrder");
    paymentRequest({
      amount:
        user.country === "India" ? data.offer_price : data.dollar_offer_price,
      img: data.book_img_1,
      name: data.package_name,
      package: data.package,
      uid: userId,
      platform: "web",
      books: data.book_id,
      collection: "packages",
      currency: user.country === "India" ? "INR" : "USD",
    }).then((result) => {
     const data = result.data;
     if (data !== 500) {
       const order = result.data;
       // console.log("Order Data:", order);

       const options = {
         key: process.env.REACT_APP_RAZOR_KEY,
         amount: order.amount,
         currency: order.currency,
         name: "The Goodwill Academy",
         description: "Payment for e-book",
         order_id: order.id,
         handler: function (response) {
           // window.location.reload()
          //  setloading(false);
          //  localStorage.setItem("bookdetailsloading", true);
          //  const loadingEvent = new Event("bookdetailsloading");
          //  window.dispatchEvent(loadingEvent);
          navigate("/myshelf")
         },
         prefill: {
           name: order.notes.user_name,
           email: getAuth().currentUser.email,
           contact: order.notes.mobile,
         },
         notes: {
           address: "Razorpay Corporate Office",
         },
         theme: {
           color: "#3399cc",
         },
         modal: {
           ondismiss: function () {
             window.location.reload();
           },
         },
       };

       const rzp1 = new window.Razorpay(options);
       rzp1.open();
       rzp1.on("payment.failed", function (response) {
         setloading(false);
         alert("Something went wrong! Try again..");
       });
     } else {
       alert("Something went wrong!!");
       setloading(false);
     }
    });
    //========================================= Stripe payment function =========================================//

    //  const paymentRequest = httpsCallable(functions, "paymentRequest");
    //  paymentRequest({
    //    // amount: 300,
    //    img: data.book_img_1,
    //    name: data.package_name,
    //    package: data.package,
    //    uid: userId,
    //    platform: "web",
    //    books: data.book_id,
    //    collection: "packages",
    //    // currency: user.country === "India" ? "inr" : "usd",
    //    currency: "inr",
    //  }).then((result) => {
    //    const data = result.data;
    //    if (data) {
    //      window.location.href = data.url;
    //    } else {
    //      alert("Something went wrong!!");
    //      setloading(false);
    //    }
    //  });

    //========================================= Stripe payment function =========================================//
  };
  const divStyle = {
    cursor: "pointer",
    // Add any other styles you want for the div
  };

  // user check
  const [pBooks, setpBooks] = useState();
  const purchaseCheck = async (uid) => {
    const userRef = doc(db, "users", uid);
    await getDoc(userRef).then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setpBooks(data);
      } else {
        console.log("Document not found");
      }
    });
  };
  // user check
  useEffect(() => {
    const auth = getAuth();
    const loggedInUser = auth.currentUser;
      if(loggedInUser){
          purchaseCheck(loggedInUser.uid);
      }
  }, []);
  const [class_name, setclass_name] = useState(
    "max-w-8xl mx-auto pt-10 pb-36 px-8"
  );
  useEffect(() => {
    if (packageList.length) {
      if (packageList.length === 2) {
        setclass_name("max-w-4xl mx-auto pt-10 pb-36 px-8");
      } else if (packageList.length === 1) {
        setclass_name("max-w-xl mx-auto pt-10 pb-36 px-8");
      }
    }
  }, [packageList]);
  const pageClick = (uid) => {
    const pageClick = httpsCallable(functions, "pageClick");
    pageClick({
      uid: uid,
      page_name: "buy_book",
    }).then((result) => {
      // const data = result.data;
      // if (data) {
      //   console.log("done")
      // } else {
      //   alert("Something went wrong!!");
      // }
    });
  };

  const handleClose = () => {
    window.location.reload();
  };

  const handlePopupSubmit = () => {
    paymentFunction(dataC);
  };
      const handleprePurchase = (data) => {
          setData(data);
          setprePurchasePopup(true);
        
      };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {prePurchasePopup ? (
            <PurchasePopup
              onClose={handleClose}
              onSubmit={handlePopupSubmit} // Pass the callback to handle return value
            />
          ) : null}
          <main class={class_name}>
            <div class="max-w-md mx-auto mb-14 text-center overflow-x-auto`"></div>
            {packageList ? (
              <div class="flex flex-col justify-between items-center lg:flex-row lg:items-start">
                {packageList.map((doc, index) => {
                  return (
                    <>
                      {userId && pBooks &&
                      doc.data.book_id.every((item) =>
                        pBooks.my_books.includes(item)
                      ) ? null : (
                        <div class="w-full transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 flex-1 mt-8 p-8 order-6 bg-white shadow-2xl rounded-3xl sm:w-96 lg:w-full lg:order-1 ml-4">
                          <div class="mb-7 pb-7 flex items-center border-b border-gray-300">
                            <img
                              src={doc.data.book_img_1}
                              alt=""
                              class="rounded-3xl w-20 h-20"
                            />
                            <div class="ml-5">
                              <span class="block text-2xl font-semibold">
                                {doc.data.package_name}
                              </span>
                              <span>
                                <span class="font-medium text-gray-500 text-xl align-top">
                                </span>
                                <span class="text-3xl font-bold">
                                  {user.country === "India"
                                    ? "₹" + doc.data.offer_price
                                    : "$" + doc.data.dollar_offer_price}
                                </span>
                              </span>
                              <span class="text-gray-500 font-medium">
                                /year
                              </span>
                              <br />
                              {doc.percentage > 0 ? (
                                <span className="text-green-700 ">
                                  ({doc.percentage}%)
                                </span>
                              ) : null}
                              &nbsp;
                              <span className="text-red-500 line-through">
                                {user.country === "India"
                                  ? "₹" + doc.data.price
                                  : "$" + doc.data.dollar_price}
                              </span>
                            </div>
                          </div>
                          <ul class="mb-7 font-medium text-gray-500">
                            <li class="flex text-lg mb-4">
                              <span class="ml-3">
                                {" "}
                                <span class="text-black justify-center items-center">
                                  {doc.data.package_subtitle}
                                </span>
                              </span>
                            </li>
                            <li class="flex text-lg mb-4">
                              <span class="ml-3">
                                {" "}
                                <span class="text-black justify-center items-center">
                                  {doc.data.book_id.length}&nbsp;Books
                                </span>
                              </span>
                            </li>
                            <li class="flex text-lg">
                              <span class="ml-3">
                                <span class="text-black justify-center items-center">
                                  {doc.data.validity}&nbsp; Days
                                </span>{" "}
                              </span>
                            </li>
                          </ul>
                          <div class="border-t border-gray-300 my-4"></div>
                          <div className="flex flex-row">
                            <button
                              onClick={() => {
                                navigate("/viewbooks", {
                                  state: {
                                    doc_id: doc.id,
                                    book_id: doc.data.book_id,
                                    data: doc.data,
                                  },
                                });
                              }}
                              className="flex justify-center items-center bg-yellow-400 rounded-xl py-3 px-4 text-center text-black text-sm ml-2"
                            >
                              View Books
                            </button>
                            <button
                              style={divStyle}
                              onClick={() => {
                                handleprePurchase(doc.data);
                              }}
                              className="flex justify-center items-center bg-black rounded-xl py-3 px-4  text-center text-white text-sm ml-5"
                            >
                              Buy Now
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            ) : null}
          </main>
        </div>
      )}
    </>
  );
}
