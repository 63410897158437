import React from "react";
export default function Footer() {
  return (
    <section class="py-2 bg-blue-500 sm:pt-16 lg:pt-12">
      <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
          <div class="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
            <p class="text-base leading-relaxed text-white mt-7 font-bold">
              Professional Coaching Online
              <br />
              The Goodwill Academy
            </p>
            <p class="text-base leading-relaxed text-white ">
              Email: infothegoodwillacademy@gmail.com
            </p>
            <p class="text-base leading-relaxed text-white ">
              Mob: +91 95448 00006
            </p>
          </div>

          <div>
            <ul class="mt-6 space-y-4">
              <li>
                <a
                  href="/myshelf"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-400 focus:text-blue-400"
                >
                  {" "}
                  My Shelf{" "}
                </a>
              </li>

              <li>
                <a
                  href="/exams"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-400 focus:text-blue-400"
                >
                  {" "}
                  Exam{" "}
                </a>
              </li>
              <li>
                <a
                  href="/faq"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-400 focus:text-blue-400"
                >
                  {" "}
                  FAQ{" "}
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul class="mt-6 space-y-4">
              <li>
                <a
                  href="/aboutus-info"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-400 focus:text-blue-400"
                >
                  {" "}
                  About Us{" "}
                </a>
              </li>
              <li>
                <a
                  href="/contact-info"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-400 focus:text-blue-400"
                >
                  {" "}
                  Help{" "}
                </a>
              </li>

              <li>
                <a
                  href="/privacypolicy-info"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-400 focus:text-blue-400"
                >
                  {" "}
                  Privacy Policy{" "}
                </a>
              </li>

              {/* <li>
                <a
                  href="/"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-400 focus:text-blue-400"
                >
                  {" "}
                  FAQ{" "}
                </a>
              </li> */}
            </ul>
          </div>

          <div class="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
            <p class="text-sm font-semibold tracking-widest text-white uppercase">
              Touch With Us
            </p>

            <ul class="flex items-center space-x-3 mt-9">
              {/* <li>
                <a
                  href="a"
                  title=""
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-9 h-9 hover:bg-blue-400 focus:bg-blue-400"
                >
                  <svg
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                  </svg>
                </a>
              </li> */}

              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61551365588836"
                  title=""
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-9 h-9 hover:bg-blue-400 focus:bg-blue-400"
                >
                  <svg
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/thegoodwillacademy/"
                  title=""
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-9 h-9 hover:bg-blue-400 focus:bg-blue-400"
                >
                  <svg
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                    <circle cx="16.806" cy="7.207" r="1.078"></circle>
                    <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/goodwill-academy-ba0329293"
                  title="LinkedIn"
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-9 h-9 hover:bg-blue-400 focus:bg-blue-400"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-linkedin"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.youtube.com/channel/UCA0IpH1xQ0RE9TrmtTsALjg"
                  title=""
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-9 h-9 hover:bg-blue-400 focus:bg-blue-400"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-youtube"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr className="mt-16 mb-10 border-gray-200" />

        <p className="text-sm text-center text-gray-100">
          &copy; {new Date().getFullYear()} ACCA Professional Coaching Online.
          All Rights Reserved.
        </p>
      </div>
    </section>
  );
}
