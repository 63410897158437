import React from "react";
import { useNavigate } from "react-router-dom";
import gif from "./f.gif";
export default function Success() {

  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="relative">
        <img src={gif} alt="GIF" className="h-full w-full" />
      </div>
      <h1 className="text-3xl font-bold animate-bounce mt-4">
        Payment Successful
      </h1>
      <br />
      <a className="text-2xl mt-4" href="/myshelf">
        <p>Continue Studying</p>
      </a>
      <button
        onClick={() => {
          navigate("/profile");
        }}
      >
        <p className="text-2xl mt-2">View Invoice</p>
      </button>
    </div>
  );
}
