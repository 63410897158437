import React from 'react'
import img from './assets/smartmockups_lp9jcudp 1.png'
export default function Mobile() {
  return (
    <section  class="bg-blue-500 2xl:py-12 2xl:bg-white">
    <div class="px-4 mx-auto overflow-hidden bg-blue-500 max-w-7xl sm:px-6 lg:px-8">
        <div class="py-2 sm:py-16 lg:py-12 2xl:pl-12">
            <div class="grid items-center grid-cols-1 gap-y-12 lg:grid-cols-2 lg:gap-x-8 2xl:gap-x-20">
                <div>
                    <h2 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl lg:leading-tight">You can now download ACCA Online to your smartphone</h2>
                    <p class="mt-4 text-base text-gray-50">"ACCA Online offers seamless access to your accounting studies, now conveniently available for download on both the App Store for Apple devices and Google Play for Android devices. Enhance your learning experience anytime, anywhere with our comprehensive resources and interactive features."</p>

                    <div class="flex flex-row items-center mt-8 space-x-4 lg:mt-12">
                                       <a href="https://apps.apple.com/in/app/professional-coaching-online/id6466734988" class="flex items-center bg-black text-white px-4 py-4 rounded-xl">
    <img src="https://img.icons8.com/fluency/48/apple-app-store.png"  alt="apple-app-store" class="w-6 h-6 mr-2" />
    
    <span class="font-bold  ml-1">Get It On App Store</span>
  </a>

                         <a href="https://play.google.com/store/apps/details?id=com.goodwill.procoaching.professional_coaching_online" class="flex items-center bg-black text-white px-4 py-4 rounded-xl">
    <img src="https://img.icons8.com/external-those-icons-flat-those-icons/24/external-Play-Store-logos-and-brands-those-icons-flat-those-icons.png" alt="external-Play-Store-logos-and-brands-those-icons-flat-those-icons"  class="w-6 h-6 mr-2" />
   
    <span class="font-bold ml-1"> Get It On Play Store</span>
  </a>
                    </div>
                </div>

                <div class="relative px-12">
                    <svg class="absolute inset-x-0 bottom-0 left-1/2 -translate-x-1/2 -mb-48 lg:-mb-72 text-blue-400 w-[460px] h-[460px] sm:w-[600px] sm:h-[600px]" fill="currentColor" viewBox="0 0 8 8">
                        <circle cx="4" cy="4" r="3" />
                    </svg>
                    <img class="relative w-full max-w-xs mx-h-sm mx-auto -mb-60 lg:-mb-64" src={img} alt="" />
                </div>
            </div>
        </div>
    </div>
</section>

  )
}
