import React from 'react'
import { useLocation } from 'react-router-dom'

export default function PreviousAnswers() {
    const location = useLocation();
  return (
    <div>
      {location.state.questions.map((doc, index) => {
        return (
          <div class="container mx-auto py-8">
            <div class="max-w-6xl mx-auto bg-white shadow-lg rounded-lg p-8">
              <h2 class="text-xl font-semibold mb-4">
                Question &nbsp;{index + 1}:
              </h2>
              <p class="text-gray-600 mb-6 xl:mr-10 sm:mr-5">
                {doc.data.question}
              </p>
              <ul class="space-y-2">
                {doc.ans === doc.data.a ? (
                  <>
                    <li class="flex items-center">
                      <label
                        for="option1"
                        class="ml-2 text-gray-700"
                        style={
                          doc.data.answer === doc.data.a
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        Option A : {doc.data.a}
                      </label>
                    </li>
                  </>
                ) : (
                  <>
                    <li class="flex items-center">
                      <label
                        for="option1"
                        class="ml-2 text-gray-700"
                        style={
                          doc.data.answer === doc.data.a
                            ? { color: "green" }
                            : null
                        }
                      >
                        Option A : {doc.data.a}
                      </label>
                    </li>
                  </>
                )}
                {doc.ans === doc.data.b ? (
                  <>
                    <li class="flex items-center">
                      <label
                        for="option1"
                        class="ml-2 text-gray-700"
                        style={
                          doc.data.answer === doc.data.b
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        Option B : {doc.data.b}
                      </label>
                    </li>
                  </>
                ) : (
                  <li class="flex items-center">
                    <label
                      for="option1"
                      class="ml-2 text-gray-700"
                      style={
                        doc.data.answer === doc.data.b
                          ? { color: "green" }
                          : null
                      }
                    >
                      Option B : {doc.data.b}
                    </label>
                  </li>
                )}
                {doc.ans === doc.data.c ? (
                  <>
                    <li class="flex items-center">
                      <label
                        for="option1"
                        class="ml-2 text-gray-700"
                        style={
                          doc.data.answer === doc.data.c
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        Option C : {doc.data.c}
                      </label>
                    </li>
                  </>
                ) : (
                  <li class="flex items-center">
                    <label
                      for="option1"
                      class="ml-2 text-gray-700"
                      style={
                        doc.data.answer === doc.data.c
                          ? { color: "green" }
                          : null
                      }
                    >
                      Option C : {doc.data.c}
                    </label>
                  </li>
                )}
                {doc.ans === doc.data.d ? (
                  <>
                    <li class="flex items-center">
                      <label
                        for="option1"
                        class="ml-2 text-gray-700"
                        style={
                          doc.data.answer === doc.data.d
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        Option D : {doc.data.d}
                      </label>
                    </li>
                  </>
                ) : (
                  <li class="flex items-center">
                    <label
                      for="option1"
                      class="ml-2 text-gray-700"
                      style={
                        doc.data.answer === doc.data.d
                          ? { color: "green" }
                          : null
                      }
                    >
                      Option D : {doc.data.d}
                    </label>
                  </li>
                )}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
}
