import { getFunctions, httpsCallable } from 'firebase/functions';
import  { useEffect, useState } from 'react'
import User from '../Auth/User';
import { app } from './Config';

export default function PageClick(page) {
        const uid = User();
        const [update,setupdate] = useState(false);
      const functions = getFunctions(app, "asia-south1");
      useEffect(()=>{
            if (!update) {
            const pageClick = httpsCallable(functions, "pageClick");
            pageClick({
                uid: uid!==false?uid:"not-loggedin",
                page_name: page,
            }).then((result) => {
                const data = result.data;
                if (data) {
                setupdate(true);
                } else {
                setupdate(false);
                }
            });
            }
      },[uid,page,update])
  return update;
}
