import React, { useEffect, useState } from 'react';
import { db } from '../Config/Config';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import Spinner from '../assets/Loading';

const Career = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [resume, setResume] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [activeTab, setActiveTab] = useState("Frontend");
  const [loading, setLoading] = useState(false);
  const auth = getAuth();

  // ===============================  Fetch User  ===============================//
  const fetchUser = async () => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setName(docSnap.data().name);
      setEmail(docSnap.data().email);
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openModal = async(job) => {
       const resRef = doc(
      db,
      "career_request",
      auth.currentUser.uid + job.id
    );
    const docSnap = await getDoc(resRef);
    if (docSnap.exists()) {
      alert("You have already applied for this job");
    } else {
      setResume(null);
      setSelectedJob(job);
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setSelectedJob(null);
    setShowModal(false);
  };

  // =============================== Filtering Jobs ===============================//
  const [sortData, setSortData] = useState([]);
  const sortOptions = async () => {
    const data = [];
    const ref = collection(db, "career_group");
    const q = query(ref, orderBy("order", "asc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    setSortData(data);
    setActiveTab(data[0].id);
    filterJobs(data[0].career_group_name);
    return data;
  };

  useEffect(() => {
    sortOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =============================== Filtering Jobs ===============================//
  const [jobList, setJobList] = useState([]);
  const filterJobs = async (category) => {
    setLoading(true);
    const ref = collection(db, "career");
    const data = [];
    const q = query(
      ref,
      orderBy("order", "asc"),
      where("status", "==", true),
      where("careergroup_name", "==", category)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    setLoading(false);
    setJobList(data);
  };

  // ============================================ Uploading Resume ============================================//
  const uploadResume = async (e) => {
    e.preventDefault();
 
    const date = new Date();
    const storage = getStorage();
    if (resume) {
      const storageRef = ref(storage, "career/"+auth.currentUser.uid+"/" + date);
      uploadBytes(storageRef, resume).then((snapshot) => {
        getDownloadURL(ref(storageRef)).then((url) => {
          uploadData(url);
        });
      });
    } else {
      alert("error", "Please upload your resume");
    }
  };
// ======================================= Upload Data =======================================//
  const uploadData = async (url) => {
    const data = {
      name: name,
      career_group_id: selectedJob.careergroup_id,
      career_group_name: selectedJob.careergroup_name,
      career_id: selectedJob.id,
      career_name: selectedJob.career_title,
      email: email,
      url: url,
      createdAt: new Date(),
      status: "pending",
      uid: auth.currentUser.uid,
    };
    await setDoc(
      doc(db, "career_request", auth.currentUser.uid+selectedJob.id),
      data
    );
    setSelectedJob(null);
    setShowModal(false);
    alert("Your application has been submitted successfully.");
}

  return (
    <div className="py-6">
      <div className="text-center mb-12">
        <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#281388] to-blue-600 mb-4">
          Explore Career Opportunities
        </h1>
        <p className="text-gray-600 text-lg">
          Discover roles that align with your aspirations and skills.
        </p>
      </div>

      <div className="flex px-4 max-w-7xl mx-auto  space-x-4 mb-6">
        {sortData &&
          sortData.map((category) => (
            <button
              key={category.id}
              onClick={() => {
                setActiveTab(category.id);
                filterJobs(category.career_group_name);
              }}
              className={`px-6 py-2 rounded-md ${
                activeTab === category.id
                  ? "bg-blue-800 text-white"
                  : "bg-gray-200 text-black"
              }`}
            >
              {category.career_group_name}
            </button>
          ))}
      </div>
        {loading?<Spinner/>:
      <div className="max-w-7xl mx-auto  grid gap-8 md:grid-cols-2 lg:grid-cols-3 px-4">
        {jobList.map((job) => (
          <div
            key={job.id}
            className="relative bg-white shadow-md  border border-dotted rounded-lg p-6 overflow-hidden"
          >
            <div className="flex justify-between">
              <h3 className="text-xl font-semibold text-blue-900">
                {job.careergroup_name}
              </h3>
              <img src={job.image_url} className="w-10 h-10 " alt="'" />
            </div>

            <p className="text-gray-600 mt-2 flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
              </svg>
              <span>{job.location}</span>
            </p>
            <p className="text-gray-600 mt-2 flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-briefcase"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5" />
              </svg>
              <span className="text-gray-600 ">{job.career_title}</span>
            </p>

            <p className="text-gray-600 mt-2 flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-cash"
                viewBox="0 0 16 16"
              >
                <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2z" />
              </svg>
              <span className="text-gray-600 "> {job.salary}</span>
            </p>

            <span className="text-gray-600 flex items-center space-x-2 mt-2">
              <p className="text-base">Qualification :</p>
              <span>{job.qualification}</span>
            </span>
            <span className="text-gray-600 flex items-center space-x-2 mt-2">
              <p className="text-base">Experience :</p>
              <span>{job.experience}</span>
            </span>

            <p className="text-gray-700 mt-4">{job.job_description}</p>
            <button
              onClick={() => openModal(job)}
              className="mt-6 w-full bg-blue-800 text-white py-2 rounded-md hover:bg-blue-800 transition-colors"
            >
              Apply Now
            </button>
          </div>
        ))}
      </div>
      }

      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full z-200 bg-black bg-opacity-50 flex items-center justify-center overflow-auto">
          <div className="bg-white p-6 rounded-lg w-96 h-auto mt-[4rem]">
            <form onSubmit={uploadResume}>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-600"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  className="mt-2 p-2 w-full border rounded-md"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-600"
                >
                  Your Email
                </label>
                <input
                  type="email"
                  value={email}
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  className="mt-2 p-2 w-full border rounded-md"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="resume"
                  className="block text-sm font-medium text-gray-600"
                >
                  Your Resume
                </label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  accept=".pdf, .doc, .docx"
                  onChange={(e) => setResume(e.target.files[0])}
                  className="mt-2 p-2 w-full border rounded-md"
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={closeModal}
                  className="ml-2 bg-gray-300 text-black px-4 py-2 rounded-md"
                >
                  Close
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  type="submit"
                  className="bg-blue-800 text-white px-4 py-2 rounded-md"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Career;
