import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import {   useNavigate } from "react-router-dom";
import Spinner from "../assets/Loading";
import { app, db } from "../Config/Config";
import placeHolder from "./Images/web book tumb.png";
import AOS from "aos";
import "aos/dist/aos.css";
import PageClick from "../Config/PageClick";
import User from "../Auth/User";
import UserData from "../Config/UserData";
import PurchasePopup from "./PreBuy/PurchasePopup";
import { getAuth } from "firebase/auth";
export default function ExamBooks() {
  //======================================== Variable Declerations and initialization =========================================//

  const clickReport = PageClick("book_list");
  const uid = User();
  const userData = UserData(uid);
  AOS.init();
  const [imageFlag, setimageFlag] = useState(true);
  const [loading, setloading] = useState(true);
  const [bookList, setbookList] = useState([]);
  const [userId, setuserId] = useState(null);
  const [dataC, setData] = useState();
  const [throughPageC, setthroughPage] = useState();
  const [statusC, setstatus] = useState();
  const [prePurchasePopup, setprePurchasePopup] = useState(false);
  const [user, setuser] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    setuser(userData);
    if (uid !== false) {
      setuserId(uid);
    }
    if (userData !== false) {
      purchaseCheck(userData.uid);
    } else {
      setpBooks({ my_books: [] });
    }
    if (clickReport) {
      // console.log(clickReport)
    }
  }, [clickReport, userData, user, uid]);

  //======================================== Fetching books =========================================//

  const fetchBooks = async (n) => {
    setbookList([]);
    const q = query(
      collection(db, "book_pricing"),
      orderBy("order", "asc"),
      limit(n)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setbookList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
            percentage: (
              100 -
              (parseInt(doc.data().offer_price) / parseInt(doc.data().price)) *
                100
            ).toFixed(0),
          },
        ];
      });
    });
    setloading(false);
  };
  useState(() => {
    fetchBooks(16);
  });
  const divStyle = {
    cursor: "pointer",
  };
  // ========================================================== Purchase Check =======================================================//

  const [pBooks, setpBooks] = useState();
  const purchaseCheck = async (uid) => {
    const userRef = doc(db, "users", uid);
    await getDoc(userRef).then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setpBooks(data);
      } else {
        console.log("Document not found");
      }
    });
  };

  // ========================================================== Payment =======================================================//
  const functions = getFunctions(app, "asia-south1");
  const paymentFunction = (data, throughPage, status) => {
    // through page check
    setloading(true);
    if (uid !== false && user !== false) {
      if (throughPage) {
        navigate(`/bookdetails?b=${data.book_id} &p=/exams`);
      } else {
        const paymentRequest = httpsCallable(functions, "createOrder");
        paymentRequest({
          img: data.book_img,
          package: data.book_id,
          name: data.book_name,
          uid: userId,
          platform: "web",
          books: [data.book_id],
          collection: "book_pricing",
          currency: user.country === "India" ? "INR" : "USD",
          amount:
            user.country === "India"
              ? data.offer_price
              : data.dollar_offer_price,
        }).then((result) => {
          const data = result.data;
          if (data !== 500) {
            const order = result.data;

            const options = {
              key: process.env.REACT_APP_RAZOR_KEY,
              amount: order.amount,
              currency: order.currency,
              name: "The Goodwill Academy",
              description: "Payment for e-book",
              order_id: order.id,
              handler: function (response) {
                setloading(false);
                setprePurchasePopup(false);
              },
              prefill: {
                name: order.notes.user_name,
                email: getAuth().currentUser.email,
                contact: order.notes.mobile,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              modal: {
                ondismiss: function () {
                  setloading(false);
                  setprePurchasePopup(false);
                },
              },
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();
            rzp1.on("payment.failed", function (response) {
              setloading(false);
              setprePurchasePopup(false);
              alert("Something went wrong! Try again..");
            });
          } else {
            alert("Something went wrong!!");
            setloading(false);
          }
        });

        //========================================= Stripe payment function =========================================//

        // const paymentRequest = httpsCallable(functions, "paymentRequest");
        // paymentRequest({
        //   img: data.book_img,
        //   package: data.book_id,
        //   name: data.book_name,
        //   uid: userId,
        //   platform: "web",
        //   books: [data.book_id],
        //   collection: "book_pricing",
        //   // currency: userData.country === "India" ? "inr" : "usd",
        //   currency: "inr",
        // }).then((result) => {
        //   const data = result.data;
        //   if (data) {
        //     window.location.href = data.url;
        //   } else {
        //     alert("Something went wrong!!");
        //     setloading(false);
        //   }
        // });

        //========================================= Stripe payment function =========================================//
      }
    } else {
       if (uid === false) {
                    setprePurchasePopup(false);
         localStorage.setItem("login_popup", true);
         const storageEvent = new Event("storage");
         window.dispatchEvent(storageEvent);
         setloading(false);
       } else if (!user) {
                    setprePurchasePopup(false);

         localStorage.setItem("register_popup", true);
         const storageEvent = new Event("register");
         window.dispatchEvent(storageEvent);
         setloading(false);
       }
    }
  };

  const handleCart = async (product) => {
    if (uid !== false && user !== false) {
      localStorage.setItem("cart_flag", true);
      const cartEvent = new Event("cartEvent");
      window.dispatchEvent(cartEvent);
      await setDoc(doc(db, "users", uid, "cart", product.book_id), product, {
        merge: true,
      });
    } else {
      if (uid === false) {
                   setprePurchasePopup(false);

        localStorage.setItem("login_popup", true);
        const storageEvent = new Event("storage");
        window.dispatchEvent(storageEvent);
        setloading(false);
      } else if (!user) {
                   setprePurchasePopup(false);

        localStorage.setItem("register_popup", true);
        const storageEvent = new Event("register");
        window.dispatchEvent(storageEvent);
        setloading(false);
      }
    }
  };
    const handleClose = () => {
      // window.location.reload();
      setprePurchasePopup(false);
    };

    const handlePopupSubmit = () => {
      paymentFunction(dataC, throughPageC, statusC);
    };

    const handleprePurchase = (data, throughPage, status) => {
      if (!getAuth().currentUser) {
        localStorage.setItem("login_popup", true);
        const storageEvent = new Event("storage");
        window.dispatchEvent(storageEvent);
        setloading(false);
      } else if (!user) {
        setprePurchasePopup(false);
        localStorage.setItem("register_popup", true);
        const storageEvent = new Event("register");
        window.dispatchEvent(storageEvent);
        setloading(false);
      } else {
        if (throughPage) {
          paymentFunction(data, throughPage, status);
        } else {
          setData(data);
          setthroughPage(throughPage);
          setstatus(status);
          setprePurchasePopup(true);
        }
      }
    };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {prePurchasePopup ? (
            <PurchasePopup
              onClose={handleClose}
              onSubmit={handlePopupSubmit} // Pass the callback to handle return value
            />
          ) : null}
          <section class=" bg-white ">
            <h1 className="text-xl ml-8 py-2 font-bold text-black dark:text-black md:text-3xl">
              Pick Your Test: Exam Selection
            </h1>

            <div class="mx-auto grid max-w-8xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {pBooks ? (
                <>
                  {bookList.map((doc, index) => {
                    return (
                      <>
                        {pBooks &&
                        pBooks.my_books.includes(doc.data.book_id) ? (
                          <article
                            style={divStyle}
                            class="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 "
                          >
                            <div
                              onClick={() => {
                                handleprePurchase(
                                  doc.data,
                                  true,
                                  pBooks &&
                                    pBooks.my_books.includes(doc.data.book_id)
                                );
                              }}
                            >
                              <div class="relative flex items-end overflow-hidden rounded-xl">
                                <img
                                  onLoad={() => setimageFlag(false)}
                                  src={
                                    imageFlag ? placeHolder : doc.data.book_img
                                  }
                                  alt="aa"
                                />
                              </div>

                              <div class="mt-1 p-2">
                                <h2 class="text-slate-700  text-sm">
                                  {doc.data.book_name.toUpperCase()}
                                </h2>
                                <p class="mt-1 text-sm text-slate-400">
                                  {doc.data.sub_title}
                                </p>
                              </div>
                            </div>
                            <div class="mt-1 p-2">
                              <button
                                class="text-sm mt-1 flex text-center space-x-1.5 rounded-lg bg-black px-4 py-1.5 text-white duration-100 "
                                onClick={() => {
                                  paymentFunction(doc.data, true, true);
                                }}
                              >
                                Write Now
                              </button>
                            </div>
                          </article>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </section>
          <section class=" bg-white ">
            <h1 className="text-xl ml-8 py-6 font-bold text-black dark:text-black md:text-3xl">
              Purchase Books : Exam Selection
            </h1>

            <div class="mx-auto grid max-w-8xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {pBooks ? (
                <>
                  {bookList.map((doc, index) => {
                    return (
                      <>
                       {pBooks.my_books &&
                            pBooks.my_books.includes(
                              doc.data.book_id
                            ) ? null : (
                      <article
                        style={divStyle}
                        class="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 "
                      >
                        <div
                          onClick={() => {
                            handleprePurchase(
                              doc.data,
                              true,
                              pBooks &&
                                pBooks.my_books.includes(doc.data.book_id)
                            );
                          }}
                        >
                          <div class="relative flex items-end overflow-hidden rounded-xl">
                            <img
                              onLoad={() => setimageFlag(false)}
                              src={imageFlag ? placeHolder : doc.data.book_img}
                              alt="aa"
                            />
                           
                              <div class="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
                                <img
                                  width="24"
                                  height="24"
                                  src="https://img.icons8.com/material-rounded/24/lock--v1.png"
                                  alt="lock--v1"
                                />
                              </div>
                          
                          </div>

                          <div class="mt-1 p-2">
                            <h2 class="text-slate-700  text-sm">
                              {doc.data.book_name.toUpperCase()}
                            </h2>
                            <p class="mt-1 text-sm text-slate-400">
                              {doc.data.sub_title}
                            </p>
                          </div>
                        </div>
                        <div class="mt-1 p-2">
                         
                              <div class=" flex items-end justify-between">
                                <p
                                  class="text-lg  text-black"
                                  onClick={() => {
                                    handleprePurchase(doc.data, true, false);
                                  }}
                                >
                                  {userData.country === "India"
                                    ? "₹" + doc.data.offer_price
                                    : "$" + doc.data.dollar_offer_price}
                                  &nbsp;&nbsp;
                                  <span className="line-through text-red-600">
                                    {userData.country === "India"
                                      ? "₹" + doc.data.price
                                      : "$" + doc.data.dollar_price}
                                  </span>
                                  &nbsp;
                                  {doc.percentage > 0 ? (
                                    <span className="text-green-600 font-sm">
                                      ({doc.percentage}%)
                                    </span>
                                  ) : null}
                                </p>
                              </div>
                              <div class="flex items-center justify-between mt-2 ">
                                <button
                                  class="space-x-1.5 rounded-lg bg-black px-4 py-1.5 text-white duration-100 hover:bg-black"
                                  onClick={() => {
                                    handleprePurchase(doc.data, false, false);
                                  }}
                                >
                                  Buy Now
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  onClick={() => handleCart(doc.data)}
                                  class="text-sm"
                                >
                                  Add to Cart
                                </button>
                              </div>
                            
                        </div>
                      </article>)}
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </section>
        </div>
      )}
    </>
  );
}
