import React, { useState, useEffect } from 'react';
import './css/new.css'
import { FaWhatsapp, FaInstagram, FaPhoneSquareAlt, FaFacebookSquare } from 'react-icons/fa';

import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../Config/Config';
export default function Testimonial() {


  const [testimonialList,settestimonialList] = useState([]);
    const fetchTestimonials = async () => {
      settestimonialList([]);
      const ref = collection(db, "testimonials");
      const q = query(ref, orderBy("order","desc"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        settestimonialList((prev) => {
          return [
            ...prev,
            {
              quote: doc.data().comment,
              position: doc.data().designation,
              image:doc.data().img,

              author: doc.data().name,
              avatar: doc.data.img,
            },
          ];
        });
      });
    };
    useState(()=>{
      fetchTestimonials();
    })


  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const handleNext = (length) => {
    setCurrentTestimonial(
      (prevTestimonial) => (prevTestimonial + 1) % length
    );
    
  };


  useEffect(() => {
    const autoSlideInterval = setInterval(() => {
    
      handleNext(testimonialList.length>0?testimonialList.length:1);
      
      
    }, 5000); 
  
    return () => {
      clearInterval(autoSlideInterval);
    };
  
  }, [currentTestimonial,testimonialList]);

  return (
    <div className='px-4 bg-white   mx-auto max-w-9xl sm:px-6 lg:px-8' >

      
      <section
        
        className=" relative "
      >
        <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-900 text-center py-12">
        See what others
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900">
          {" "}
          saying about us
        </span>
      </h1>
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-12 lg:px-6">
          <figure className="relative">
            <svg
              className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              ></path>
            </svg>
            <blockquote className="text-xl font-medium text-gray-800 md:text-2xl dark:text-gray-800">
              {testimonialList.length &&
              currentTestimonial < testimonialList.length
                ? testimonialList[currentTestimonial].quote
                : null}
            </blockquote>
            <figcaption className="flex items-center justify-center mt-6 space-x-3">
              {testimonialList[currentTestimonial]?(
              <img
                className="w-12 h-12 rounded-full"
                 src={testimonialList[currentTestimonial].image!==''?testimonialList[currentTestimonial].image:"https://img.icons8.com/fluency/48/user-female-circle.png"}

                alt="profile"
              />
            ):null}
              <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                <div className="pr-3 font-medium text-gray-900 dark:text-gray-900">
                  {testimonialList.length &&
                  currentTestimonial < testimonialList.length
                    ? testimonialList[currentTestimonial].author
                    : null}
                </div>
                <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                  {testimonialList.length &&
                  currentTestimonial < testimonialList.length
                    ? testimonialList[currentTestimonial].position
                    : null}
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
        {/* Dot Indicators */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 py-2">
          {testimonialList.map((_, index) => (
            <div
              key={index}
              onClick={() => setCurrentTestimonial(index)}
              className={`w-3 h-3 rounded-full cursor-pointer ${
                currentTestimonial === index ? "bg-blue-500" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </section>

      <div className="social-bar">

        <a href="https://www.facebook.com/profile.php?id=61551365588836" className="social-icon facebook">
          <FaFacebookSquare size={24} />

        </a>
       <a href="https://wa.me/919544800006" className="social-icon whatsapp">
  <FaWhatsapp size={24} />
</a>
        <a href="https://instagram.com/thegoodwillacademy" className="social-icon instagram">
          <FaInstagram size={24} />
        </a>
      </div>
      <br/>
    </div>
  );
}
