import React from "react";
import img from "./Group 518.png";
function Main() {
  return (
    <div className="">
      <section className="py-8">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
              <h1 className="text-xl font-semibold text-black sm:text-6xl lg:text-5xl text-animation transform scale-105 transition-transform">
                Empower Your Success With Advanced <span className="text-blue-500">High </span> Standards
                <div className="relative inline-flex">
                  <h1 className="relative text-4xl font-bold text-black sm:text-6xl text-bold lg:text-5xl text-animation">
                    <span className="text-blue-500 mt-2">ACCA Online Coaching</span> 
                  </h1>
                </div>
              </h1>

              <p className="mt-8 text-base text-black sm:text-xl discrete-text transform translate-y-2 transition-transform">
              Transforming Aspirations into Achievements !
              </p>

              <div className="mt-10  py-6 sm:flex sm:items-center sm:space-x-8">
                <a
                  href="/myshelf"
                  class="inline-flex items-center justify-center px-10 py-4 bg-black px-8 py-4 font-semibold text-white transition [box-shadow:rgb(76,_119,245)-8px_8px] hover:[box-shadow:rgb(76,_119,_675)_0px_0px]"
                >
                  <p class="mr-6 font-bold">Explore Begins Here</p>
                  <svg
                    fill="currentColor"
                    class="h-4 w-4 flex-none"
                    viewBox="0 0 20 21"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polygon points="16.172 9 10.101 2.929 11.515 1.515 20 10 19.293 10.707 11.515 18.485 10.101 17.071 16.172 11 0 11 0 9"></polygon>
                  </svg>
                </a>
              </div>
            </div>

            <div>
              <img
                className="w-full transform scale-105 hover:scale-110 transition-transform"
                src={img}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <div class="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]"></div>
    </div>
  );
}

export default Main;
