import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getToken } from 'firebase/messaging';
import  { useEffect, useState } from 'react'
import { app, db, messaging } from '../Config/Config';

export default function Message() {
      const auth = getAuth();
            const functions = getFunctions(app, "asia-south1");

      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            const uid = user.uid;
            requestPermission(uid);
          }
        });
        return () => {
          unsubscribe();
        };
      }, [auth]);

      const subscribeTotopics = async(uid,token) =>{
       
          const docRef = doc(db, "users", uid,"topics","topics");
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            if(docSnap.data().sub.length>0){
             const topicSubscription = httpsCallable(
               functions,
               "topicSubscription"
             );
             topicSubscription({
              uid:uid,
              token:token,
              topics:docSnap.data().sub
             }).then((result) => {
               const data = result.data;
               if (data) {
                 console.log("Subscribed to topics")
               } else {
                 console.log("Failed");
               }
             });
            }
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
      }

    const [mToken,setToken] = useState(null);
       const requestPermission = async (uid) => {
         const token = await getToken(messaging, {
           vapidKey:
             "BFT3wx0HTNDSwHG1EDZIxpx-ZHC-VIlSFIml-_nk_FI7-2CGXpbZ7si_Z4nvzjljTp8YoPVXqBphKPAbV9gmF38",
         });
         await setDoc(doc(db,"token",uid),{
            uid:uid,
            token:token,
            platform:"web"
         },{merge:true}).then(()=>{
            subscribeTotopics(uid,token);
            setToken(token);
         })         
       };

  return (mToken)
}
