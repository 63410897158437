import { collection, endBefore, getDocs, limit, limitToLast, orderBy, query, startAfter } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Spinner from '../assets/Loading';
import User from '../Auth/User'
import { db } from '../Config/Config';
import Noresult from './Noresult';
export default function Previous() {
const uid = User();
    const [answersList,setanswersList] = useState([]);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const [lastVisible, setLastVisible] = useState(null);
    const [buttonCount,setbuttonCount] = useState(0);
    const [dataFlag,setdataFlag] = useState(false);
    const [loading, setloading] = useState(true);
    const navigate = useNavigate();


  const fetchAnswers = async (q) => {
        const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length === 0) {
            setLastVisible(null);
            setloading(false);
            setdataFlag(true);
          } else {
            setloading(false);
            setdataFlag(false);
            setanswersList([]);
            querySnapshot.forEach((doc) => {
              setanswersList((prev) => {
                return [
                  ...prev,
                  {
                    id: doc.id,
                    data: doc.data(),
                  },
                ];
              });
            });
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          }
  };
  useEffect(()=>{
    if(uid){
          const first = query(
            collection(db, "exam_answers", uid, "answers"),
            orderBy("date", "desc"),
            limit(25)
          );
        fetchAnswers(first)
    }
  },[uid]);
  const nextButton = ()=>{
        setbuttonCount(buttonCount + 1);
      const next = query(
        collection(db, "exam_answers", uid, "answers"),
        orderBy("date", "desc"),
        startAfter(lastVisible),
        limit(25)
      );
    fetchAnswers(next);
  }
    const previousButton = () => {
        setbuttonCount(buttonCount - 1);
      const next = query(
        collection(db, "exam_answers", uid, "answers"),
        orderBy("date", "desc"),
        endBefore(lastVisible),
        limitToLast(25)
      );
      fetchAnswers(next);
    };
  return (
    <div className="">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {dataFlag ? (
            <div>
              <Noresult/>
            </div>
          ) : (
            <>
              <div class="w-full bg-white p-6 rounded-lg  md:p-12">
                <h1 class="text-2xl font-semibold text-gray-800 mb-6">
                  Previous Exam History
                </h1>
                {answersList.length ? (
                  <ul class="space-y-6">
                    {answersList.map((doc, index) => {
                      return (
                        <li class="border py-6 px-6 md:px-8 rounded-xl border-gray-200 pb-4 flex flex-col items-center md:flex-row justify-between transition duration-300 ease-in-out transform hover:scale-105">
                          <div className="flex flex-col items-center md:flex-row gap-4 text-center md:text-left">
                            <h2 class="text-xl font-semibold text-gray-800">
                              {doc.data.book_name}
                            </h2>
                            <p class="text-gray-700">
                              Chapter Name: {doc.data.chapter_name}
                            </p>
                            <p class="text-gray-700">
                              Date:{" "}
                              {new Date(
                                doc.data.date.seconds * 1000
                              ).toDateString([], options)}
                            </p>
                            <span class="text-gray-700">
                              {" "}
                              Time:
                              {new Date(
                                doc.data.date.seconds * 1000
                              ).toLocaleTimeString([], options)}{" "}
                            </span>
                          </div>
                          <button
                            onClick={() => {
                              navigate("/answerKey", {
                                state: {
                                  questions: doc.data.QuestionsList,
                                },
                              });
                            }}
                            class="bg-blue-500 text-white py-2 px-4 rounded-xl hover:bg-blue-700 mt-4 md:mt-0 transition duration-300 ease-in-out transform hover:scale-105"
                          >
                            View Result
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
              <div class="flex justify-center mx-w-200">
                <div className="flex justify-between mt-4 text-bold">
                  <button
                    className="inline-flex items-center justify-center w-auto px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow hover:bg-gray-400"
                    disabled={buttonCount < 1 ? true : false}
                    onClick={previousButton}
                  >
                    Previous
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    className="inline-flex items-center justify-center w-auto px-4 py-2 bg-gray-300 text-gray-700 font-semibold rounded-md shadow hover:bg-gray-400"
                    disabled={!lastVisible}
                    onClick={nextButton}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
