import React, { useEffect, useState } from "react";
import { LockClosedIcon } from '@heroicons/react/outline';
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../Config/Config";
import { useLocation, useNavigate } from "react-router-dom";

export default function StudyPlan() {
  const [planDetails, setPlanDetails] = useState([]);
  const navigate = useNavigate();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const location = useLocation();
    // ==================================== fetch days in plan =====================================//
    const [daysList, setDaysList] = useState([]);
    const fetchDaysInPlan = async (id) => {
      const q = query(
        collection(db, "study_plan",id , "day"),
        orderBy("order", "asc")
      );
      const querySnapshot = await getDocs(q);
     const daysData = [];
      querySnapshot.forEach((doc) => {
        daysData.push({
          data:doc.data(),
          id:doc.id, 
          isPast: new Date(doc.data().date.seconds*1000) < today,
          isFuture: new Date(doc.data().date.seconds*1000) > new Date(),
          isToday: new Date(doc.data().date.seconds*1000).toDateString() === today.toDateString() ,
          dayNumber: `Day ${doc.data().day}`,
          dayInWords: new Date(doc.data().date.seconds*1000).toLocaleDateString("en-US", { weekday: "short" }),
          shortMonth: `${new Date(doc.data().date.seconds*1000).toLocaleDateString("en-US", { month: "short" })} 
          ${new Date(doc.data().date.seconds*1000).toLocaleDateString("en-US", { day: "numeric" })}`,
        });
      })
      setDaysList(daysData);

    }


  // ===================================== fetch study plan =====================================//
  const [planId, setPlanId] = useState("");
  const fetchStudyPlan = async(id) => {
      const ref = doc(db, "study_plan", id);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        setPlanDetails(docSnap.data());
        fetchDaysInPlan(id);
      }
    };

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const studyPlanId = params.get("p").trim();
      if (studyPlanId) {
        setPlanId(studyPlanId);
        fetchStudyPlan(studyPlanId);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

  return (
    <div className="bg-gradient-to-br from-gray-50 via-white to-gray-100 ">
      <div className=" mx-auto max-w-7xl min-h-screen px-4 items-center py-6">
        {planDetails ? (
          <>
            <div className="flex justify-between w-full py-6 px-4 ">
              <h1 className="text-xl font-bold text-blue-950 ">
                {planDetails.plan_name}
              </h1>
              <h1 className="text-lg font-meduim text-blue-950">
                {planDetails.day} Days
              </h1>
            </div>
            <p className="px-4 py-4 text-blue-950">{planDetails.description}</p>
          </>
        ) : null}
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-10 w-full max-w-7xl px-4 py-6">
          {daysList &&
            daysList.map((dayInfo, index) => (
              <div
                onClick={() => {
                  if (dayInfo.isPast||dayInfo.isToday) {
                  navigate(`/studyplancotent?d=${dayInfo.id}&p=${planId}`);
                  }
                }}
                key={index}
                className={`group relative flex flex-col items-center justify-center bg-white border border-gray-200 rounded-lg transition-transform duration-300   
                ${
                  dayInfo.isFuture ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                } ${
                  dayInfo.isToday||dayInfo.isPast
                    ? "shadow-xl transform scale-105"
                    : "hover:shadow-2xl "
                }`}
              >
                <div
                  className={`w-full  sm:py-1.5 text-center bg-gradient-to-r from-blue-600 to-blue-800 text-white font-semibold rounded-t-lg ${
                    dayInfo.isToday
                      ? "bg-gradient-to-r from-indigo-500 via-blue-600 to-indigo-500"
                      : ""
                  }`}
                >
                  {dayInfo.dayNumber}
                </div>

                <span
                  className={`mt-2 sm:mt-4 text-lg sm:text-xl font-semibold text-gray-800 group-hover:text-indigo-600 transition-all duration-300 ${
                    dayInfo.isToday ? "text-indigo-600" : ""
                  }`}
                >
                  {dayInfo.dayInWords}
                </span>

                <span
                  className={`text-sm sm:text-lg py-2 sm:py-3 text-gray-500 group-hover:text-blue-500 mt-1 transition-all duration-300 ${
                    dayInfo.isToday ? "text-purple-500" : ""
                  }`}
                >
                  {dayInfo.shortMonth}
                </span>

                {dayInfo.isFuture && (
                  <div className="absolute top-2 right-2">
                    <LockClosedIcon className="h-6 w-6 text-gray-400" />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
