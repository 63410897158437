import React from 'react'
import img from './Decrease_3.jpg'
export default function Noresult() {
  return (
    <div class="flex items-center justify-center h-screen">
      <div class="flex items-center justify-center h-screen">
        <div class="text-center">
          <img
            src={img}
            alt="Your"
            class="mx-auto h-full w-1/3 rounded-full"
          
          />
          <p class="mt-1 text-lg ">
            Result Not Found
            <a href="/exams">
              <span className="text-blue-500"> Try Now</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
