import { getAuth } from 'firebase/auth';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import  {  useState } from 'react'
import { db } from '../Config/Config';

export default function BookResults() {
    const [bookList,setbookList]=useState([]);
   
      const fetchBookReport = async (q) => {
        setbookList([]);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          setbookList((prev) => {
            return [
              ...prev,
              {
                id: doc.id,
                name: doc.data().book_name,

                data: {right:
                  parseInt(doc.data().total_right_answers) > 0
                    ? ((parseInt(doc.data().total_right_answers) /
                        parseInt(doc.data().total_questions)) *
                      100).toFixed(0)
                    : 0,
                    wrong:
                  parseInt(doc.data().total_wrong_answers) > 0
                    ? ((parseInt(doc.data().total_wrong_answers) /
                        parseInt(doc.data().total_questions)) *
                      100).toFixed(0)
                    : 0,
                    skipped:
                  parseInt(doc.data().total_unattended_questions) > 0
                    ? ((parseInt(doc.data().total_unattended_questions) /
                        parseInt(doc.data().total_questions)) *
                      100).toFixed(0)
                    : 0,
                }
                ,
                datas: doc.data(),
              },
            ];
          });
        });
      };
      useState(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        const q = query(
          collection(db, "users", user.uid, "book_stat"),
          orderBy("book_id", "asc")
        );
        // const q2 = query(
        //   collection(db, "users", user.uid, "chapter_stat"),
        //   orderBy("book_id", "asc")
        // );
        fetchBookReport(q);
      });
  return bookList;
}



