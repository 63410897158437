import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { app, db } from "../Config/Config";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./l.css";
import User from "../Auth/User";
import UserData from "../Config/UserData";
import CircularProgress from '@mui/material/CircularProgress';
import './l.css'
const Cart = () => {
  const uid = User();
  const userData = UserData(uid);
  const [loading, setloading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [price, setprice] = useState(0);
  const [offerPrice, setofferPrice] = useState(0);
  // const [price$, setprice$] = useState(0);
  const [offerPrice$, setofferPrice$] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);
  const [accept, setAccept] = useState(false);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    localStorage.setItem("cart_flag", false);
  };

  window.addEventListener("cartEvent", (event) => {
    if (event.type === "cartEvent") {
      setIsDrawerOpen(localStorage.getItem("cart_flag"));
    }
  });

  useEffect(() => {
    fetch_paymentPolicy();
  }, []);
  const [policyContent, setpolicyContent] = useState();
  const fetch_paymentPolicy = async () => {
    const docRef = doc(db, "basic_info", "payment_policy");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setpolicyContent(docSnap.data().content);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth ? auth.currentUser.uid : null;

    const cartRef = collection(db, "users", user, "cart");
    const unsubscribe = onSnapshot(cartRef, (snapshot) => {
      const newItems = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCartItems(newItems);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (cartItems.length > 0) {
      var amt = 0.00;
      var original = 0.00;
      var amt$ = 0.0;
      var original$ = 0.0;
      for (var i = 0; i < cartItems.length; i++) {
        // if(userData.country ==="India"){
          amt =
            amt + parseInt(cartItems[i].offer_price)
              
          original =
            original +  parseInt(cartItems[i].price)
           
          setofferPrice(amt);
          setprice(original);
       // } else {
           amt$ =
             amt$ + parseFloat(cartItems[i].dollar_offer_price);
           original$ =
             original$ + parseFloat(cartItems[i].dollar_price);
           setofferPrice$((amt$).toFixed(2));
          //  setprice$(original$);
       // }
      }
    } else {
      setofferPrice(0);
      setprice(0);
        setofferPrice$(0);
       // setprice$(0);
    }
  }, [cartItems,userData.country]);

  const auth = getAuth();
  const removeItem = async (data) => {
    const user = auth ? auth.currentUser.uid : null;
    const newItems = cartItems.filter((item) => item.id !== data.id);
    setCartItems(newItems);
    await deleteDoc(doc(db, "users", user, "cart", data.book_id));
  };
  const functions = getFunctions(app, "asia-south1");

  const paymentFunction = (bookIdArray, validityArray, bookDetails) => {
    // setloading(true);
    const paymentRequest = httpsCallable(functions, "cartOrderId");
    paymentRequest({
      // amount: 300,
      img: "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
      name: "Custom",
      package: "bulk",
      uid: auth.currentUser.uid,
      platform: "web",
      books: bookIdArray,
      validity: validityArray,
      offer_price: userData.country === "India" ? offerPrice : offerPrice$,
      book_details: bookDetails,
      price: price,
      //currency: userData.country === "India"?"inr":"usd",
      currency: userData.country === "India" ? "INR" : "USD",
    }).then((result) => {
      const data = result.data;
      if (data !== 500) {
        setShowPopUp(false);
        setloading(false);
        const order = result.data;
        // console.log("Order Data:", order);

        const options = {
          key: process.env.REACT_APP_RAZOR_KEY,
          amount: order.amount,
          currency: order.currency,
          name: "The Goodwill Academy",
          description: "Payment for e-book",
          order_id: order.id,
          handler: function (response) {
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
            window.location.reload();
          },
          prefill: {
            name: order.notes.user_name,
            email: getAuth().currentUser.email,
            contact: order.notes.mobile,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
          modal: {
            ondismiss: function () {
              setloading(false);
            },
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          setloading(false);
          alert("Something went wrong! Try again..");
        });
      } else {
        alert("Something went wrong!!");
        setloading(false);
        setShowPopUp(false);
      }
    });
    //========================================= Stripe payment function =========================================//

    // const paymentRequest = httpsCallable(functions, "CartpaymentRequest");
    // paymentRequest({
    //   // amount: 300,
    //   img: "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
    //   name: "Custom",
    //   package: "bulk",
    //   uid: auth.currentUser.uid,
    //   platform: "web",
    //   books: bookIdArray,
    //   validity: validityArray,
    //   offer_price: offerPrice,
    //   book_details: bookDetails,
    //   price: price,
    //   //currency: userData.country === "India"?"inr":"usd",
    //   currency: "inr",
    // }).then((result) => {
    //   const data = result.data;
    //   if (data) {
    //     window.location.href = data.url;
    //   } else {
    //     setloading(false);
    //     alert("Something went wrong!!");
    //   }
    // });

    //========================================= Stripe payment function =========================================//
  };

  const prePurchase = () => {
    setloading(true);
    const bookIdArray = cartItems.map((obj) => obj.book_id);
    const validityArray = cartItems.map((obj) => obj.validity);
    const extractedData = cartItems.map(
      ({ book_id, book_name, offer_price, price, validity }) => ({
        book_id,
        book_name,
        offer_price,
        price,
        validity,
      })
    );
    paymentFunction(bookIdArray, validityArray, extractedData);
  };

  const policyPopup = () => {
    setShowPopUp(true);
    setIsDrawerOpen(false);
  };

   const handleClosePopup = () => {
    setShowPopUp(false);
  };


  return (
    <div>
      <Drawer
        className=""
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      >
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Your Cart</h2>
          <button
            onClick={handleDrawerClose}
            className="absolute top-0 right-0 mr-4 mt-2 text-gray-600 hover:text-gray-800"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          {cartItems ? (
            <>
              {cartItems.length > 0 ? (
                <>
                  {cartItems.map((doc, index) => {
                    const timestamp = new Date();
                    const validityDate = new Date(
                      timestamp.getTime() + doc.validity * 86400000
                    );
                    return (
                      <div className="py-2">
                        <div className="flex justify-between items-center border-t py-2">
                          <div className="flex items-center">
                            <img
                              className="w-12 h-12 rounded mr-8"
                              src={doc.book_img}
                              alt="Book Title"
                            />
                            <div>
                              <p
                                className="text-gray-800 text-sm"
                                style={{ maxWidth: "144px" }}
                              >
                                {doc.book_name}
                              </p>
                              <p className="text-gray-700 text-xs">
                                Valid till {validityDate.toLocaleDateString()}
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <p
                              className="text-gray-600"
                              style={{
                                textDecoration: "line-through",
                                color: "red",
                              }}
                            >
                              {userData.country === "India"
                                ? "₹" + doc.price
                                : "$" + doc.dollar_price}
                            </p>
                            <p className="text-gray-800 ml-4">
                              {userData.country === "India"
                                ? "₹" + doc.offer_price
                                : "$" + doc.dollar_offer_price}
                            </p>
                            <button
                              className="flex items-center text-red-500 hover:text-red-600 ml-2"
                              onClick={() => removeItem(doc)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 mr-1"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-11.293a1 1 0 00-1.414-1.414L10 8.586 7.707 6.293a1 1 0 00-1.414 1.414L8.586 10l-2.293 2.293a1 1 0 101.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414L11.414 10l2.293-2.293z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="mt-4 border-t border-gray-300 pt-4">
                    <div className="flex justify-between">
                      <p className="text-lg font-semibold">Total:</p>
                      <p className="text-xl font-semibold mr-3">
                        {userData.country === "India"
                          ? "₹" + offerPrice
                          : "$" + offerPrice$}
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 ">
                   <button
      onClick={policyPopup}
      className={`bg-black text-white py-2 float-right px-4 rounded-md ${
        loading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      disabled={loading}
    >
      {loading ? (
        <>
          <CircularProgress size={20} thickness={4} color="inherit" style={{ marginRight: 10 }} />
        
        </>
      ) : (
        "Buy Now"
      )}
    </button>
                  </div>
                </>
              ) : (
                <div
                  style={{ width: "260px" }}
                  className="text-center justify-center mt-48"
                >
                  Your Cart is Empty
                </div>
              )}
            </>
          ) : null}
        </div>
      </Drawer>

      {showPopUp && (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 bg-opacity-50 mt-8">
  <div className="bg-white p-6 rounded-md" style={{ width: "50%" }}>
    <button
      className="float-right top-0 right-0 mb-4 mr-2"
      onClick={handleClosePopup}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
    <h2 className="text-lg font-semibold mb-4">Terms and Conditions</h2>
    <div className="content" style={{ maxHeight: "300px", overflowY: "auto" }}>
      <pre className="content">{policyContent}</pre>
    </div>
    
    <div className="mt-4 flex justify-between items-center">
      <label htmlFor="termsCheckbox" className="flex items-center">
        <input
          type="checkbox"
          id="termsCheckbox"
          checked={accept}
          onChange={(e) => setAccept(e.target.checked)}
          className="mr-2"
        />
        <span className="text-gray-800">
          I accept the terms and conditions
        </span>
      </label>
       <button
            disabled={!accept || loading}
            onClick={prePurchase}
            className={`px-4 py-2 rounded-md ${
              !accept || loading
                ? "bg-gray-300 text-black cursor-not-allowed"
                : "bg-black text-white "
            }`}
          >
            {loading && <CircularProgress size={10} thickness={2} color="inherit" style={{ marginRight: 10 }} />}
            {loading ? "Loading..." : "Proceed"}
          </button>
    </div>
    <div className="mt-4 flex justify-end"></div>
  </div>
</div>

      )}
    </div>
  );
};

export default Cart;
