import { getAuth } from 'firebase/auth';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import React, {  useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { db } from '../Config/Config';

export default function PurchaseList() {
  const auth = getAuth();
  const [purchaseList,setpurchaseList] = useState([]);
  const navigate = useNavigate();
   //======================================================== List Fetching =========================================================//
   const fetchList = async (uid) => {
     const q = query(
       collection(db, "purchase_history"),
       where("uid", "==", uid),
       orderBy("subscribed_date", "desc")
     );

     const querySnapshot = await getDocs(q);
     querySnapshot.forEach(async (doc) => {
       setpurchaseList((prev) => {
         return [
           ...prev,
           {
             id: doc.id,
             data: doc.data(),
           },
         ];
       });
     });
   };
   //======================================================== User Id =========================================================//

   useState(() => {
     fetchList(auth.currentUser.uid);
   });
  return (
    <div>
      {" "}
      <h3 className="text-lg font-semibold text-gray-800 ">Payment History</h3>
      <div className="bg-white rounded-lg overflow-hidden">
        <div className="p-4 border-b border-gray-200">
          {purchaseList.length > 0 ? (
            <div className="divide-y divide-gray-200">
              {purchaseList.map((purchase, index) => (
                <div
                  key={index}
                  className="py-3 flex items-center justify-between"
                >
                  <p className="text-lg font-semibold">
                    {purchase.data.book_details ? (
                      purchase.data.book_details.map((doc) => {
                        return doc.book_name + ",";
                      })
                    ) : (
                      <>{purchase.data.package.toUpperCase()}</>
                    )}
                  </p>
                  <p className="text-sm text-gray-500">
                    Date:{" "}
                    {purchase.data.subscribed_date
                      ? new Date(
                          purchase.data.subscribed_date.seconds * 1000
                        ).toLocaleDateString()
                      : "Date Not Available"}
                  </p>

                  <div className="flex items-center">
                    {/* <p className="text-lg font-semibold">{purchase.data.amount} INR</p> */}

                    <button
                      onClick={() => {
                        navigate("/invoice", {
                          state: {
                            data: purchase.data,
                          },
                        });
                      }}
                      className="ml-4 text-black hover:text-black focus:outline-none"
                    >
                      View Invoice
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 p-4">No purchase history available.</p>
          )}
        </div>
      </div>
    </div>
  );
}
